import React from 'react';
import { useGetBoundaryName } from '../../utils/hooks';

const CommunityMetadata = ({ boundaryMetadata }) => {
  const getBoundaryName = useGetBoundaryName();

  if (!boundaryMetadata) {
    return <></>;
  }

  return (
    <>
      {/* Display the council district's associated council member name and email */}

      {boundaryMetadata.representative && (
        <p className={'m-0 community-description'}>
          <span>
            {getBoundaryName({ id: boundaryMetadata.id })} in{' '}
            {boundaryMetadata.borough} is represented by{' '}
            {getBoundaryName({ representativeTitle: true })}{' '}
            <a
              className={'underline'}
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={boundaryMetadata.representative.email}
            >
              {boundaryMetadata.representative.name || null}
            </a>
          </span>
          .
        </p>
      )}
      {boundaryMetadata.url ? (
        <p className={'m-0 community-description'}>
          <a
            href={boundaryMetadata.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {getBoundaryName({
              id: boundaryMetadata.id,
            })}
          </a>{' '}
          includes the neighborhoods {boundaryMetadata.neighborhoods}.
        </p>
      ) : (
        <p className={'m-0 small-font pt-3'}>
          Neighborhoods: {boundaryMetadata.neighborhoods}
        </p>
      )}
    </>
  );
};

export default CommunityMetadata;
