import { useSelector, useDispatch } from 'react-redux';
import { resetSearchParams } from '../store/slices/communitySearchSlice';
import { setBoundaryType } from '../store/slices/navigationSlice';
import { boundaryTypeNames } from '../utils/constants';

/**
 * BoundaryToggle.js renders the UI component that allows the user to toggle
 * between the different administrative boundaries.
 * @constructor
 */

const BOUNDARY_TYPES = ['council', 'community', 'senate', 'assembly'];

export default function BoundaryToggle() {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const boundaryType = useSelector((state) => state.nav.boundaryType);
  const dispatch = useDispatch();

  return (
    <div
      className={`${isMobile ? `m-0` : ''} w-100 boundary-toggle`}
      style={{ cursor: 'pointer', flexGrow: isMobile ? '2' : '' }}
    >
      {BOUNDARY_TYPES.map((typeId, i) => (
        <div
          key={typeId}
          className={`boundary-toggle-item ${
            boundaryType === typeId
              ? 'boundary-toggle-item-active'
              : 'boundary-toggle-item-inactive'
          } ${i % 2 === 0 ? 'no-right-border' : ''} ${
            i < 2 ? 'no-bottom-border' : ''
          } small-font ${isMobile ? `border-0` : ''}`}
          onClick={(e) => {
            e.stopPropagation();

            // const communitySearchBar =
            //   document.querySelector('#community-search');
            // if (communitySearchBar) {
            //   communitySearchBar.style.backgroundColor = 'white';
            //   communitySearchBar.style.color = 'black';
            // }

            console.log('==================================');
            console.log('SWITCHING BOUNDARY TYPE TO', typeId);
            console.log('==================================');

            dispatch(setBoundaryType(typeId));

            // Reset bad searches
            dispatch(resetSearchParams({ onlyBadSearches: true }));
          }}
        >
          {boundaryTypeNames.selection[typeId]}
        </div>
      ))}
    </div>
  );
}
