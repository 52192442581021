import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';
import html2canvas from 'html2canvas';
import aes from 'crypto-js/aes';
import { usePersistedState } from './hooks';
import cssStyles from './styles.module.css';

const Protect = ({
  sha512,
  blur,
  boxTitle,
  inputPlaceholder,
  buttonLabel,
  children,
  isEnabled,
}) => {
  const chkHash = sha512.toLowerCase();
  const [fp, setFP] = useState(null);
  const [decryptedHash, setDecryptedHash] = useState('');
  const [pass, setPass] = useState('');
  const [wrongPassword, setWrongPassword] = useState(false);

  const [cipher, setCipher] = usePersistedState('cipher', '');

  const refBlur = useRef(null);
  const [renderChild, setRenderChild] = useState(true);

  const handleSubmit = (e) => {
    e?.preventDefault();
    const hash = CryptoJS.SHA512(pass).toString();

    if (hash === chkHash) {
      setCipher(aes.encrypt(JSON.stringify({ pass }), fp.visitorId).toString());
      setDecryptedHash(hash);
      setWrongPassword(false);
      console.log('Correct password, unlocking');
    } else {
      console.warn('Incorrect password');
      setCipher('');
      setPass('');
      setWrongPassword(true);
    }
  };

  useEffect(() => {
    (async function getFingerprint() {
      const fpi = await FingerprintJS.load();
      const result = await fpi.get();
      let d;
      try {
        d = aes.decrypt(cipher, result.visitorId).toString(CryptoJS.enc.Utf8);
      } catch (e) {
        d = '';
      }

      if (d) {
        const hash = CryptoJS.SHA512(JSON.parse(d).pass).toString();
        setDecryptedHash(hash);
      }

      setFP(result);
    })();
  }, [cipher]);

  useEffect(() => {
    if (blur && refBlur.current && renderChild) {
      html2canvas(refBlur.current, { useCORS: true }).then((canvas) => {
        refBlur.current.appendChild(canvas);
        setRenderChild(false);
      });
    }
  }, [blur, renderChild]);

  if (!isEnabled) {
    return children;
  }

  if (fp !== null && decryptedHash === chkHash) {
    return children;
  }

  return (
    <div>
      {fp === null && (
        <div className={cssStyles.skChase}>
          <div className={cssStyles.skChaseDot}></div>
          <div className={cssStyles.skChaseDot}></div>
          <div className={cssStyles.skChaseDot}></div>
          <div className={cssStyles.skChaseDot}></div>
          <div className={cssStyles.skChaseDot}></div>
        </div>
      )}
      {fp !== null && decryptedHash !== chkHash && (
        <div>
          <form className={cssStyles.box} onSubmit={handleSubmit}>
            <header className={cssStyles.boxTitle}>{boxTitle}</header>
            <div>
              <input
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                type="password"
                placeholder={inputPlaceholder}
                autoComplete="password"
              />
            </div>
            <footer className={`${cssStyles.boxButton}`}>
              <p className={wrongPassword ? cssStyles.incorrect : cssStyles.correct}>Incorrect password</p>
              <button className={cssStyles.button}>
                <p className="mb-0 small-font">{buttonLabel}</p>
              </button>
            </footer>
          </form>
          <div
            ref={refBlur}
            className={blur && cssStyles.blurClass}
            style={{ filter: `${blur && 'blur(10px)'}`, overflow: 'hidden' }}
          >
            {blur && renderChild && children}
          </div>
        </div>
      )}
    </div>
  );
};

Protect.defaultProps = {
  blur: true,
  boxTitle: 'This site is password protected.',
  inputPlaceholder: 'Password',
  buttonLabel: 'Unlock',
  styles: {
    input: {
      width: '100%',
      marginTop: '5px',
    },
    button: {},
    header: {},
    wrapper: {},
  },
};

Protect.propTypes = {
  sha512: PropTypes.string.isRequired,
  blur: PropTypes.bool,
  isEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default Protect;
