/**
 * Stores state for the general navigation of the site, such as selected values.
 */

import { createSlice } from '@reduxjs/toolkit';
import { chapters, viewTypes } from '../../utils/constants';

const initialState = {
  /**
   * Which section of the webapp the user is currently on (either 1, 2, 3, or
   * 4).
   * Previously `selectedChapter`
   *
   * @type {int | null}
   */
  chapter: null,
  /**
   * Which category of issues is currently selected
   * (either 1, 2 or 3 => Health, Environment or Mobility)
   * Previously `selectedIssue`
   *
   * @type {int}
   */
  metricCategory: null,
  /**
   * Which metric is currently actively selected
   * Previously `selectedSpecificIssue`
   *
   * @type {int}
   */
  metric: null,
  /**
   * List of integers representing the metrics to display on the community
   * profile chart view page
   *
   * @type {number[]}
   */
  profileMetrics: [],
  /**
   * Either 'council' or 'community', represents which administrative boundary
   * the user has selected
   *
   * Previously `boundary`
   *
   * @type {string}
   */
  boundaryType: 'council',
  /**
   * Which demographic type the user has selected.
   *
   * 1: Race & Ethnicity
   * 2: Poverty Level
   * 3: Vehicle Ownership
   * 4: Drive Alone to Work
   * 5: Walk, Bike, or Ride Transit
   *
   * Previously `demographic`
   *
   * @type {int}
   */
  demographic: null,
  /**
   * Which section of the about page to scroll to immediately
   * Previously `selectedAbout`
   *
   * @type {int}
   */
  aboutSection: null,
  /**
   * The current view for each relevant chapter.
   *
   * @type {object}
   */
  views: {
    [chapters.CITYWIDE]: viewTypes.HISTOGRAM,
    [chapters.COMMUNITY]: viewTypes.MAP,
  },
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setChapter(state, action) {
      state.chapter = action.payload;
    },
    setAboutSection(state, action) {
      state.aboutSection = action.payload;
    },
    setMetricCategory(state, action) {
      state.metricCategory = action.payload;
    },
    setMetric(state, action) {
      console.warn('SETTING METRIC TO', action.payload);
      state.metric = action.payload;
    },
    setBoundaryType(state, action) {
      state.boundaryType = action.payload;
    },
    setDemographic(state, action) {
      if (!action.payload) {
        state.demographic = null;
        return;
      }
      state.demographic = Number(action.payload);
    },
    setProfileMetrics(state, action) {
      // Be sure to make a copy to avoid aliasing
      state.profileMetrics = [...action.payload];
    },
    pushProfileMetric(state, action) {
      state.profileMetrics.push(action.payload);
    },
    removeProfileMetric(state, action) {
      state.profileMetrics = state.profileMetrics.filter(
        (metricId) => metricId !== action.payload
      );
    },
    clearProfileMetrics(state) {
      state.profileMetrics = [];
    },
    setView(state, action) {
      const { chapter, newView } = action.payload;
      state.views[chapter] = newView;
    },
    syncProfileMetrics(state, action) {
      const topChallenges = action.payload;
      const missingMetrics = [];
      topChallenges.forEach((metricId) => {
        if (!state.profileMetrics.includes(metricId)) {
          missingMetrics.push(metricId);
        }
      });
      state.profileMetrics = state.profileMetrics.concat(missingMetrics);
    },
  },
});

export const {
  setChapter,
  setAboutSection,
  setMetricCategory,
  setMetric,
  setBoundaryType,
  setDemographic,
  setProfileMetrics,
  pushProfileMetric,
  removeProfileMetric,
  clearProfileMetrics,
  setView,
  syncProfileMetrics,
} = navigationSlice.actions;
export default navigationSlice.reducer;
