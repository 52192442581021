// import React and React hooks
import React from 'react';

// import text and/or data
import _METRIC_CATEGORY_METADATA from '../meta/metricCategoryMetadata.json';

/**
 * SolutionsCard.js correctly formats and displays media and text associated with each indicator.
 * @param {Object} issue - entry from issues.json for the current selected indicator; with media and text info
 */

import { useDispatch } from 'react-redux';
import { sanitize } from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { setShowSolutions } from '../store/slices/togglesSlice';

export default function SolutionsCard({ issue, onClose }) {
  const dispatch = useDispatch();

  const color = _METRIC_CATEGORY_METADATA[issue.metricCategoryId].color;

  return (
    <div
      className={'issues-tile-text-container'}
      style={{
        border: '1rem solid',
        borderColor: color,
        position: 'relative',
      }}
    >
      {onClose && (
        <FontAwesomeIcon
          icon={faClose}
          onClick={() => {
            dispatch(setShowSolutions(false));
            onClose();
          }}
          style={{
            position: 'absolute',
            right: '2rem',
            color: color,
            cursor: 'pointer',
          }}
          fontSize={'x-large'}
        />
      )}
      <h1
        style={{
          fontSize: '23px',
          color: color,
          fontWeight: '750',
          margin: '0',
        }}
      >
        Solutions
      </h1>
      <div className={'issues-tile-solutions issues-tile-text'}>
        <div className="">
          <p>
            To {issue.solutionInfo.actionText}, pay attention to the design and
            use of public spaces. Here are a few ways to improve spatial equity:
          </p>
          {/* Image */}
          <div>
            {issue.solutionInfo.imageIds?.map((id, index) => {
              return (
                <img
                  key={index}
                  className={`issue-tile-image ${index > 0 ? 'mt-2' : ''}`}
                  src={`/${id}`}
                  alt={''}
                />
              );
            })}
          </div>
          <div className={'smaller-font'}>
            Image: National Association of City Transportation Officials
          </div>
          {/* Solution text */}
          <div>
            {issue.solutionInfo.solutionsList.map((solution, index) => (
              <div
                key={index}
                className={`links-${
                  _METRIC_CATEGORY_METADATA[issue.metricCategoryId].name
                }`}
                style={{ paddingTop: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(solution, { ADD_ATTR: ['target'] }),
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
