import React from 'react';
import { demographicIds } from '../../utils/constants';
import { getFormattedNumber } from '../../utils/functions';
import SourceInfo from '../SourceInfo';
import TransitToggles from './TransitToggles';

/**
 * Text that is displayed over the demographic legend.
 */
const DemographicStatement = ({
  percentages,
  demographicMetadata,
  displayedBoundaryName,
}) => {
  const isCommuteTimes = demographicMetadata.id === demographicIds.COMMUTE_TIME;
  const displayedValue = percentages
    ? getFormattedNumber(100 - percentages[percentages.length - 1] * 100)
    : 0;

  return (
    <div className="d-flex flex-column">
      <div
        className={
          demographicMetadata.multivalue
            ? 'mb-0 small-font d-inline-block'
            : 'mb-3 small-font d-inline-block'
        }
      >
        {isCommuteTimes ? (
          <>Commuters that</>
        ) : (
          <span className="m-0">
            {displayedValue}% of {demographicMetadata.count_units}
            {displayedBoundaryName !== 'citywide' ? ' in' : ''}{' '}
            <b>{displayedBoundaryName}</b>{' '}
            {demographicMetadata.legend_fragment &&
              `${demographicMetadata.legend_fragment}.`}
            {!demographicMetadata.multivalue && (
              <span className="ps-1">
                <SourceInfo type={'demographic'} />
              </span>
            )}
          </span>
        )}
      </div>
      {demographicMetadata.multivalue && (
        <div
          className={`d-flex align-items-center ${
            isCommuteTimes ? '' : 'mb-2'
          }`}
        >
          <TransitToggles
            hidden={
              // Need to hide the bikes since commute times do not support it
              demographicMetadata.id === demographicIds.COMMUTE_TIME
                ? ['bike']
                : ['drive']
            }
          />
          {!isCommuteTimes && (
            <span className="ps-2 mb-1">
              <SourceInfo type={'demographic'} />
            </span>
          )}
        </div>
      )}
      {isCommuteTimes && (
        <div className="mb-2 small-font d-flex">
          <span className="m-0 pe-1">
            {displayedBoundaryName !== 'citywide' ? ' in' : ''}{' '}
            <b>{displayedBoundaryName}</b> spend{' '}
            {getFormattedNumber(percentages[0])} minutes commuting on average.
            <span className="ps-1">
              <SourceInfo type={'demographic'} />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default DemographicStatement;
