import React from 'react';
import IssuesCard from './IssuesCard';

const IssueCardsContainer = ({
  metricIds,
  boundaryMetadata,
  selectedMetric,
  children,
}) => {
  return (
    <>
      <header>{children}:</header>
      <div className={'d-flex flex-column cards-column'}>
        {metricIds.map((metricId) => {
          return (
            <div key={metricId}>
              <IssuesCard
                metricId={metricId}
                boundaryMetadata={boundaryMetadata}
                target={
                  selectedMetric && selectedMetric !== metricId ? false : true
                }
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default IssueCardsContainer;
