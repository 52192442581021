import { configureStore } from '@reduxjs/toolkit';

import mapReducer from './slices/mapSlice';
import mobileReducer from './slices/mobileSlice';
import communitySearchReducer from './slices/communitySearchSlice';
import navReducer from './slices/navigationSlice';
import togglesReducer from './slices/togglesSlice';
import vizReducer from './slices/vizSlice';

const store = configureStore({
  reducer: {
    map: mapReducer,
    mobile: mobileReducer,
    search: communitySearchReducer,
    nav: navReducer,
    toggles: togglesReducer,
    viz: vizReducer,
  },
});

export default store;
