import { Fragment } from 'react';
import RankingText from '../RankingText';
import { demographicIds, raceAndEthnicityMeta } from '../../utils/constants';
import {
  getFormattedNumber,
  getBoundaryName,
  getBoundaryId,
  getCommuteModesProperty,
  getCommuteTimesProperty,
  getRaceAndEthnicityBreakdown,
  capitalize,
  isValidFeature,
} from '../../utils/functions';

const MapTooltip = ({
  // v Passed from global state (but through props) v
  boundaryType,
  selectedMetricData,
  selectedMetricMetadata,
  selectedDemographicMetadata,
  demographicId,
  commuteToggles,
  commuteModesString,
  // v Props for hover tooltips v
  pickingInfoObject,
  isSubway,
  // v Props for static tooltips v
  primarySearchQuery,
  compareSearchQuery,
  isStatic,
}) => {
  const boundaryId = getBoundaryId(pickingInfoObject?.properties);
  const boundaryName = getBoundaryName(boundaryType, {
    id: boundaryId,
    length: 'medium',
  });
  const boundaryTitle = getBoundaryName(boundaryType, {
    id: boundaryId,
    length: 'long',
  });

  const getDemographicTooltip = () => {
    const obj = pickingInfoObject;

    const midSentence = `${selectedDemographicMetadata.count_units} in ${boundaryName}`;

    // return if (bike walk or transit)
    if (demographicId === demographicIds.COMMUTE_MODE) {
      const { aggregateValue } = getCommuteModesProperty(
        obj.properties,
        commuteToggles
      );
      return (
        <div className="map-tooltip-info">
          {commuteModesString !== '...'
            ? `${getFormattedNumber(
                aggregateValue * 100
              )}% of ${midSentence} ${commuteModesString.toLowerCase()}.`
            : `Check off one of the transportation options to see how people are getting around.`}
        </div>
      );
    }

    if (demographicId === demographicIds.COMMUTE_TIME) {
      const { aggregateValue } = getCommuteTimesProperty(
        obj.properties,
        commuteToggles
      );
      return (
        <div className="map-tooltip-info">
          {commuteModesString !== '...'
            ? `${capitalize(
                selectedDemographicMetadata.count_units
              )} that ${commuteModesString.toLowerCase()} in ${boundaryName} spend ${getFormattedNumber(
                aggregateValue
              )} minutes commuting on average.`
            : `Check off one of the transportation options to see how long it takes for people to get around.`}
        </div>
      );
    }

    // return if (race and ethnicity)
    if (demographicId === demographicIds.RACE_AND_ETHNICITY) {
      const ethnicityValuesByKey = getRaceAndEthnicityBreakdown(
        obj.properties,
        Object.keys(raceAndEthnicityMeta)
      );
      return (
        <div className="map-tooltip-info">
          {boundaryName} is—
          <div className="tooltip-grid">
            {ethnicityValuesByKey.map(({ key, value }) => (
              <Fragment key={key}>
                <div
                  style={{
                    color:
                      raceAndEthnicityMeta[key.toLowerCase()].colors.htmlFormat,
                  }}
                >
                  ■
                </div>
                <div>{Math.round(value * 100)}%</div>
                <div>{capitalize(key)}</div>
              </Fragment>
            ))}
          </div>
        </div>
      );
    }

    // Default case, return the plain value from the boundary properties.
    // Recall that all other demographics are percentage values
    return (
      <div className="map-tooltip-info">
        {getFormattedNumber(
          obj.properties[selectedDemographicMetadata.lookup] * 100
        )}
        % of {midSentence} {selectedDemographicMetadata.legend_fragment}.
      </div>
    );
  };

  const getTooltipJSX = () => {
    if (!pickingInfoObject?.properties) {
      return <></>;
    }

    if (isSubway) {
      return (
        <div
          className="map-tooltip-header"
          style={{
            paddingRight: '0.5rem',
          }}
        >
          {pickingInfoObject?.properties.name}
          {pickingInfoObject?.properties.rt_symbol === 'SIR'
            ? ''
            : ` Train${
                pickingInfoObject?.properties.name.length === 1 ? '' : 's'
              }`}
        </div>
      );
    }

    if (!isValidFeature(pickingInfoObject)) {
      return <></>;
    }

    if (
      boundaryId === primarySearchQuery ||
      boundaryId === compareSearchQuery
    ) {
      return <></>;
    }

    return (
      <>
        <div
          className="map-tooltip-header"
          style={{
            paddingRight:
              isStatic && demographicId === null ? '2rem' : '0.5rem',
          }}
        >
          <strong>{boundaryTitle}</strong>
        </div>
        {selectedMetricData && (
          <div className="map-tooltip-info">
            <RankingText
              boundaryId={boundaryId}
              boundaryType={boundaryType}
              allMetricData={selectedMetricData}
              selectedMetricMetadata={selectedMetricMetadata}
            />
          </div>
        )}
        {demographicId !== null && getDemographicTooltip()}
      </>
    );
  };

  return getTooltipJSX();
};

export default MapTooltip;
