/**
 * Stores state for the community profiles search.
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  /**
   * The source of the community search is from.
   * Either 'search' (search bar) or 'click' (map)
   * @type {string}
   */
  searchSource: 'search',
  data: {
    primary: {
      /** @type {string | null} The primary community the user has queried */
      query: null,
      /** @type {number[]} Coordinates of the the primary search location */
      coords: [],
      isBadSearch: false,
    },
    compare: {
      /**
       * The community the user has queried for comparison with the primary
       * @type {string | null}
       */
      query: null,
      /** @type {number[]} Coordinates of the the compare search location */
      coords: [],
      isBadSearch: false,
    },
  },
  /** @type {number | null} Error code of a bad search */
  errorCode: null,
};

const communitySearchSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setSearchSource(state, action) {
      state.searchSource = action.payload;
    },
    setSearchQuery(state, action) {
      const { isPrimary, query } = action.payload;
      if (isPrimary) {
        state.data.primary.query = query;
      } else {
        state.data.compare.query = query;
      }
    },
    setPrimarySearchQuery(state, action) {
      state.data.primary.query = action.payload;
    },
    setCompareSearchQuery(state, action) {
      state.data.compare.query = action.payload;
    },
    setPrimarySearchCoords(state, action) {
      state.data.primary.coords = action.payload;
    },
    setCompareSearchCoords(state, action) {
      state.data.compare.coords = action.payload;
    },
    /** First item is the primary coords, second is the compare coords. */
    setSearchCoords(state, action) {
      const [primaryCoords, compareCoords] = action.payload;
      state.data.primary.coords = primaryCoords;
      state.data.compare.coords = compareCoords;
    },
    resetBadSearch(state, action) {
      state.data.primary.isBadSearch = false;
      state.data.compare.isBadSearch = false;
    },
    setBadSearch(state, action) {
      // Reset the compare coords and update the bad search flags
      const { type, newFlag } = action.payload;
      state.data[type].isBadSearch = newFlag;
    },
    setSearchFromSearchBar(state, action) {
      const { isPrimary, coords, searchResults } = action.payload;
      let newQuery = null;
      if (searchResults.length) {
        newQuery = searchResults[0];
      }

      state.searchSource = 'search';
      if (isPrimary) {
        state.data.primary.query = newQuery;
        state.data.primary.coords = coords;
      } else {
        state.data.compare.query = newQuery;
        state.data.compare.coords = coords;
      }
    },
    resetSearchParams(state, action) {
      const { onlyBadSearches, type } = action.payload || {
        onlyBadSearches: null,
        type: 'both',
      };
      state.searchSource = 'search';
      if (
        (!onlyBadSearches || state.data.primary.isBadSearch) &&
        type !== 'compare'
      ) {
        state.data.primary.query = null;
        state.data.primary.coords = [];
      }
      if (
        (!onlyBadSearches || state.data.compare.isBadSearch) &&
        type !== 'primary'
      ) {
        state.data.compare.query = null;
        state.data.compare.coords = [];
      }

      if (type !== 'compare') {
        state.data.primary.isBadSearch = false;
      }
      if (type !== 'primary') {
        state.data.compare.isBadSearch = false;
      }
    },
    setErrorCode(state, action) {
      state.errorCode = action.payload;
    },
    /**
     *
     * @param {object} state
     * @param {object} action - Action
     */
    setSearchData(state, action) {
      const { type, boundaryId, boundaryCoords } = action.payload;
      if (type === 'primary') {
        state.data.primary.query = Number(boundaryId);
        state.data.primary.coords = boundaryCoords || [];
        state.data.primary.isBadSearch = false;
      } else {
        state.data.compare.query = Number(boundaryId);
        state.data.compare.coords = boundaryCoords || [];
        state.data.compare.isBadSearch = false;
      }
    },
    setSearchFromId(state, action) {},
  },
});

export const {
  setSearchSource,
  setSearchQuery,
  setPrimarySearchQuery,
  setCompareSearchQuery,
  setPrimarySearchCoords,
  setCompareSearchCoords,
  setSearchCoords,
  resetBadSearch,
  setBadSearch,
  setErrorCode,
  setSearchFromSearchBar,
  resetSearchParams,
  setSearchData,
} = communitySearchSlice.actions;
export default communitySearchSlice.reducer;
