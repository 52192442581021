import React from 'react';
import { BIN_SIZE } from '../../utils/constants';
import { getFormattedNumber } from '../../utils/functions';

const LegendColorBar = ({ dataBins, colorRamp, unitsSymbol }) => {
  const formattedBins = getFormattedNumber(dataBins, {
    legendTicksUnitsSymbol: unitsSymbol,
  });

  return (
    <div className={'placeholder-legend'}>
      {colorRamp.map((color, i) => (
        <div
          key={i}
          className={'legend-scale'}
          style={{
            backgroundColor: `rgb(${color.join(',')})`,
            fontFamily: 'Arial',
          }}
        />
      ))}

      {formattedBins.map(
        (value, i) =>
          i < BIN_SIZE && (
            <div key={i} className={'small-font'}>
              {/* Add a '+' to the second to last bin value */}
              {value}
              {unitsSymbol}
              {i === BIN_SIZE - 1 ? '+' : ''}
            </div>
          )
      )}
    </div>
  );
};

export default LegendColorBar;
