import React, { useState, useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import {
  getBoundaryFeature,
  getFormattedNumber,
  getMetricData,
} from '../../utils/functions';
import Header from '../Header';

import { useDispatch, useSelector } from 'react-redux';
import { setChapter } from '../../store/slices/navigationSlice';
import {
  selectBoundaryData,
  selectCompareQuery,
  selectPrimaryQuery,
} from '../../store/storeUtils';
import {
  useGetBoundaryName,
  useCommunitySearchUpdater,
} from '../../utils/hooks';

const RankingTable = ({
  metricMetadata,
  citywideTab = false,
  target = null,
}) => {
  const [expand, setExpand] = useState(false);
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const compareSearchQuery = useSelector(selectCompareQuery);

  const getBoundaryName = useGetBoundaryName();

  const updateCommunitySearch = useCommunitySearchUpdater();

  const dispatch = useDispatch();
  const boundaryData = useSelector(selectBoundaryData);
  const metricData = getMetricData(boundaryData, metricMetadata);

  const rankings = useMemo(() => {
    let sortedData;
    if (metricMetadata.higherValueIsBad) {
      sortedData = metricData.sort((a, b) => a.rank - b.rank);
    } else {
      sortedData = metricData.sort((a, b) => b.rank - a.rank);
    }
    if (!expand && isMobile) {
      return sortedData.slice(0, 5);
    }
    return sortedData;
  }, [metricData, expand]);

  return (
    <div
      style={{
        overflowY: 'scroll',
        position: 'relative',
      }}
    >
      <Table bordered style={{ direction: 'ltr' }}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>{getBoundaryName({ length: 'medium' })}</th>
            <th>{metricMetadata.units}</th>
          </tr>
        </thead>
        <tbody>
          {rankings.map((entry, index) => {
            return (
              <tr
                key={index}
                className={`issues-profile-table-row
${
  entry.boundaryId === primarySearchQuery ||
  entry.boundaryId === compareSearchQuery
    ? 'active-scheme'
    : ''
}

`}
              >
                <td>{entry.rank}</td>
                <td
                  onClick={() => {
                    const boundaryFeature = getBoundaryFeature(
                      boundaryData,
                      entry.boundaryId
                    );
                    updateCommunitySearch(boundaryFeature, { source: 'search' });
                    dispatch(setChapter(3));
                  }}
                  className={'issues-profile-community-jump'}
                >
                  {getBoundaryName({
                    id: entry.boundaryId,
                    length: 'short',
                  })}
                </td>
                <td>
                  {getFormattedNumber(entry.value, metricMetadata)}
                  {metricMetadata.id === 12 ? ' million' : ''}
                  {metricMetadata.id === 5 ? ' °F' : ''}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {isMobile && (
        <div
          style={
            expand
              ? {
                  borderTop: '2px solid black',
                  background: 'white',
                  position: 'sticky',
                  bottom: '0em',
                }
              : { borderTop: '1px solid black' }
          }
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {(target || (citywideTab && isMobile)) && (
            <Header
              type={'collapse'}
              expand={expand}
              citywideTab={citywideTab}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RankingTable;
