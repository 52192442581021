/** Stores any global state for the vizualizations, such as bin lists and scales. */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  /**
   * The binning method for the data scale. Either 'quantile' or 'equal'.
   *
   * @type {string}
   */
  binningMethod: 'equal',
  /**
   * The pinned boundaries on the citywide data histogram.
   * This state is represented as an object mapping a boundary type to a list of
   * IDs of boundaries. This is so that the pinned boundaries can be preserved
   * across boudnary type switches. Note that only the pinned boundaries of the
   * currently selected boundary type will be persisted in the URL. See
   * `useURLUpdater` in `hooks.js`.
   *
   * @type {object}
   */
  pinnedBoundaries: {},
};

const vizSlice = createSlice({
  name: 'viz',
  initialState,
  reducers: {
    setBinningMethod(state, action) {
      state.binningMethod = action.payload;
    },
    setHeaderHeight(state, action) {
      state.headerHeight = action.payload;
    },
    addPinnedBoundary(state, action) {
      const { boundaryType, boundaryId } = action.payload;

      if (!state.pinnedBoundaries) {
        state.pinnedBoundaries = { boundaryType: [boundaryId] };
        return;
      }

      if (!state.pinnedBoundaries[boundaryType]) {
        state.pinnedBoundaries[boundaryType] = [boundaryId];
        return;
      }

      if (!state.pinnedBoundaries[boundaryType].includes(boundaryId)) {
        state.pinnedBoundaries[boundaryType].push(boundaryId);
      }
    },
    removePinnedBoundary(state, action) {
      const { boundaryType, boundaryId } = action.payload;

      if (!state.pinnedBoundaries || !state.pinnedBoundaries[boundaryType]) {
        console.warn('Tried to remove pinned boundary from nonexistent data!');
        return;
      }

      state.pinnedBoundaries[boundaryType] = state.pinnedBoundaries[
        boundaryType
      ].filter((id) => id !== boundaryId);
    },
    clearPinnedBoundaries(state, action) {
      const boundaryType = action.payload;
      if (!boundaryType) {
        // Clear all if no boundary selected
        state.pinnedBoundaries = {};
        return;
      }

      state.pinnedBoundaries[boundaryType] = [];
    },
    setPinnedBoundaries(state, action) {
      const { boundaryType, boundaryIds } = action.payload;
      state.pinnedBoundaries[boundaryType] = boundaryIds;
    },
  },
});

export const {
  setBinningMethod,
  setHeaderHeight,
  addPinnedBoundary,
  removePinnedBoundary,
  clearPinnedBoundaries,
  setPinnedBoundaries,
} = vizSlice.actions;

export default vizSlice.reducer;
