import BoundaryToggle from '../BoundaryToggle';
import ShareButton from '../ShareButton';
import MobileFixedHeader from './MobileFixedHeader';

import { useDispatch, useSelector } from 'react-redux';
import * as mapReducers from '../../store/slices/mapSlice';
import { setMenuVisible } from '../../store/slices/mobileSlice';
import { resetSearchParams } from '../../store/slices/communitySearchSlice';
import {
  setChapter,
  clearProfileMetrics,
} from '../../store/slices/navigationSlice';
import { setPanelIsCollapsed } from '../../store/slices/togglesSlice';

export default function MobileNav() {
  const menuVisible = useSelector((state) => state.mobile.menuVisible);
  const chapter = useSelector((state) => state.nav.chapter);
  const dispatch = useDispatch();

  return (
    <div
      className={`mobile-nav overflow-hidden height-transition position-absolute`}
      style={{
        height: '100%',
        pointerEvents: menuVisible ? 'auto' : 'none',
      }}
    >
      {chapter && <MobileFixedHeader />}

      <div
        className="height-transition d-flex flex-column overflow-hidden"
        style={{ height: menuVisible ? '100%' : '0%' }}
      >
        <BoundaryToggle />
        {/* chapter 1 */}

        <div
          className={`mobile-nav-chapter
            big-padding ${
              menuVisible
                ? 'grow regular-border border-0'
                : 'shrink border-none'
            }
            ${chapter === 1 ? 'active-scheme' : 'inactive-scheme'}
            `}
          onClick={() => {
            if (chapter !== 1) {
              dispatch(setChapter(1));
            } else {
              dispatch(setChapter(null));
            }
            dispatch(setMenuVisible(false));
          }}
        >
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'small-text opacity-100' : 'small-text opacity-0'
            }`}
          >
            {' '}
            What is{' '}
          </p>
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'big-text opacity-100' : 'big-text opacity-0'
            }`}
          >
            {' '}
            Spatial Equity NYC{' '}
          </p>
        </div>
        {/* chapter 2 */}
        <div
          className={`mobile-nav-chapter
            big-padding ${
              menuVisible ? 'grow regular-border' : 'shrink border-none'
            }
            ${chapter === 2 ? 'active-scheme' : 'inactive-scheme'}`}
          onClick={() => {
            if (chapter !== 2) {
              dispatch(setChapter(2));
              dispatch(clearProfileMetrics());
              dispatch(setPanelIsCollapsed(false));
              dispatch(resetSearchParams());
            } else {
              dispatch(setChapter(null));
            }
            dispatch(setMenuVisible(false));
          }}
        >
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'small-text opacity-100' : 'small-text opacity-0'
            }`}
          >
            {' '}
            Explore Spatial Equity by{' '}
          </p>
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'big-text opacity-100' : 'big-text opacity-0'
            }`}
          >
            Citywide Data
          </p>
        </div>
        {/* chapter 3 */}
        <div
          className={`mobile-nav-chapter
            big-padding ${
              menuVisible ? 'grow regular-border' : 'shrink border-none'
            }
            ${chapter === 3 ? 'active-scheme' : 'inactive-scheme'}`}
          onClick={() => {
            if (chapter !== 3) {
              dispatch(setChapter(3));
            } else {
              dispatch(setChapter(null));
            }
            dispatch(setMenuVisible(false));
          }}
        >
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'small-text opacity-100' : 'small-text opacity-0'
            }`}
          >
            {' '}
            Explore Spatial Equity by{' '}
          </p>
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'big-text opacity-100' : 'big-text opacity-0'
            }`}
          >
            {' '}
            Community Profiles{' '}
          </p>
        </div>
        {/* chapter 4 */}
        <div
          className={`mobile-nav-chapter
            big-padding ${
              menuVisible ? 'grow regular-border' : 'shrink border-none'
            }
            ${chapter === 4 ? 'active-scheme' : 'inactive-scheme'}`}
          onClick={() => {
            if (chapter !== 4) {
              dispatch(setChapter(4));
              dispatch(setMenuVisible(false));
            }
          }}
        >
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'small-text opacity-100' : 'small-text opacity-0'
            }`}
          >
            {' '}
            Learn More &{' '}
          </p>
          <p
            className={`mb-0 mobile-transition-font ${
              menuVisible ? 'big-text opacity-100' : 'big-text opacity-0'
            }`}
          >
            {' '}
            Take Action{' '}
          </p>
        </div>
        {/* mobile */}
        <div
          className={`mobile-nav-chapter
            big-padding ${menuVisible ? 'regular-border' : 'shrink border-none'}
            active-scheme`}
        >
          <ShareButton />
        </div>
      </div>
    </div>
  );
}
