import Legend from '../visualizations/Legend';
import Demographics from '../Demographics';
import MapNotableIndicators from '../map/MapNotableIndicators';

import { useDispatch, useSelector } from 'react-redux';
import { toggleLegendVisible } from '../../store/slices/mobileSlice';
import {
  setShowDemographicsTab,
  toggleDemographicsTab,
} from '../../store/slices/togglesSlice';
import {
  selectPrimarySearchMetadata,
  selectColorRamp,
  selectCurrentView,
} from '../../store/storeUtils';
import { viewTypes } from '../../utils/constants';

export default function MobileLegendTray({ isTouchingMapMobile }) {
  const mapState = useSelector((state) => state.map);
  const view = useSelector(selectCurrentView);
  const legendVisible = useSelector((state) => state.mobile.legendVisible);
  const primarySearchMetadata = useSelector(selectPrimarySearchMetadata);
  const dispatch = useDispatch();

  return (
    <div style={{ backgroundColor: 'white', pointerEvents: 'auto' }}>
      <div>
        <div
          className={`mobile-demographics-toggle inactive-scheme`}
          onClick={() => {
            if (view !== viewTypes.MAP) {
              dispatch(toggleDemographicsTab());
            } else {
              dispatch(setShowDemographicsTab(true));
              dispatch(toggleLegendVisible());
              isTouchingMapMobile.current = false;
            }
          }}
        >
          <div className="w-100 d-flex flex-column align-items-center">
            <div
              style={{
                transition: '0.5s ease-in-out',
                backgroundColor: 'black',
                width: '8%',
                height: '6px',
                borderRadius: '1rem',
                marginBottom: '0.5rem',
              }}
            ></div>

            <Legend />
          </div>
        </div>
        {!mapState.demographicsVisible && (
          <MapNotableIndicators boundaryMetadata={primarySearchMetadata} />
        )}

        {/*map mode - bottom tray details fourth child */}
        <div
          className={'mobile-demographics-container'}
          style={{
            transition: '0.5s',
            overflow: 'hidden',
            maxHeight:
              legendVisible || mapState.demographicsVisible ? '100vh' : '0',
          }}
        >
          <div
            className={'d-flex flex-column justify-content-between'}
            style={{
              padding: '1rem',
            }}
          >
            {(legendVisible || !mapState.demographicsVisible) && (
              <p className={'small-font mb-1'}>Compare Demographics</p>
            )}

            <div
              style={{
                position: 'relative',
                zIndex: 1,
                height: '100%',
              }}
            >
              <Demographics />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
