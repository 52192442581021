import React from 'react';

const MapLegend = ({ metricId }) => {
  return (
    <div id="map-legend">
      {metricId === 18 && (
        <article id="flooding-legend">
          <span>
            <div
              className="color-block"
              style={{
                background: '#56271D',
              }}
            />
            <p>Deep Rainwater Flooding (more than 1ft)</p>
          </span>
          <span>
            <div
              className="color-block"
              style={{
                background: '#D8634D',
              }}
            />
            <p>Light Rainwater Flooding (less than 1ft)</p>
          </span>
          <span>
            <div
              className="color-block"
              style={{
                background: '#E39C8F',
              }}
            />
            <p>Future Seawater High Tides 2080</p>
          </span>
        </article>
      )}
    </div>
  );
};

export default MapLegend;
