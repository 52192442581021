import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCompareQuery,
  selectCurrentView,
  selectPrimaryQuery,
} from '../../store/storeUtils';
import { viewTypes } from '../../utils/constants';
import { getBoundaryId } from '../../utils/functions';
import { useGetBoundaryName } from '../../utils/hooks';
import MapToggle from '../map/MapToggle';

const CommunityHeader = () => {
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const compareSearchQuery = useSelector(selectCompareQuery);
  const getBoundaryName = useGetBoundaryName();
  const currentView = useSelector(selectCurrentView);

  return (
    <div
      className={'d-flex flex-column position-relative'}
      id="community-header"

    >
      <div
        className={`row ${'issues-chapters-active'} collapse-issue top-border transition-height`}
        style={{
          padding: '1rem'
        }}
      >
          {primarySearchQuery ? (
              compareSearchQuery ? (
                  currentView === viewTypes.MAP ? (
                    <>
                            <div
            className="position-relative d-flex"
            style={{
              padding: '0.75rem 1rem 0rem 0rem',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
        >
                    <h6
                      className="mb-0"
                      style={{
                      cursor: 'default',
                      alignItems: 'flex-start',
                      padding: '0rem 0rem 0.5rem 1.5rem',
                      fontSize: '1.3rem',
                      }}
                    >
                        {'Compare '}
                        {getBoundaryName() +  's'}
                      </h6>
                      <h6
                        className="mb-0"
                        style={{
                        cursor: 'default',
                        alignItems: 'flex-start',
                        padding: '0rem 0rem 0.5rem 1.5rem',
                        fontSize: '2.5rem',
                        }}
                      >
                        {getBoundaryName({ id: primarySearchQuery, length: 'short' })}
                        {' & '}
                        {getBoundaryName({ id: primarySearchQuery, length: 'short' }).slice(0,4) === getBoundaryName({ id: compareSearchQuery, length: 'short' }).slice(0,4)
                            ? getBoundaryName({
                                id: compareSearchQuery,
                                length: 'short',
                              }).match(/\d+/)[0]
                            : getBoundaryName({
                                id: compareSearchQuery,
                                length: 'short',
                              })
                        }
                    </h6>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className="position-relative col-6" 
                    style={{
                      padding: '0.75rem 1rem 0rem 0rem',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                    >
                      <h6 
                        className="mb-0"
                        style={{
                        cursor: 'default',
                        alignItems: 'flex-start',
                        padding: '0rem 0rem 0.5rem 1.5rem',
                        fontSize: '1.3rem',
                      }}>{getBoundaryName()}
                      </h6>
                      <h6
                        className="mb-0"
                        style={{
                        cursor: 'default',
                        alignItems: 'flex-start',
                        padding: '0rem 0rem 0.5rem 1.5rem',
                        fontSize: '2.5rem',
                        }}
                      >
                        {getBoundaryName({ id: primarySearchQuery, length: 'short' })}
                      </h6>
                    </div>
                    <div className="position-relative col-6" 
                    style={{
                      padding: '0.75rem 1rem 0rem 0rem',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                    >
                      <h6 
                        className="mb-0"
                        style={{
                        cursor: 'default',
                        alignItems: 'flex-start',
                        padding: '0rem 0rem 0.5rem 1.5rem',
                        fontSize: '1.3rem',
                      }}>{getBoundaryName()}
                      </h6>
                      <h6
                        className="mb-0"
                        style={{
                        cursor: 'default',
                        alignItems: 'flex-start',
                        padding: '0rem 0rem 0.5rem 1.5rem',
                        fontSize: '2.5rem',
                        }}
                      >
                        {getBoundaryName({ id: compareSearchQuery, length: 'short' })}
                      </h6>
                    </div>
                    </>
                  )
                  
              ) : (
              <>
          <div
            className="position-relative d-flex"
            style={{
              padding: '0.75rem 1rem 0rem 0rem',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
                <h6
                  className="mb-0"
                  style={{
                    cursor: 'default',
                    alignItems: 'flex-start',
                    padding: '0rem 0rem 0.5rem 1.5rem',
                    fontSize: '1.3rem',
                  }}
                >
                  {getBoundaryName()}
                </h6>
                <h6
                  className="mb-0"
                  style={{
                    cursor: 'default',
                    alignItems: 'flex-start',
                    padding: '0rem 0rem 0.5rem 1.5rem',
                    fontSize: '2.5rem',
                  }}
                >
                  {getBoundaryName({ id: primarySearchQuery, length: 'short' })}
                </h6>
                </div>
              </>
              )
          ) : (
              <h6
                style={{
                  cursor: 'default',
                  fontSize: '1.3rem',
                }}
              >
                Select a {getBoundaryName({ length: 'short' }).toLowerCase()} to
                get started...
              </h6>
          )}
        <div
              style={{
                flexDirection: 'column',
                position: 'absolute',
                top: '10px',
                right: '10px',
                // paddingLeft: '10px',
                width: '80px',
              }}
            >
              <MapToggle showToggle={true} isCommunityProfile={true} />
        </div>
      </div>
    </div>
  );
};

export default CommunityHeader;
