/** General global state for the map. */

import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_VIEW_STATE } from '../../components/map/constants';

const initialState = {
  loading: true,
  // map starting position and view state constraints
  viewState: DEFAULT_VIEW_STATE,
  /**
   * Whether or not to show neighborhood data on the map/legend
   * @type {boolean}
   */
  showNeighborhoodData: false,
  /**
   * Whether or not the choropleth is visible on the map.
   *
   * Choropleth becomes hidden when the user zooms in past a certain threshold.
   *
   * @type {boolean} True if visible, false otherwise.
   */
  showChoropleth: true,
  /** @type {boolean} True if the user selects to display the demographics on the map */
  demographicsVisible: false,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewState(state, action) {
      state.viewState = action.payload;
    },
    toggleDemographicsVisible(state) {
      state.demographicsVisible = !state.demographicsVisible;
    },
    setDemographicsVisible(state, action) {
      state.demographicsVisible = action.payload;
    },
    setShowNeighborhoodData(state, action) {
      state.showNeighborhoodData = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setShowChoropleth(state, action) {
      state.showChoropleth = action.payload;
    },
  },
});

export const {
  setViewState,
  toggleDemographicsVisible,
  setDemographicsVisible,
  setShowNeighborhoodData,
  setHandleLegend,
  setLoading,
  setShowChoropleth,
} = mapSlice.actions;
export default mapSlice.reducer;
