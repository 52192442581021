import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  /** @type {boolean} Whether or not the website is in mobile view */
  isMobile: false,
  /** @type {boolean} Whether or not the issues dropdown is visible */
  dropdownVisible: false,
  /** @type {number | null } The ID of the visible sub dropdown (null if none) */
  subDropdownVisible: false,
  /** @type {boolean} Whether or not the map legend is visible */
  legendVisible: false,
  /** @type {boolean} Whether or not the notable indicators tray is visible */
  notableTrayVisible: true,

  /** @type {boolean} Whether or not the mobile menu is showing */
  menuVisible: false,
};

const mapSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    toggleLegendVisible(state, action) {
      state.legendVisible = !state.legendVisible;
    },
    onMapTouchMove(state, action) {
      if (state.dropdownVisible) {
        state.dropdownVisible = false;
      }
      if (state.subDropdownVisible) {
        state.subDropdownVisible = false;
      }
      if (state.legendVisible) {
        state.legendVisible = false;
      }
    },
    onMapTouchEnd(state, action) {
      const isTouchingMapMobile = action.payload;
      if (!state.isMobile) {
        return;
      }
      if (!state.legendVisible && isTouchingMapMobile === 1) {
        state.legendVisible = true;
      }
      if (!state.notableTrayVisible && isTouchingMapMobile.current === 2) {
        state.notableTrayVisible = true;
      }
    },
    toggleDropdown(state, action) {
      state.dropdownVisible = !state.dropdownVisible;
    },
    collapseDropdowns(state, action) {
      if (state.dropdownVisible) {
        state.dropdownVisible = false;
      }
      if (state.subDropdownVisible) {
        state.subDropdownVisible = false;
      }
    },
    /**
     * Opens a sub-dropdown at the given ID.
     *
     * If a sub-dropdown is already open, close it.
     */
    openSubDropdown(state, action) {
      const dropdownId = action.payload;
      if (state.subDropdownVisible !== dropdownId) {
        state.subDropdownVisible = dropdownId;
      } else {
        state.subDropdownVisible = null;
      }
    },
    toggleMenu(state, action) {
      state.menuVisible = !state.menuVisible;
    },
    setMenuVisible(state, action) {
      state.menuVisible = action.payload;
    },
  },
});

export const {
  setIsMobile,
  toggleLegendVisible,
  onMapTouchMove,
  onMapTouchEnd,
  toggleDropdown,
  collapseDropdowns,
  openSubDropdown,
  toggleMenu,
  setMenuVisible,
} = mapSlice.actions;
export default mapSlice.reducer;
