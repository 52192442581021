// dependencies
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import DeckGL from '@deck.gl/react';
import { Map } from 'react-map-gl';
import { GeoJsonLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import { MapView } from '@deck.gl/core';
import { FillStyleExtension } from '@deck.gl/extensions';
import { max, min } from 'd3-array';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faTrainSubway,
} from '@fortawesome/free-solid-svg-icons';
import { HtmlOverlay, HtmlOverlayItem } from '@nebula.gl/overlays';

// geospatial dependencies
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';

// data
import _NEIGHBORHOODS from '../../data/neighborhoods.json';
import _NEIGHBORHOOD_NAMES from '../../data/neighborhood_names.json';
import _ETHNICITY from '../../data/ethnicity.json';
import _FILL_PATTERN from '../../data/fill_pattern.json';
import _HATCH_ATLAS from '../../data/triple_hatch_pattern.png';
import _NYC_BOUNDARY from '../../data/nyc_boundary.json';
import _DEMOGRAPHICS from '../../meta/demographics.json';
import _SUBWAY_LINES from '../../data/subway_lines.json';
import _SUBWAY_STATIONS from '../../data/subway-stations.json';
import _LAYER_METADATA from '../../meta/layerMetadata.json';

// mapbox style
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  isValidFeature,
  getBoundaryId,
  getBoundaryName,
  splitHyphens,
  getBoundaryFeature,
} from '../../utils/functions';

import MapNotableIndicators from './MapNotableIndicators';

import {
  DEFAULT_VIEW_STATE,
  MAPBOX_ACCESS_TOKEN,
  MAP_STYLE,
  MAP_BACKGROUND_STYLE,
  CHOROPLETH_OPACITY,
  ZOOM_MIN,
  ZOOM_MAX,
  LONGITUDE_RANGE,
  LATITUDE_RANGE,
  MAIN_VIEW,
  SPLIT_SCREEN_HEADER,
  SPLIT_SCREEN_POSITIONING,
  SPLIT_VIEW_LEFT,
  SPLIT_VIEW_RIGHT,
  DEFAULT_COLORS,
  BUTTON_ZOOM_STEP,
  zoomLevels,
  ethnicityCodeToName,
  subwayLineColors,
  zoomCutoffs,
} from './constants';

import { useSelector, useDispatch } from 'react-redux';
import * as mapReducers from '../../store/slices/mapSlice';
import * as mobileReducers from '../../store/slices/mobileSlice';
import { setSearchSource } from '../../store/slices/communitySearchSlice';
import { setChapter } from '../../store/slices/navigationSlice';
import StaticTooltip from './StaticTooltip';
import {
  selectCompareSearchMetadata,
  selectMetricVizData,
  selectPrimarySearchMetadata,
  selectMetricMetadata,
  selectBoundaryData,
  selectMapBoundaryData,
  selectDemographicVizData,
  selectDemographicMetadata,
  selectCommuteModesString,
} from '../../store/storeUtils';
import {
  getChoroplethFillColor,
  useGetHatchPattern,
  useCustomHoverTooltip,
  useGetViewportDataFromPoints,
  useTooltipWidthUpdater,
  useZoomLevel,
  useOnHoverHandler,
  getDemographicFillColor,
  useMapboxLayerUpdater,
} from './mapUtils';
import {
  boundaryTypes,
  chapters,
  demographicIds,
  raceAndEthnicityMeta,
} from '../../utils/constants';
import { useCommunitySearchUpdater } from '../../utils/hooks';
import { BoundaryFeature } from '../../utils/types';

export default function DeckMap({
  // mobile only
  isTouchingMapMobile,
}) {
  const [primaryTooltipData, setPrimaryTooltipData] = useState(null);
  const [compareTooltipData, setCompareTooltipData] = useState(null);

  const [viewStateLocal, setViewStateLocal] = useState(DEFAULT_VIEW_STATE);
  /** The ID of the feature that is highlighted on hover. */
  const [hoveredBoundaryId, setHoveredBoundaryId] = useState(null);
  const [renderBaseMap, setRenderBaseMap] = useState(true);
  const [hoveredSubwayLines, sethoveredSubwayLines] = useState(null);
  const [subwayLineStatus, setSubwayLineStatus] = useState(false);

  const waitForUpdates = useRef(null);
  const closeTooltipTimer = useRef(null);
  const deckRef = useRef(null);
  const mainMapRef = useRef(null);
  const leftMapRef = useRef(null);
  const rightMapRef = useRef(null);
  const overlay1Ref = useRef(null);

  // GLOBAL STATE
  const mapState = useSelector((state) => state.map);
  const mobileState = useSelector((state) => state.mobile);
  const searchState = useSelector((state) => state.search);
  const navState = useSelector((state) => state.nav);
  const toggles = useSelector((state) => state.toggles);
  const selectedMetricMetadata = useSelector(selectMetricMetadata);
  const primarySearchMetadata = useSelector(selectPrimarySearchMetadata);
  const compareSearchMetadata = useSelector(selectCompareSearchMetadata);
  const { colorScale: metricColorScale, metricData: selectedMetricData } =
    useSelector(selectMetricVizData);
  const { demographicValues, colorScale: demographicColorScale } = useSelector(
    selectDemographicVizData
  );
  const selectedDemographicMetadata = useSelector(selectDemographicMetadata);

  const commuteModesString = useSelector(selectCommuteModesString);

  /** The boundary geojson data */
  const boundaryData = useSelector(selectBoundaryData);

  /** Map boundary data includes neighborhood data if zoomed in */
  const mapBoundaryData = useSelector(selectMapBoundaryData);

  const dispatch = useDispatch();

  // ===============================
  //    COMPUTED VALUES (useMemo)
  // ===============================

  const displayedLayerText = useMemo(() => {
    const displayedLayerId = mapState.showNeighborhoodData
      ? boundaryTypes.NEIGHBORHOOD
      : navState.boundaryType;

    const layerMeta = _LAYER_METADATA.find(
      (meta) => meta.id === displayedLayerId
    );

    return `${layerMeta.name}, ${layerMeta.source.name} ${layerMeta.source.year}`;
  }, [navState.boundaryType, mapState.showNeighborhoodData]);

  const selectedMetricJSONId = selectedMetricMetadata?.jsonId;

  const demographicMapLabel = useMemo(() => {
    if (!selectedDemographicMetadata) {
      return '';
    }
    if (selectedDemographicMetadata?.id === demographicIds.COMMUTE_MODE) {
      return `Commuters Who ${commuteModesString}`;
    }
    if (selectedDemographicMetadata?.id === demographicIds.COMMUTE_TIME) {
      return `Commute Times for Commuters who ${commuteModesString}`;
    }
    return selectedDemographicMetadata.name;
  }, [selectedDemographicMetadata, commuteModesString]);

  /** The zoom level (0=low, 1=normal, or 2=high) */
  const zoomLevel = useZoomLevel(viewStateLocal.zoom);

  /**
   * Array of map views (single view or split view)
   */
  const currentMapViews = useMemo(() => {
    if (mapState.demographicsVisible && navState.metric) {
      return [SPLIT_VIEW_LEFT, SPLIT_VIEW_RIGHT];
    }
    return [MAIN_VIEW];
  }, [navState.metric, mapState.demographicsVisible]);

  /**
   * Toggle on the `waitForUpdates` flag for a short period of time.
   */
  function startCloseTooltipTimer() {
    waitForUpdates.current = true;
    closeTooltipTimer.current = setTimeout(
      () => (waitForUpdates.current = false),
      500
    );
  }

  // Update the mapbox layers
  useMapboxLayerUpdater(navState.metric, mainMapRef, leftMapRef, rightMapRef);

  useEffect(() => {
    console.log('MAP COMPONENT MOUNTED');

    // Make sure to clear the timer on component unmounts
    return () => {
      clearTimeout(closeTooltipTimer.current);
      console.log('MAP COMPONENT UNMOUNTED');
    };
  }, []);

  // useEffect(() => {
  //   console.log('Map style check');
  //   console.log(mainMapRef.current, leftMapRef.current, rightMapRef.current);
  //   const mapLoaded =
  //     mainMapRef.current || leftMapRef.current || rightMapRef.current;
  //   if (mapState.loading && mapLoaded) {
  //     console.log('========== Map style done loading ===========');
  //     dispatch(mapReducers.setLoading(false));
  //   }
  // }, [
  //   mainMapRef.current?.getMap().isStyleLoaded(),
  //   leftMapRef.current,
  //   rightMapRef.current,
  //   mapState.loading,
  // ]);

  useEffect(() => {
    console.log(
      'Boundary type changed, recalculating boundaries from coordinates'
    );
    if (searchState.data.primary.query) {
      const primaryBoundary = getBoundaryFeatureFromCoords(
        searchState.data.primary.coords,
        searchState.data.primary.query
      );
      updateCommunitySearch(primaryBoundary, {
        coords: searchState.data.primary.coords,
        searchType: 'primary',
        source: 'boundary',
      });
    }
    if (searchState.data.compare.query) {
      const compareBoundary = getBoundaryFeatureFromCoords(
        searchState.data.compare.coords,
        searchState.data.compare.query
      );
      updateCommunitySearch(compareBoundary, {
        coords: searchState.data.compare.coords,
        searchType: 'compare',
        source: 'boundary',
      });
    }
  }, [navState.boundaryType]);

  // Update the show neighborhood data flag based on the zoom level
  useEffect(() => {
    const neighborhoodFlag = zoomLevel >= zoomLevels.NORMAL;
    dispatch(mapReducers.setShowNeighborhoodData(neighborhoodFlag));

    const showChoropleth = zoomLevel < zoomLevels.HIGH;
    dispatch(mapReducers.setShowChoropleth(showChoropleth));
  }, [zoomLevel]);

  // Updates the width property of the tooltip data.
  useTooltipWidthUpdater(
    'map-tooltip-primary',
    setPrimaryTooltipData,
    overlay1Ref.current?.props
  );
  useTooltipWidthUpdater(
    'map-tooltip-compare',
    setCompareTooltipData,
    overlay1Ref.current?.props
  );

  /**
   * If global view state changes, change the local view state. This is done to
   * make sure the map is loaded properly from a link.
   */
  useEffect(() => {
    // Update the zoom bounds just in case
    console.log('Updating local view state from global view state');
    setViewStateLocal({
      ...mapState.viewState,
      minZoom: ZOOM_MIN,
      maxZoom: ZOOM_MAX + 4,
    });
  }, [mapState.viewState]);

  function moveViewStateSmoothlyTo(newCoords) {
    setViewStateLocal({
      ...viewStateLocal,
      longitude: newCoords[0],
      latitude: newCoords[1],
      zoom: ZOOM_MAX - 0.5,
      transitionDuration: 500,
    });
  }

  /**
   * IDs of the underperforming neighborhoods
   */
  const underperformerIds = useMemo(() => {
    if (!selectedMetricMetadata) {
      return [];
    }

    // Filter out values with no data
    const performanceBar = mapBoundaryData.features
      .filter(isValidFeature)
      .map((feature) => ({
        id: getBoundaryId(feature.properties),
        value: feature.properties[selectedMetricJSONId],
      }));

    performanceBar.sort(function (a, b) {
      // return the sorted list of values depending if you want the highest scores or lowest scores of a given metric
      return selectedMetricMetadata.higherValueIsBad
        ? b.value - a.value // highest scores
        : a.value - b.value; // lowest scores
    });

    // Return the top 8 neighborhoods or the top 5 boundaries
    const underperformers = mapState.showNeighborhoodData
      ? performanceBar.slice(0, 8)
      : performanceBar.slice(0, 5);

    // Return only the ids
    return underperformers.map((x) => x.id);
  }, [mapState.showNeighborhoodData, mapBoundaryData, selectedMetricMetadata]);

  /**
   * `toggleScatterPlot` - scatter plot viz
   * `toggleDemChoropleth` - standard choropleth viz but for demographics
   */
  const [toggleScatterPlot, toggleDemChoropleth] = useMemo(() => {
    if (!mapState.demographicsVisible) {
      return [false, false];
    }

    // Default values
    let toggleScatterPlot = false;
    let toggleDemChoropleth = false;

    if (navState.demographic === 1) {
      // Race and ethnicity
      toggleScatterPlot = true;
    } else if (navState.demographic > 1) {
      toggleDemChoropleth = true;
    }

    return [toggleScatterPlot, toggleDemChoropleth];
  }, [
    navState.demographic,
    navState.demographic,
    mapState.demographicsVisible,
  ]);

  const onViewStateChange = useCallback(
    ({ viewState }) => {
      // 04.1 set constraints on view state
      const newViewStateProps = {};

      newViewStateProps.longitude = Math.min(
        LONGITUDE_RANGE[1],
        Math.max(LONGITUDE_RANGE[0], viewState.longitude)
      );

      newViewStateProps.latitude = Math.min(
        LATITUDE_RANGE[1],
        Math.max(LATITUDE_RANGE[0], viewState.latitude)
      );

      newViewStateProps.width = '100%';

      // 04.2 ramp in/out based on zoom level
      if (toggleScatterPlot) {
        // Prevent zooming in if the scatterplot layer is showing (Race &
        // Ethnicity)
        // Max out at the high zoom cutoff
        newViewStateProps.zoom = Math.min(
          viewState.zoom,
          zoomCutoffs.NORMAL_TO_HIGH
        );
      }

      setViewStateLocal({
        ...viewState,
        ...newViewStateProps,
        transitionDuration: 0,
      });
    },
    [mapState.loading, toggleScatterPlot]
  );

  const zoomIn = () => {
    setViewStateLocal({
      ...viewStateLocal,
      zoom: min([ZOOM_MAX + 4, viewStateLocal.zoom + BUTTON_ZOOM_STEP]),
      transitionDuration: 250,
    });
  };

  const toggleSubwayLines = () => {
    setSubwayLineStatus(!subwayLineStatus);
  };

  const zoomOut = () => {
    setViewStateLocal({
      ...viewStateLocal,
      zoom: max([ZOOM_MIN, viewStateLocal.zoom - BUTTON_ZOOM_STEP]),
      transitionDuration: 250,
    });
  };

  /** Renders the custom hover tooltip for deck GL */
  const getDeckGlTooltip = useCustomHoverTooltip(
    navState,
    searchState,
    toggles,
    selectedMetricData,
    selectedMetricMetadata,
    selectedDemographicMetadata,
    commuteModesString
  );

  const getBoundaryFeatureFromCoords = (coords, boundaryId) => {
    if (!coords?.length && boundaryId) {
      return getBoundaryFeature(boundaryData, boundaryId);
    }

    return boundaryData.features
      .filter(isValidFeature)
      .find(
        (feature) => feature && booleanPointInPolygon(point(coords), feature)
      );
  };

  const getViewportDataFromPoints = useGetViewportDataFromPoints(
    mapState.demographicsVisible
  );

  const handleOnHover = useOnHoverHandler(setHoveredBoundaryId);

  const updateCommunitySearch = useCommunitySearchUpdater();

  useEffect(() => {
    console.log('Executing viewstate update', searchState.data);
    dispatch(mapReducers.setLoading(false));
    if (!searchState.data.primary.query) {
      setPrimaryTooltipData(null);
      setCompareTooltipData(null);
      setViewStateLocal(DEFAULT_VIEW_STATE);
      return;
    }

    if (!searchState.data.compare.query) {
      setCompareTooltipData(null);
    }

    let centeredView = false;
    if (searchState.data.primary.query && searchState.data.compare.query) {
      centeredView = true;
      const newViewStateData = getViewportDataFromPoints(
        searchState.data.primary.coords,
        searchState.data.compare.coords
      );
      setViewStateLocal({
        ...viewStateLocal,
        ...newViewStateData,
        transitionDuration: 500,
      });
    }

    if (searchState.data.primary.query) {
      const tooltipData = getBoundaryFeature(
        boundaryData,
        searchState.data.primary.query
      );
      !centeredView && moveViewStateSmoothlyTo(searchState.data.primary.coords);
      setPrimaryTooltipData({
        ...tooltipData,
        coords: searchState.data.primary.coords, // Search engine gives the position of the dots
      });
    }
    if (searchState.data.compare.query) {
      const tooltipData = getBoundaryFeature(
        boundaryData,
        searchState.data.compare.query
      );
      !centeredView && moveViewStateSmoothlyTo(searchState.data.compare.coords);
      setCompareTooltipData({
        ...tooltipData,
        coords: searchState.data.compare.coords, // Search engine gives the position of the dots
      });
    }
  }, [searchState.data, boundaryData, navState.views[navState.chapter]]);

  /**
   * Assumes the boundary is either 'council' or 'community' and that the
   * feature has data (f.properties.Data_YN === 'Y').
   *
   * @param {BoundaryFeature} f - The feature
   * @returns {boolean} Whether or not the feature is the selected boundary
   */
  const featureIsSelectedBoundary = useCallback(
    (f) => {
      const boundaryId = getBoundaryId(f.properties);
      return (
        boundaryId === searchState.data.primary.query ||
        boundaryId === searchState.data.compare.query
      );
    },
    [
      getBoundaryId,
      searchState.data.primary.query,
      searchState.data.compare.query,
    ]
  );

  const getHatchPattern = useGetHatchPattern(underperformerIds);

  // =============================== \
  //
  // DECK GL LAYERS
  //
  // =================================|
  const metricLayers = [
    new GeoJsonLayer({
      id: 'neighborhoods',
      data: _NEIGHBORHOODS.features,
      stroked: false,
      filled: true,
      getFillColor: (f) =>
        getChoroplethFillColor(
          f,
          selectedMetricJSONId,
          zoomLevel,
          metricColorScale,
          true
        ),
      lineWidthUnits: 'pixels',
      // update triggers
      updateTriggers: {
        getFillColor: [selectedMetricJSONId, zoomLevel, metricColorScale],
      },
      transitions: {
        getFillColor: {
          duration: 400,
        },
      },
    }),
    new GeoJsonLayer({
      id: 'administrative-choropleth',
      data: boundaryData,
      filled: true,
      getFillColor: (f) =>
        getChoroplethFillColor(
          f,
          selectedMetricJSONId,
          zoomLevel,
          metricColorScale,
          false
        ),
      getTextSize: 320,
      updateTriggers: {
        getFillColor: [selectedMetricJSONId, zoomLevel, metricColorScale],
      },
      transitions: {
        getFillColor: {
          duration: 250,
        },
      },
    }),

    new GeoJsonLayer({
      id: 'administrative-choropleth-highlights',
      data: boundaryData,
      filled: true,
      stroked: true,
      opacity: CHOROPLETH_OPACITY,
      visible: toggles.showUnderperformers && !mapState.showNeighborhoodData,
      getLineWidth: (f) => {
        if (!isValidFeature(f)) {
          return 0;
        }
        if (underperformerIds.includes(getBoundaryId(f.properties))) {
          return 100;
        }
        return 0;
      },
      // props added by FillStyleExtension
      fillPatternMask: true,
      fillPatternAtlas: _HATCH_ATLAS,
      fillPatternMapping: _FILL_PATTERN,
      getFillPattern: getHatchPattern,
      getFillPatternScale: 10,
      getFillPatternOffset: [0, 0],
      // Define extensions
      extensions: [new FillStyleExtension({ pattern: true })],

      updateTriggers: {
        getLineWidth: [underperformerIds],
        getFillPattern: [underperformerIds, selectedMetricJSONId],
      },
    }),
    new GeoJsonLayer({
      id: 'neighborhood-choropleth-highlights',
      data: _NEIGHBORHOODS.features,
      visible:
        toggles.showUnderperformers &&
        mapState.showNeighborhoodData &&
        mapState.showChoropleth,
      filled: true,
      stroked: true,
      getLineWidth: (f) => {
        if (underperformerIds.includes(getBoundaryId(f.properties))) {
          return 50;
        }
        return 0;
      },
      opacity: CHOROPLETH_OPACITY,
      // props added by FillStyleExtension
      fillPatternMask: true,
      fillPatternAtlas: _HATCH_ATLAS,
      fillPatternMapping: _FILL_PATTERN,
      getFillPattern: getHatchPattern,
      getFillPatternScale: 10,
      getFillPatternOffset: [0, 0],
      // Define extensions
      extensions: [new FillStyleExtension({ pattern: true })],
      updateTriggers: {
        getLineWidth: [underperformerIds],
        getFillPattern: [underperformerIds, selectedMetricJSONId],
      },
    }),
  ];
  const demographicLayers = [
    new GeoJsonLayer({
      id: 'neighborhood-demographics',
      data: _NEIGHBORHOODS.features,
      stroked: false,
      filled: true,
      getFillColor: (f) =>
        getDemographicFillColor(
          f,
          zoomLevel,
          selectedDemographicMetadata,
          demographicValues,
          toggles.commuteToggles,
          demographicColorScale
        ),
      lineWidthMinPixels: 1,
      visible: mapState.showNeighborhoodData ? toggleDemChoropleth : 0,
      updateTriggers: {
        getFillColor: [
          zoomLevel,
          selectedDemographicMetadata,
          demographicValues,
          toggles.commuteToggles,
          demographicColorScale,
        ],
      },
    }),

    new GeoJsonLayer({
      id: 'administrative-demographics',
      data: boundaryData,
      stroked: false,
      filled: true,
      getFillColor: (f) =>
        getDemographicFillColor(
          f,
          zoomLevel,
          selectedDemographicMetadata,
          demographicValues,
          toggles.commuteToggles,
          demographicColorScale
        ),
      lineWidthMinPixels: 1,
      visible: !mapState.showNeighborhoodData ? toggleDemChoropleth : 0,
      updateTriggers: {
        getFillColor: [
          zoomLevel,
          selectedDemographicMetadata,
          demographicValues,
          toggles.commuteToggles,
          demographicColorScale,
        ],
      },
    }),

    new ScatterplotLayer({
      id: 'ethnicity',
      data: _ETHNICITY.features,
      visible: toggleScatterPlot,
      stroked: false,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 1,
      radiusMaxPixels: 100,
      lineWidthMinPixels: 1,
      getPosition: (d) => d.geometry.coordinates,
      getRadius: 3,
      opacity: 0.75,
      getFillColor: (d) =>
        raceAndEthnicityMeta[ethnicityCodeToName[d.properties.EthnicityCode]]
          .colors.deckFormat,
    }),
  ];
  const annotationLayers = [
    new GeoJsonLayer({
      id: 'nyc-boundaries',
      data: _NYC_BOUNDARY.features,
      filled: true,
      stroked: true,
      getFillColor: [255, 255, 255, 200],
      getLineColor: [0, 0, 0, 255],
      getLineWidth: 3,
      lineWidthMinPixels: 1,
    }),

    new GeoJsonLayer({
      id: 'administrative-selected',
      data: boundaryData,
      stroked: true,
      filled: true,
      visible: zoomLevel < zoomLevels.HIGH,
      getFillColor: (f) => {
        if (
          isValidFeature(f) &&
          getBoundaryId(f.properties) === hoveredBoundaryId
        ) {
          return [0, 0, 0, 50];
        }
        return [0, 0, 0, 0];
      },
      getLineColor: (f) => {
        if (!isValidFeature(f)) {
          return [0, 0, 0, 0];
        }

        // Check if boundary is a selected one
        if (featureIsSelectedBoundary(f)) {
          if (navState.metric) {
            return [255, 255, 255, 255];
          }

          // Color the line by the issue category if none is selected
          if (navState.metricCategory) {
            return DEFAULT_COLORS[navState.metricCategory - 1];
          }
          return [255, 0, 0, 255];
        }

        // Otherwise check it's hover status
        if (getBoundaryId(f.properties) === hoveredBoundaryId) {
          return [0, 0, 0, 255];
        }

        // Gray outline by default
        return [67, 67, 67, 100];
      },
      getLineWidth: (f) => {
        if (!isValidFeature(f)) {
          return 0;
        }

        if (
          featureIsSelectedBoundary(f) ||
          getBoundaryId(f.properties) === hoveredBoundaryId
        ) {
          return mapState.showNeighborhoodData ? 50 : 100;
        }

        return !mapState.showNeighborhoodData ? 50 : 25;
      },
      pickable: true,
      onClick: (info) => {
        const obj = info.object;
        console.log('Clicked on the map:', obj);
        if (waitForUpdates.current) {
          console.debug('Click handler aborted, waiting for updates');
          return;
        }

        if (!isValidFeature(obj)) {
          console.log('Feature did NOT have data, aborting');
          return;
        }

        // Find the boundary from coordinates
        const boundaryFeature = getBoundaryFeatureFromCoords(info.coordinate);

        // change selected boundary
        dispatch(setSearchSource('click')); //set search source to click

        if (waitForUpdates.current) {
          console.debug('Waiting for updates, search engine update aborted');
          return;
        }

        updateCommunitySearch(boundaryFeature, {
          coords: info.coordinate,
          source: 'click',
        });

        // change chapter
        if (navState.chapter !== chapters.COMMUNITY) {
          dispatch(setChapter(chapters.COMMUNITY));
        }
      },
      updateTriggers: {
        getLineColor: [
          navState.metric,
          navState.metricCategory,
          searchState.data.primary.query,
          searchState.data.compare.query,
          navState.metricCategory,
          hoveredBoundaryId,
        ],
        getFillColor: [hoveredBoundaryId],
        getLineWidth: [
          hoveredBoundaryId,
          mapState.showNeighborhoodData,
          zoomLevel,
        ],
      },
    }),
    new GeoJsonLayer({
      id: 'subway-lines-underlayer',
      data: _SUBWAY_LINES.features,
      stroked: true,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      lineWidthMaxPixels: 12,
      pickable: true,
      extruded: true,
      visible: subwayLineStatus,
      pointType: 'circle',
      lineCapRounded: true,
      lineJointRounded: true,
      getLineColor: (f) => {
        return [209, 209, 209, 255];
      },
    }),
    new GeoJsonLayer({
      id: 'subway-lines',
      data: _SUBWAY_LINES.features,
      stroked: true,
      lineWidthScale: 20,
      lineWidthMinPixels: 3,
      lineWidthMaxPixels: 12,
      pickable: true,
      extruded: true,
      visible: subwayLineStatus,
      pointType: 'circle',
      lineCapRounded: true,
      lineJointRounded: true,
      getLineColor: (f) => {
        // Show the color if this feature includes at least one the hovered
        // subway lines
        const splitName = f.properties.name.split('-');
        if (hoveredSubwayLines?.find((line) => splitName.includes(line))) {
          return subwayLineColors[f.properties.rt_symbol];
        }
        return [209, 209, 209, 0];
      },
      onHover: (info) => {
        sethoveredSubwayLines(info.object?.properties.name.split('-'));
      },
      updateTriggers: {
        getLineColor: hoveredSubwayLines,
      },
      transitions: {
        getLineColor: {
          duration: 100,
        },
      },
    }),

    new ScatterplotLayer({
      id: 'subway-stations-points',
      data: _SUBWAY_STATIONS.features,
      stroked: true,
      filled: true,
      visible: subwayLineStatus,
      radiusScale: 6,
      radiusMinPixels: 1.5,
      radiusMaxPixels: 10,
      getSize: 25,
      sizeUnits: 'meters',
      getLineColor: [0, 0, 0, 255],
      getFillColor: [255, 255, 255, 255],
      getPosition: (d) => d.geometry.coordinates,
    }),

    new TextLayer({
      id: 'subway-stations-names',
      data: _SUBWAY_STATIONS.features,
      fontFamily: 'Heebo Medium, Inter',
      visible: subwayLineStatus && zoomLevel >= zoomLevels.HIGH,
      characterSet: 'auto',
      fontSettings: {
        sdf: true,
      },
      fontWeight: '800',
      outlineWidth: 50,
      outlineColor: [255, 255, 255, 255],
      getSize: 14,
      getPixelOffset: [0, 20],
      sizeMinPixels: 8,
      sizeUnits: 'pixels',
      getColor: [0, 0, 0, 255],
      getPosition: (d) => d.geometry.coordinates,
      getText: (d) => d.properties['Stop Name'],
    }),

    /**
     * The neighborhood names
     */
    new TextLayer({
      id: 'neighborhood-names',
      data: _NEIGHBORHOOD_NAMES.features,
      fontFamily: 'Inter',
      characterSet: 'auto',
      sizeUnits: 'meters',
      fontWeight: '1000',
      getSize: 75,
      getColor: () => {
        // Boundary names appear only on non-high zoom levels
        let opacity = 0;
        if (mapState.showNeighborhoodData && zoomLevel < zoomLevels.HIGH) {
          opacity = 255;
        }

        // If there is a selected metric, the text color should be white
        let color = [0, 0, 0];
        if (selectedMetricJSONId) {
          color = [255, 255, 255];
        }
        return [...color, opacity];
      },
      getText: (d) => splitHyphens(d.properties.NTAName.toUpperCase()),
      getPosition: (d) => d.geometry.coordinates,
      getSize: 75,
      maxWidth: 600,
      updateTriggers: {
        getColor: [
          selectedMetricJSONId,
          zoomLevel,
          mapState.showNeighborhoodData,
        ],
      },
      transitions: {
        getColor: {
          duration: 250,
        },
      },
    }),

    /**
     * The dot where the user has clicked (or searched for in the search bar)
     * for the community search.
     */

    new ScatterplotLayer({
      id: 'user-search',
      data: [
        { type: 'primary', coords: searchState.data.primary.coords },
        { type: 'compare', coords: searchState.data.compare.coords },
      ],
      stroked: true,
      filled: true,
      pickable: true,
      visible: zoomLevel < zoomLevels.HIGH,
      radiusScale: 4,
      radiusMinPixels: 8,
      radiusMaxPixels: 8,
      onClick: (pickingInfo) => {
        // Show the tooltip if clicking on the dot
        const searchType = pickingInfo.object.type;
        if (searchType === 'primary') {
          setPrimaryTooltipData({ ...primaryTooltipData, hidden: false });
        } else {
          setCompareTooltipData({ ...compareTooltipData, hidden: false });
        }
      },
      lineWidthMinPixels: 1,
      getPosition: (d) => d.coords,
      getRadius: 30,
      getFillColor: [0, 0, 0, 255],
      getLineColor: [255, 255, 255, 255],
    }),
  ];
  // =================================|
  //
  // DECK GL LAYERS END
  //
  // ================================ /

  const layerFilter = useCallback(
    ({ layer, viewport }) => {
      const groupName = `Checking if layer ${layer.id} is visible`;
      // console.group(groupName);

      const metricList = [];
      const annoList = [];
      const demoList = [];

      for (let i = 0; i < metricLayers.length; i++) {
        metricList.push(metricLayers[i].id);
      }
      for (let i = 0; i < annotationLayers.length; i++) {
        annoList.push(annotationLayers[i].id);
      }
      for (let i = 0; i < demographicLayers.length; i++) {
        demoList.push(demographicLayers[i].id);
      }

      // case 1: single view
      if (
        annoList.includes(layer.id) ||
        (metricList.includes(layer.id) &&
          !mapState.demographicsVisible &&
          navState.metric) ||
        (demoList.includes(layer.id) &&
          mapState.demographicsVisible &&
          !navState.metric)
      ) {
        // console.debug('TRUE');
        // console.groupEnd(groupName);
        return true;
        // case 2: split screen left
      } else if (metricList.includes(layer.id) && navState.metric) {
        // console.groupEnd(groupName);

        return viewport.id === 'splitLeft';
        // case 2: split screen right
      } else if (demoList.includes(layer.id) && mapState.demographicsVisible) {
        // console.groupEnd(groupName);

        return viewport.id === 'splitRight';
      }
      // console.debug('FALSE');
      // console.groupEnd(groupName);
      return false;
    },
    [mapState.demographicsVisible, navState.metric]
  );

  return (
    <div
      onTouchMove={() => {
        if (!mobileState.isMobile) {
          return;
        }
        if (mobileState.legendVisible) {
          isTouchingMapMobile.current = 1;
        }
        if (mobileState.notableTrayVisible) {
          isTouchingMapMobile.current = 2;
        }
        dispatch(mobileReducers.onMapTouchMove());
      }}
      onTouchEnd={() => {
        dispatch(mobileReducers.onMapTouchEnd(isTouchingMapMobile?.current));
      }}
    >
      {/* STATIC ELEMENTS */}
      <h1 style={{ position: 'fixed', zIndex: '9999' }}>{mapState.loading}</h1>
      {/* Notable indicators */}
      {!mobileState.isMobile && (
        <div className="map-notable-container transition-height overflow-hidden">
          {primarySearchMetadata && (
            <MapNotableIndicators boundaryMetadata={primarySearchMetadata} />
          )}
          {searchState.data.compare.query && toggles.compareMode && (
            <MapNotableIndicators boundaryMetadata={compareSearchMetadata} />
          )}
        </div>
      )}
      {/* Buttons (subway toggle, zoom in/out) */}
      <div className="map-zoom-toggle map-zoom-buttons-container">
        <FontAwesomeIcon
          onClick={toggleSubwayLines}
          icon={faTrainSubway}
          id="subway-toggle"
          style={{
            backgroundColor: subwayLineStatus ? '#333333' : 'white',
            color: subwayLineStatus ? 'white' : 'black',
          }}
        />
        <FontAwesomeIcon onClick={zoomIn} icon={faPlus} />
        <FontAwesomeIcon onClick={zoomOut} icon={faMinus} />
      </div>
      {/* Source info */}
      <div className="map-source-info-container">
        <p>{displayedLayerText}</p>
      </div>
      {/* THE MAP */}
      <DeckGL
        style={{ backgroundColor: 'black' }}
        viewState={viewStateLocal}
        onViewStateChange={onViewStateChange}
        views={currentMapViews}
        controller={true}
        layers={[metricLayers, demographicLayers, annotationLayers]}
        getCursor={() => 'crosshair'}
        getTooltip={!mobileState.isMobile ? getDeckGlTooltip : null}
        layerFilter={layerFilter}
        ref={deckRef}
        onHover={handleOnHover}
      >
        {/* STATIC TOOLTIPS */}
        <HtmlOverlay>
          {/* Primary static tooltip */}
          {primaryTooltipData?.coords?.length &&
            zoomLevel < zoomLevels.HIGH && (
              <HtmlOverlayItem
                key={0}
                ref={overlay1Ref}
                style={{
                  transform: 'translate(-50%,-50%)',
                  zIndex: '2',
                  pointerEvents: 'all',
                  display: primaryTooltipData.hidden ? 'none' : 'block',
                }}
                coordinates={primaryTooltipData.coords}
              >
                <StaticTooltip
                  tooltipData={primaryTooltipData}
                  onClose={() => {
                    startCloseTooltipTimer();
                    setPrimaryTooltipData({
                      ...primaryTooltipData,
                      hidden: true,
                    });
                  }}
                />
              </HtmlOverlayItem>
            )}

          {/* Compare static tooltip */}
          {compareTooltipData?.coords?.length &&
            zoomLevel < zoomLevels.HIGH && (
              <HtmlOverlayItem
                key={1}
                style={{
                  transform: 'translate(-50%,-50%)',
                  pointerEvents: 'all',
                  zIndex: compareTooltipData.zIndex || 1,
                  display: compareTooltipData.hidden ? 'none' : 'block',
                }}
                onMouseOver={() =>
                  setCompareTooltipData((data) => ({ ...data, zIndex: '2' }))
                }
                onMouseOut={() =>
                  setCompareTooltipData((data) => ({ ...data, zIndex: '1' }))
                }
                coordinates={compareTooltipData.coords}
              >
                <StaticTooltip
                  tooltipData={compareTooltipData}
                  onClose={() => {
                    startCloseTooltipTimer();
                    setCompareTooltipData({
                      ...compareTooltipData,
                      hidden: true,
                    });
                  }}
                  isCompareTooltip
                />
              </HtmlOverlayItem>
            )}
        </HtmlOverlay>
        {renderBaseMap &&
          // Primary (single) map view (either ONLY demographics visible or ONLY
          // metric visible)
          (!mapState.demographicsVisible ||
            (mapState.demographicsVisible && !navState.metric)) && (
            <MapView id="primary">
              <Map
                key={'map-key'}
                ref={mainMapRef}
                reuseMaps
                mapStyle={MAP_STYLE}
                preventStyleDiffing={true}
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                attributionControl={false}
                logoPosition="top-right"
              />
              {toggles.panelIsCollapsed &&
                (navState.metric || mapState.demographicsVisible) && (
                  <div key={'map-header'} style={SPLIT_SCREEN_POSITIONING}>
                    <div style={SPLIT_SCREEN_HEADER}>
                      {selectedMetricMetadata?.name ||
                        `${
                          _DEMOGRAPHICS[navState.demographic].lookup ==
                          'F10_TrsBkW'
                            ? `Commuters Who ${commuteModesString}`
                            : _DEMOGRAPHICS[navState.demographic].name
                        }`}{' '}
                      by{' '}
                      {getBoundaryName(navState.boundaryType, {
                        length: 'medium',
                      })}
                    </div>
                  </div>
                )}
            </MapView>
          )}

        {/* Split map view when metric AND demographics are visible */}
        {mapState.demographicsVisible && navState.metric && (
          <>
            <MapView id="splitLeft">
              <Map
                key={'map-key-left'}
                ref={leftMapRef}
                reuseMaps
                mapStyle={MAP_STYLE}
                preventStyleDiffing={true}
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                attributionControl={false}
                logoPosition="top-right"
              />
              <div style={MAP_BACKGROUND_STYLE} />
              {navState.metric && (
                <div key={'map-header-left'} style={SPLIT_SCREEN_POSITIONING}>
                  <div style={SPLIT_SCREEN_HEADER}>
                    {selectedMetricMetadata.name} by{' '}
                    {getBoundaryName(navState.boundaryType, {
                      length: 'medium',
                    })}
                  </div>
                </div>
              )}
            </MapView>
            <MapView id="splitRight">
              <Map
                key={'map-key-right'}
                ref={rightMapRef}
                reuseMaps
                mapStyle={MAP_STYLE}
                preventStyleDiffing={true}
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                attributionControl={false}
                logoPosition="top-right"
              />
              <div style={MAP_BACKGROUND_STYLE} />
              {navState.demographic && (
                <div key={'map-header-right'} style={SPLIT_SCREEN_POSITIONING}>
                  <div style={SPLIT_SCREEN_HEADER}>{demographicMapLabel}</div>
                </div>
              )}
            </MapView>
          </>
        )}
      </DeckGL>
    </div>
  );
}
