import { MapView } from '@deck.gl/core';

// Set your mapbox access token here
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const MAP_STYLE =
  'mapbox://styles/mitcivicdata/cllmgs7ev00ll01p27s4sb4i0'; //toner

// color ramps
export const CHOROPLETH_OPACITY = 0.93;
// Map Viewport settings
export const ZOOM_MIN = 9.5;
export const ZOOM_MAX = 13;
export const BUTTON_ZOOM_STEP = 0.5;

export const LONGITUDE_RANGE = [-74.25, -73.7];
export const LATITUDE_RANGE = [40.5, 40.9];

export const NYC_BBOX = '-74.25,40.5,-73.7,40.9';

export const TOOLTIP_WIDTH = 250;

export const ethnicityCodeToName = {
  1: 'latino',
  2: 'white',
  3: 'black',
  4: 'other', // 4 is 'indigenous' but maps to the 'other' category
  5: 'asian',
  6: 'other',
};

// Default view state (reset view)
export const DEFAULT_VIEW_STATE = {
  longitude: -74,
  latitude: 40.7131,
  zoom: 9.5,
  transitionDuration: 500,
  minZoom: ZOOM_MIN,
  maxZoom: ZOOM_MAX + 4,
};

export const MAP_BACKGROUND_STYLE = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  border: '0px solid black',
  borderRight: '1px solid black',
  borderLeft: 'none',
};

export const SPLIT_SCREEN_POSITIONING = {
  // height: '15%',
  position: 'absolute',
  top: '1rem',
  margin: '0rem 1rem 0rem 1rem',
  // borderRadius: "50px",
  pointerEvents: 'none',
};

export const SPLIT_SCREEN_HEADER = {
  padding: '0.25em 0.5em',
  gridRowStart: '2',
  verticalAlign: 'middle',
  textAlign: 'center',
  fontFamily: 'Inter',
  color: 'white',
  fontWeight: '700',
  backgroundColor: 'black',
  border: '1.5px solid white',
  fontSize: '0.8rem',
  // borderRadius: "50px",
};

/**
 * Map view definitions
 */

export const MAIN_VIEW = new MapView({
  id: 'primary',
  // https://deck.gl/docs/api-reference/core/controller
  controller: {
    dragRotate: false,
    doubleClickZoom: false,
    // scrollZoom: {
    //   smooth: true,
    //   speed: 0.02,
    // },
    // inertia: 500,
  },
  x: 0,
  y: 0,
  width: '100%',
  height: '100%',
  clear: true,
});

export const SPLIT_VIEW_LEFT = new MapView({
  id: 'splitLeft',
  controller: {
    dragRotate: false,
    doubleClickZoom: false,
  },
  x: 0,
  y: 0,
  width: '50%',
  height: '100%',
  clear: true,
});

export const SPLIT_VIEW_RIGHT = new MapView({
  id: 'splitRight',
  controller: {
    dragRotate: false,
    doubleClickZoom: false,
  },
  x: '50%',
  y: 0,
  width: '50%',
  height: '100%',
  clear: true,
});

export const DEFAULT_COLORS = [
  [220, 92, 233, 255], // health
  [236, 76, 51, 255], // environment
  [95, 128, 236, 255], // mobility
];

export const zoomCutoffs = {
  /** Anything greater than this value is considered HIGH zoom. */
  NORMAL_TO_HIGH: 13,
  LOW_TO_NORMAL: 12.25,
};

export const zoomLevels = {
  HIGH: 2,
  NORMAL: 1,
  LOW: 0,
};

export const subwayLineColors = {
  1: [238, 53, 46],
  2: [238, 53, 46],
  3: [238, 53, 46],
  4: [0, 147, 60],
  5: [0, 147, 60],
  6: [0, 147, 60],
  7: [185, 51, 173],
  A: [0, 57, 166],
  C: [0, 57, 166],
  E: [0, 57, 166],
  B: [255, 99, 25],
  D: [255, 99, 25],
  F: [255, 99, 25],
  M: [255, 99, 25],
  G: [108, 190, 69],
  J: [153, 102, 51],
  Z: [153, 102, 51],
  L: [167, 169, 172],
  N: [252, 204, 10],
  Q: [252, 204, 10],
  R: [252, 204, 10],
  W: [252, 204, 10],
  S: [128, 129, 131],
  SIR: [0, 0, 205],
};
