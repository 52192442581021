import React, { useMemo } from 'react';

import _DEMOGRAPHICS from '../../meta/demographics.json';
import GridGraph from './GridGraph';
import {
  selectBoundaryData,
  selectDemographicMetadata,
  selectDemographicVizData,
} from '../../store/storeUtils';
import { useSelector } from 'react-redux';
import {
  capitalize,
  getCommuteModesProperty,
  getCommuteTimesProperty,
  getNoCommuteTogglesSelected,
  getRaceAndEthnicityBreakdown,
} from '../../utils/functions';
import LegendColorBar from './LegendColorBar';
import { useGetBoundaryName } from '../../utils/hooks';
import {
  chapters,
  demographicIds,
  raceAndEthnicityMeta,
} from '../../utils/constants';
import DemographicStatement from './DemographicStatement';

const DemographicLegend = ({ boundaryId }) => {
  const mapState = useSelector((state) => state.map);
  const mobileState = useSelector((state) => state.mobile);
  const chapter = useSelector((state) => state.nav.chapter);
  const demographicMetadata = useSelector(selectDemographicMetadata);
  const { legendBins: demographicLegendBins, citywideAverage } = useSelector(
    selectDemographicVizData
  );
  const commuteToggles = useSelector((state) => state.toggles.commuteToggles);

  const noCommuteTogglesSelected = getNoCommuteTogglesSelected(
    commuteToggles,
    demographicMetadata.id
  );

  const selectedBoundaryData = useSelector((state) =>
    selectBoundaryData(state, boundaryId)
  );

  const getBoundaryName = useGetBoundaryName();
  const useBoundary = boundaryId && chapter === chapters.COMMUNITY;

  const displayedBoundaryName = useBoundary
    ? getBoundaryName({ id: boundaryId, length: 'medium' })
    : 'citywide';

  /**
   * Update the legend state when props change
   */
  const legendData = useMemo(() => {
    const newData = {};

    // Initialize the text list and percent list
    newData.textList = [];

    /** The array of percentages.
     *
     * The first N-1 percentages are simple cutoffs, whereas the Nth value
     * represents the "Other" category, or (1 - sum(cutoffs)).
     */
    newData.percList = [];

    if (!demographicMetadata || !selectedBoundaryData) {
      // No new data, early return
      return newData;
    }

    const demoId = demographicMetadata.id;

    if (chapter !== 3 && chapter !== 2) {
      // Outside of the necessary chapters
      return newData;
    }

    // Race and Ethnicity demographics
    if (demoId === demographicIds.RACE_AND_ETHNICITY) {
      const valuesByKey = getRaceAndEthnicityBreakdown(
        useBoundary ? selectedBoundaryData : demographicMetadata.citywideValue,
        _DEMOGRAPHICS[demoId].text_list
      );

      newData.textList = valuesByKey.map(({ key, _ }) => key);
      newData.percList = valuesByKey.map(({ _, value }) => value);
      return newData;
    }

    // Commute Modes
    if (demoId === demographicIds.COMMUTE_MODE) {
      // Get the aggregate value for commute modes
      const { aggregateValue, valuesByKey } = getCommuteModesProperty(
        useBoundary ? selectedBoundaryData : demographicMetadata.citywideValue,
        commuteToggles
      );

      // Add each % category
      valuesByKey.forEach(({ key, value }) => {
        newData.textList.push(capitalize(key));
        newData.percList.push(value);
      });

      // Add the "other" category
      newData.textList.push('Others');
      newData.percList.push(1 - aggregateValue);

      return newData;
    }

    // Commute Times (note that no text list is required since there is no grid
    // graph)
    if (demoId === demographicIds.COMMUTE_TIME) {
      // Get the aggregate value for commute modes
      const { aggregateValue } = getCommuteTimesProperty(
        useBoundary ? selectedBoundaryData : demographicMetadata.citywideValue,
        commuteToggles
      );

      newData.percList = [aggregateValue, 1 - aggregateValue];
      return newData;
    }

    // All other demographic IDs
    newData.textList = _DEMOGRAPHICS[demoId].text_list;
    if (useBoundary) {
      // Use the boundary data, otherwise use citywide data
      newData.percList = [
        selectedBoundaryData[demographicMetadata.lookup],
        1 - selectedBoundaryData[demographicMetadata.lookup],
      ];
      return newData;
    }

    // Use citywide data (average of demographic values)
    newData.percList = [citywideAverage, 1 - citywideAverage];
    return newData;
  }, [
    selectedBoundaryData,
    commuteToggles,
    mapState.demographicsVisible,
    demographicMetadata,
    chapter,
    citywideAverage,
  ]);

  if (chapter === 3 && !legendData.percList) {
    return (
      <div>
        <h3>Select a valid community to see its census data</h3>
      </div>
    );
  }

  if (
    mapState.demographicsVisible ||
    demographicMetadata.id === demographicIds.COMMUTE_TIME
  ) {
    // 1.1 Demographics are displayed on map, but not the race and ethnicity
    // breakdown
    if (demographicMetadata.name !== 'Race & Ethnicity') {
      return (
        <div className={'d-flex flex-column row-gap'} style={{ flex: 1 }}>
          <div>
            {!mobileState.isMobile ||
            (mobileState.isMobile && mobileState.legendVisible) ? (
              <DemographicStatement
                demographicMetadata={demographicMetadata}
                percentages={legendData.percList}
                displayedBoundaryName={displayedBoundaryName}
              />
            ) : null}
            <p className={'mb-1 small-font'}>{demographicMetadata.title}</p>
            {demographicMetadata.multivalue && noCommuteTogglesSelected ? (
              <p>Select one of the transportation options to see the legend.</p>
            ) : (
              <LegendColorBar
                dataBins={demographicLegendBins}
                colorRamp={demographicMetadata.colorRamp}
                unitsSymbol={demographicMetadata.units_symbol}
              />
            )}
          </div>
        </div>
      );
    }
    // 1.2 Race and ethnicity breakdown is displayed for race and ethnicity
    // (it has a different display mode due to the multiple categories)
    return (
      <div className={'d-flex flex-column row-gap'} style={{ flex: 1 }}>
        <div>
          <p className={'mb-3 small-font'}>
            {chapter === 3 ? '' : 'Citywide'} {demographicMetadata.name}
            {chapter === 3 ? ' in ' : ''}
            {chapter === 3 ? displayedBoundaryName : ''}.
          </p>
          <div className={'placeholder-legend placeholder-legend-ethnicity'}>
            {[...Object.keys(raceAndEthnicityMeta)].map((key) =>
              key !== 'Indigenous' ? (
                <div
                  key={key}
                  className={'legend-scale'}
                  style={{
                    backgroundColor: `${raceAndEthnicityMeta[key].colors.htmlFormat}`,
                    fontFamily: 'Arial',
                  }}
                />
              ) : (
                <></>
              )
            )}
            {legendData.percList?.map((value, i) => (
              <div key={i} className="small-font">
                {Math.round(value * 100)}% {legendData.textList[i]}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // 3. if the demographics are not displayed on the map
  let gridColorRamps;

  if (demographicMetadata.id === demographicIds.RACE_AND_ETHNICITY) {
    gridColorRamps = Object.values(raceAndEthnicityMeta).map(
      (meta) => meta.colors.htmlFormat
    );
  } else if (demographicMetadata.id === demographicIds.COMMUTE_MODE) {
    if (noCommuteTogglesSelected) {
      gridColorRamps = [
        `rgb(${demographicMetadata.colorRamp[3].join(',')})`,
        `rgb(${demographicMetadata.colorRamp[1].join(',')})`,
      ];
    } else {
      gridColorRamps = [];
      if (commuteToggles.walk)
        gridColorRamps.push(
          `rgb(${demographicMetadata.colorRamp[4].join(',')})`
        );
      if (commuteToggles.subway)
        gridColorRamps.push(
          `rgb(${demographicMetadata.colorRamp[3].join(',')})`
        );
      if (commuteToggles.bike)
        gridColorRamps.push(
          `rgb(${demographicMetadata.colorRamp[2].join(',')})`
        );
      if (commuteToggles.bus) {
        gridColorRamps.push(
          `rgb(${demographicMetadata.colorRamp[1].join(',')})`
        );
      }
      gridColorRamps.push(`rgb(${demographicMetadata.colorRamp[0].join(',')})`);
    }
  } else {
    gridColorRamps = [
      `rgb(${demographicMetadata.colorRamp[3].join(',')})`,
      `rgb(${demographicMetadata.colorRamp[1].join(',')})`,
    ];
  }
  return (
    <div style={{ flex: 1 }}>
      {demographicMetadata.name === 'Race & Ethnicity' ? (
        <p
          className={
            demographicMetadata.lookup === 'F10_TrsBkW'
              ? 'mb-1 small-font'
              : 'mb-3 small-font'
          }
        >
          {demographicMetadata.name} {useBoundary ? 'in' : ''}{' '}
          <b>{displayedBoundaryName}</b>.
        </p>
      ) : (
        <DemographicStatement
          demographicMetadata={demographicMetadata}
          percentages={legendData.percList}
          displayedBoundaryName={displayedBoundaryName}
        />
      )}

      <div className={'placeholder-legend placeholder-legend-ethnicity'} />
      <GridGraph
        colorRamps={gridColorRamps}
        percList={legendData.percList.map((perc) => perc * 100)}
        textList={legendData.textList}
      />
    </div>
  );
};

export default DemographicLegend;
