import { max, min } from 'd3-array';
import {
  BIN_SIZE,
  boundaryTypeNames,
  boundaryTypes,
  commuteTogglesMeta,
  demographicIds,
  geojsonTypes,
  raceAndEthnicityMeta,
} from './constants';

import {
  FeatureCollection,
  BoundaryFeature,
  MetricMetadata,
  BoundaryMetricData,
  CommuteToggles,
  DemographicMetadata,
  BoundaryProperties,
} from './types';

/**
 * Capitalizes the given string.
 *
 * @param {string} str
 * @returns {string} The same string with the first letter capitalized
 */
export function capitalize(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

/**
 * Interpolates the value from the original range to the new range.
 *
 * @param {number} value - The value to interpolate
 * @param {number} low1 - Original low
 * @param {number} high1 - Original high
 * @param {number} low2 - New low
 * @param {number} high2 - New high
 * @returns {number} The interpolated value
 */
export function interpolate(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
}

/**
 * Gets the NYC borough name given its ID.
 *
 * @param {string} boroughId - The borough ID
 * @returns {string} The borough name
 */
export function getBoroughName(boroughId) {
  switch (boroughId) {
    case '1':
      return 'Manhattan';
    case '2':
      return 'Bronx';
    case '3':
      return 'Brooklyn';
    case '4':
      return 'Queens';
    case '5':
      return 'Staten Island';
    default:
      return '';
  }
}

/**
 * Returns a formatted number (or numbers) for visualizations.
 *
 * @param {number|number[]} value - The value(s) to format.
 * @param {MetricMetadata} metricMetadata - The metadata of the metric this
 *    value is associated with.
 * @returns {number|number[]}
 */
export function getFormattedNumber(value, metricMetadata) {
  if (value === null) {
    return;
  }

  const isPercent = metricMetadata?.legendTicksUnitsSymbol === '%';
  if (typeof value === 'number' || typeof value === 'string') {
    const num = isPercent ? Number(value) * 100 : Number(value);
    const wholeNumber = String(num.toFixed(2)).split('.')[1];

    const absoluteValue = Math.abs(num);
    if (absoluteValue === 0) {
      return absoluteValue;
    }
    if (wholeNumber === '00') {
      return num.toFixed(0);
    }
    if (absoluteValue > 10) {
      return num.toFixed(1);
    }
    return num.toFixed(2);
  }
  if (typeof value === 'object') {
    let valuesArray;
    if (isPercent) {
      valuesArray = value.map((d) => d * 100);
    } else {
      valuesArray = [...value];
    }

    const minVal = Math.abs(min(valuesArray));
    const stringMinVal = minVal.toString();

    if (min(valuesArray) === 0) {
      return [0, ...getFormattedNumber(valuesArray.slice(1))];
    }

    if (
      minVal > 10 ||
      (stringMinVal[stringMinVal.length - 1] === '0' && stringMinVal[0] !== '0')
    ) {
      return valuesArray.map((d) => d.toFixed(0));
    }

    if (min(valuesArray) >= 1) {
      return valuesArray.map((d) => d.toFixed(1));
    }

    return valuesArray.map((d) => d.toFixed(2));
  }
}

/**
 * Returns a (stringified) number with its ordinal suffix.
 *
 * @param {number} i - The number, e.g. 2
 * @returns {string} The ordinal version of the number, e.g. 2nd
 */

export function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

/**
 * Splits the given string at the hyphens and rejoins it with spaces.
 *
 * Example:
 *    `splitHyphens("hello-world") -> "hello - world"`
 *
 * @param {string} str - The string to split.
 */
export function splitHyphens(str) {
  return str.split('-').join(' - ');
}

/**
 * Gets the boundary ID given that different boundary types name IDs differently.
 *
 * @param {BoundaryProperties} featureProperties
 * @returns {number} The ID of the boundary feature
 */
export function getBoundaryId(featureProperties) {
  if (!featureProperties) {
    return;
  }
  return (
    featureProperties.coun_dist ||
    featureProperties.BoroCD ||
    featureProperties.DISTRICT ||
    featureProperties.NTA2020
  );
}

/**
 * Gets the boundary name given the boundary type and a set of config values.
 *
 * Adheres to the table approved by TA:
 * https://github.com/civic-data-design-lab/spatial-equity/issues/40#issuecomment-1789899063
 *
 * Examples:
 * ```js
 * >>> getBoundaryName('council', { length: 'long', plural: true })
 * 'City Council Districts'
 *
 * >>> getBoundaryName('community', { length: 'medium', id: 'MN04' })
 * 'Manhattan CB 4'
 *
 * >>> getBoundaryName('senate', { length: 'short', id: 3 })
 * 'District 3'
 *
 * >>> getBoundaryName('assembly', { length: 'medium' })
 * 'Assembly District'
 * ```
 *
 * @param {string} boundaryType - The given boundary type (see `boundaryTypes`
 *    in `utils/constants.js`).
 * @param {object} [config] - Configuration object. See below for properties.
 * @param {string} [config.length] - The length of the name that we want. Either
 *    'long', 'medium', or 'short'.
 * @param {string} [config.id] - The ID of the boundary, in order to get the
 *    full name of that boundary.
 * @param {boolean} [config.plural] - Whether or not to get the plural form of
 *    the name. Note that this is incompatible with providing an ID.
 *
 * @returns {string} The realized boundary name
 */
export function getBoundaryName(boundaryType, config) {
  if (!config) {
    return boundaryTypeNames.selection[boundaryType];
  }

  if (config.representativeTitle) {
    if (boundaryType === boundaryTypes.STATE_SENATE) {
      return 'Senator';
    }
    return `${boundaryTypeNames.selection[boundaryType]} member`;
  }

  let boundaryTypeName;
  // If no length is provided, defaults to "long"
  const defaultLength = 'long';
  if (!config.id) {
    // No boundary ID provided, show the name by itself

    boundaryTypeName =
      boundaryTypeNames[config.length || defaultLength][boundaryType];

    // If plural, add an 's'
    return config.plural ? boundaryTypeName + 's' : boundaryTypeName;
  }

  // Boundary ID provided
  if (config.plural) {
    // It does not make sense to display the plural form when an ID is provided,
    // so throw a warning.
    console.warn(
      'config.plural was selected but boundary ID was provided, ignoring the plural flag.'
    );
  }

  // Handle the exception, Community Board
  if (boundaryType === boundaryTypes.COMMUNITY_BOARD) {
    const boardId = config.id.toString();
    const boroughName = getBoroughName(boardId.slice(0, 1));
    const boardNumber = Number(boardId.slice(1)).toFixed(0);
    if (config.length === 'short') {
      return `${boroughName} ${boardNumber}`;
    }
    if (config.length === 'medium') {
      return `${boroughName} CB ${boardNumber}`;
    }
    // Long or default length
    return `${boroughName} ${
      boundaryTypeNames[config.length || defaultLength][boundaryType]
    } ${boardNumber}`;
  }

  // All other boundary types are simply the name followed by the ID
  return `${boundaryTypeNames[config.length || defaultLength][boundaryType]} ${
    config.id
  }`;
}

/**
 * Gets the bin list from the data for the legends.
 *
 * @param {number[]} data - The data, MUST be sorted in ascending order
 * @param {string} binningMethod - The binning method (quantile or equal)
 */
export function getBins(data, binningMethod) {
  const bins = [];

  const uniqueValues = [...new Set(data)];
  for (let i = 0; i < BIN_SIZE; i++) {
    if (binningMethod === 'equal') {
      const threshold = (max(data) - min(data)) / (BIN_SIZE + 1);
      bins.push(Math.round((threshold * (i + 1) + min(data)) * 100) / 100);
    } else {
      const interval = Math.floor(
        ((uniqueValues.length - 1) / BIN_SIZE) * (i + 1)
      );
      //  quantile breaks
      bins.push(uniqueValues[interval]);
    }
  }

  return bins;
}

/**
 * Returns whether or not the given feature has data.
 *
 * Used for calculating the legend bins and displaying the data on the map.
 *
 * @param {BoundaryFeature} feature - The feature to test
 * @returns {boolean} Valid or not
 */
export function isValidFeature(feature) {
  return (
    // Filter out non-regular neighborhoods (e.g. parks, airports, etc.)
    (feature.properties.NTAType === undefined ||
      feature.properties.NTAType === '0') &&
    // Filter out non-regular community boards (e.g. Joint Interest Areas (JIAs))
    (feature.properties.CDTAType === undefined ||
      feature.properties.CDTAType === 'CD')
  );
}

/**
 * Gets the percentages breakdown and the aggregate (average) value of data.
 *
 * Some data requires taking a breakdown of the data based on several indicators
 * (passed in through the lookups property). For example, commuter-related
 * demographics are broken down by mode.
 *
 * @param {BoundaryProperties} boundaryProperties - The data to calculate the
 *    percentage breakdown from.
 * @param {object[]} lookups - An array of lookup IDs and keys in the data (e.g.
 *    different commute mode values)
 * @returns {object} The percentage breakdown of the boundary (or citywide) given the
 *    lookups. Returns the aggregate value as well as the values by key provided
 */
export function getPercentagesBreakdown(boundaryProperties, lookups) {
  const boundaryPercentagesBreakdown = lookups.map(({ lookup, key }) => ({
    key,
    value: boundaryProperties[lookup],
  }));

  const boundaryAggregatePercentage = boundaryPercentagesBreakdown
    .map(({ _, value }) => value)
    .reduce((a, b) => a + b, 0);

  return {
    aggregateValue: boundaryAggregatePercentage,
    valuesByKey: boundaryPercentagesBreakdown,
  };
}

/**
 * Gets the selected commute lookups for the given demographic ID.
 *
 * Some demographics ignore certain commute toggles. For example, there is no
 * "bike" commute times data.
 *
 * @param {CommuteToggles} commuteToggles
 * @param {number} demographicId
 * @returns {object[]} Array of objects with the commute ID and a JSON lookup
 *    for the boundary property
 */
function getSelectedCommuteLookups(commuteToggles, demographicId) {
  return Object.entries(commuteToggles)
    .filter(
      ([commuteId, toggle]) =>
        // Ignore commute toggles that are not supported by this demographic
        // e.g. Ignore "percent drive alone" for commute modes since it is a
        // separate demographic and not included in commute modes
        toggle && commuteTogglesMeta[commuteId].lookups[demographicId]
    )
    .map(([commuteId, _]) => ({
      lookup: commuteTogglesMeta[commuteId].lookups[demographicId],
      key: commuteId,
    }));
}

/**
 * Gets the aggregate commute modes property as well as the breakdown by mode.
 * 
 * @param {BoundaryProperties} boundaryProperties - May be a truncated version
 *    for the citywide data
 * @param {CommuteToggles} commuteToggles 
 * @returns {object} - Returns the `aggregateValue` and `valuesByKey` object
 */
export function getCommuteModesProperty(boundaryProperties, commuteToggles) {
  const selectedCommuteLookups = getSelectedCommuteLookups(
    commuteToggles,
    demographicIds.COMMUTE_MODE
  );

  return getPercentagesBreakdown(boundaryProperties, selectedCommuteLookups);
}

/**
 * Gets the average commute time as well as the breakdown by mode.
 * 
 * @param {BoundaryProperties} boundaryProperties - May be a truncated version
 *    for the citywide data
 * @param {CommuteToggles} commuteToggles 
 * @returns {object} - Returns the `aggregateValue` and `valuesByKey` object
 */
export function getCommuteTimesProperty(boundaryProperties, commuteToggles) {
  const selectedCommuteLookups = getSelectedCommuteLookups(
    commuteToggles,
    demographicIds.COMMUTE_TIME
  );

  const { aggregateValue, valuesByKey } = getPercentagesBreakdown(
    boundaryProperties,
    selectedCommuteLookups
  );
  return {
    aggregateValue: selectedCommuteLookups.length
      ? aggregateValue / selectedCommuteLookups.length
      : 0,
    valuesByKey,
  };
}

/**
 * Gets the race and ethnicity demographic breakdown.
 *
 * @param {BoundaryProperties} data - The boundary properties or a general
*     object, contains at least the lookup as defined by the metadata. 
 * @param {string[]} textList - The text list to use for the breakdown (e.g.
 *    Other: 10%)
 * @returns {object[]} Array of objects with the text as a key and the value
 */
export function getRaceAndEthnicityBreakdown(data, textList) {
  let lookups = textList
    .filter((key) => key.toLowerCase() !== 'other')
    .map((key) => ({
      key,
      lookup: raceAndEthnicityMeta[key.toLowerCase()].lookup,
    }));

  const { aggregateValue, valuesByKey } = getPercentagesBreakdown(
    data,
    lookups
  );

  // Add other category
  valuesByKey.push({ key: 'Other', value: 1 - aggregateValue });
  return valuesByKey;
}

/**
 * Gets the values from the boundary data of the selected demographic.
 *
 * @param {FeatureCollection} boundaryData
 * @param {DemographicMetadata} demographicMetadata - Used to extract the values from the
 *    boundary data
 * @param {CommuteToggles} commuteToggles - Used in case the commute mode demographic is
 *    selected, since we need a better breakdown
 * @returns {Map<number, number>} Demographic values, maps the boundary ID to
 *    the value for the given demographic
 */
export function getDemographicValues(
  boundaryData,
  demographicMetadata,
  commuteToggles
) {
  const demographicValues = new Map();

  boundaryData.features.forEach((feature) => {
    // Exclude invalid features from the data
    if (!isValidFeature(feature)) {
      return;
    }

    const boundaryId = getBoundaryId(feature.properties);
    let value;
    switch (demographicMetadata.id) {
      case demographicIds.COMMUTE_TIME:
        // Get multidata features (commute times)
        value = getCommuteTimesProperty(
          feature.properties,
          commuteToggles
        ).aggregateValue;
        break;
      case demographicIds.COMMUTE_MODE:
        // Get multidata features (commute modes)
        value = getCommuteModesProperty(
          feature.properties,
          commuteToggles
        ).aggregateValue;
        break;
      default:
        // Single datapoint demographic
        value = feature.properties[demographicMetadata.lookup];
    }
    // Create mapping from boundary ID to it's value
    demographicValues.set(boundaryId, value);
  });

  return demographicValues;
}

/**
 * Stringifies the boolean into 't' or 'f'.
 *
 * @param {boolean} bool
 * @returns {string} 't' if true, 'f' if false
 */
export function stringifyBool(bool) {
  return bool ? 't' : 'f';
}

/**
 * Converts a 't'/'f' string into a boolean
 *
 * @param {string} string
 * @returns {boolean} True if 't', False if 'f'
 */
export function boolifyString(str) {
  return str === 't';
}

export function getRGBFromArray(colorArray) {
  const [r, g, b] = Array.from(colorArray);
  return { r, g, b };
}

export const colorInterpolate = (colorA, colorB, intval) => {
  const rgbA = getRGBFromArray(colorA);
  const rgbB = getRGBFromArray(colorB);
  const colorVal = (prop) =>
    Math.round(rgbA[prop] * (1 - intval) + rgbB[prop] * intval);
  return [colorVal('r'), colorVal('g'), colorVal('b')];
};

/**
 * Get the specific, cleaned value from the feature given the JSON ID.
 *
 * @param {BoundaryFeature} feature - The boundary feature object
 * @param {string} jsonId - The ID of the value within that feature object
 * @returns
 */
export function getValueFromFeature(feature, jsonId) {
  const value = feature.properties[jsonId];
  if (value === null && jsonId !== 'F27_BusSpe') {
    return 0;
  }
  return parseFloat(value);
}

/**
 * Gets all of the metric data across all boundaries.
 *
 * @param {FeatureCollection} allBoundaryData - The entire boundary data feature collection
 * @param {MetricMetadata} metricMetadata - The specific metric metadata of the
 *    metric we want to get the data of
 * @returns {BoundaryMetricData[]} - Collection of each boundary's metric data
 *    for the specific metric provided
 */
export function getMetricData(allBoundaryData, metricMetadata) {
  return allBoundaryData.features.filter(isValidFeature).map((feature) => ({
    boundaryId: getBoundaryId(feature.properties),
    value: getValueFromFeature(feature, metricMetadata?.jsonId),
    rank: feature.properties[`${metricMetadata?.jsonId}_RANK`],
  }));
}

/**
 * Gets the specific boundary data feature.
 *
 * Automatically filters out invalid features
 *
 * @param {FeatureCollection} allBoundaryData - The entire boundary data feature collection
 * @param {number} boundaryId - The boundary ID to search for
 * @returns {BoundaryFeature} A single boundary feature
 */
export function getBoundaryFeature(allBoundaryData, boundaryId) {
  return allBoundaryData.features
    .filter(isValidFeature)
    .find((feature) => getBoundaryId(feature.properties) === boundaryId);
}

/**
 * Converts an object to a stringified CSV.
 *
 * Object properties are converted to columns in the CSV string.
 *
 * @param {object} obj - The object to convert
 * @returns {string} The string with comma separated values, with rows separated
 *    by newlines
 */
export function objectArrayToCSVString(obj) {
  const csvRows = [];
  // Get the headers based on the keys of the first object
  const headers = Object.keys(obj[0]);

  csvRows.push(headers.join(','));

  for (const row of obj) {
    const values = headers.map((header) => {
      const escapedValue = row[header]?.toString().replace(/"/g, '\\"');
      return `"${escapedValue}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}

/**
 * Gets whether or not there are no commute toggles active.
 *
 * Depends on the currently selected demographic ID, since some demographics use
 * different commute toggles.
 *
 * @param {object} commuteToggles
 * @param {number} selectedDemographicId
 * @returns {boolean}
 */
export function getNoCommuteTogglesSelected(
  commuteToggles,
  selectedDemographicId
) {
  const onCommuteToggles = [...Object.entries(commuteToggles)].filter(
    ([commuteToggle, isActive]) =>
      commuteTogglesMeta[commuteToggle].lookups[selectedDemographicId] &&
      isActive
  );
  return onCommuteToggles.length === 0;
}
