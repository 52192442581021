import _METRIC_METADATA from '../../meta/metricMetadata.json';

import { useDispatch, useSelector } from 'react-redux';
import { setMetric } from '../../store/slices/navigationSlice';
import { selectTopChallenges } from '../../store/storeUtils';
import { useGetBoundaryName } from '../../utils/hooks';

export default function MapNotableIndicators({ boundaryMetadata }) {
  const mobileState = useSelector((state) => state.mobile);
  const selectedMetric = useSelector((state) => state.nav.metric);
  const dispatch = useDispatch();

  const topChallenges = useSelector((state) =>
    selectTopChallenges(state, boundaryMetadata?.id)
  );

  const getBoundaryName = useGetBoundaryName();

  return (
    <div
      className="height-transition overflow-hidden"
      style={
        mobileState.isMobile
          ? {
              maxHeight:
                mobileState.notableTrayVisible && boundaryMetadata
                  ? '20vh'
                  : '0vh',
              paddingBottom: '0.5rem',
            }
          : { paddingBottom: '0.5rem' }
      }
    >
      <div
        className={mobileState.isMobile ? 'small-font' : ''}
        style={{
          backgroundColor: mobileState.isMobile ? 'white' : 'black',
          color: mobileState.isMobile ? 'black' : 'white',
          padding: mobileState.isMobile ? '0.25rem 1rem' : '0.25rem 0.5rem',
        }}
      >
        {!mobileState.isMobile
          ? getBoundaryName({ id: boundaryMetadata?.id, length: 'short' })
          : ''}{' '}
        {!mobileState.isMobile ? <br></br> : ''}Top Challenges
      </div>
      <div
        className="map-notable-indicators"
        style={{ margin: mobileState.isMobile ? '0 1rem' : '0' }}
      >
        {topChallenges.map((metricId) => (
          <div
            key={metricId}
            className={
              selectedMetric === metricId ? 'active-scheme' : 'inactive-scheme'
            }
            onClick={() => dispatch(setMetric(metricId))}
          >
            {_METRIC_METADATA[metricId]?.name}
          </div>
        ))}
      </div>
    </div>
  );
}
