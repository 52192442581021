// import React and React hooks
import React, { useEffect, useMemo, useState } from 'react';

// import fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faXmark,
  faSearch,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// import packages
import axios from 'axios';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';

// Set your mapbox access token here
import { MAPBOX_ACCESS_TOKEN, NYC_BBOX } from '../map/constants';

import { useSelector, useDispatch } from 'react-redux';
import { resetSearchParams } from '../../store/slices/communitySearchSlice';
import { setCompareMode } from '../../store/slices/togglesSlice';
import { selectBoundaryData } from '../../store/storeUtils';
import { useCommunitySearchUpdater } from '../../utils/hooks';
import { isValidFeature } from '../../utils/functions';

/**
 * CommunitySearchBar.js renders the community search bars which are used for the primary community search and the secondary community search
 * when compare mode is active. Handles forward geocoding, displaying search options and behavior for when an option is selected
 *
 * @constructor
 * @param {string} toggleValue - value inside the search bar
 * @param {Element[]} children - a list of children for dropdown list, elements ready to be displayed
 * @param {boolean} searchType - if the search box is used for community search or compare search
 * @param {Object} info - contains information calculated in App.js
 */

export default function CommunitySearchBar({
  toggleValue,
  searchType,
  setResize = null,
  setResizeIssues = null,
  children,
}) {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const searchState = useSelector((state) => state.search);
  const boundaryType = useSelector((state) => state.nav.boundaryType);
  const boundaryData = useSelector(selectBoundaryData);
  const compareMode = useSelector((state) => state.toggles.compareMode);
  const dispatch = useDispatch();

  // value of input (searchbar)
  const [value, setValue] = useState('');
  // whether to show dropdown items or not
  const [focus, setFocus] = useState(false);
  // list of objects of search items from forward geocoding transformed into divs
  const [searchItems, setSearchItems] = useState([]);
  // loader
  const [loading, setloading] = useState(true);
  // response from forward geocoding
  const [response, setResponse] = useState(null);
  const [firstMatchedRes, setFirstMatchedRes] = useState([]);
  const [hover, setHover] = useState(false);

  const updateCommunitySearch = useCommunitySearchUpdater();

  useEffect(() => {
    if (toggleValue) {
      setValue(toggleValue);
    } else {
      setValue('');
    }
  }, [toggleValue]);

  useEffect(() => {
    forwardGeocoding(value);
  }, [value]); // monitor at inputValues

  // forward geocoding to get address lookups using mapbox API
  const forwardGeocoding = () => {
    const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=false&limit=5&bbox=${NYC_BBOX}`;
    axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setResponse(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const getBoundaryFeatureFromCoords = (coords) => {
    return boundaryData.features
      .filter(isValidFeature)
      .find(
        (feature) => feature && booleanPointInPolygon(point(coords), feature)
      );
  };

  useEffect(() => {
    if (!response) return;
    if (response.data.features.length === 0) return;

    // take results from geocoding and get elements to display in dropdown
    let firstItem = true;
    let resItems = [];
    for (const v of response.data.features) {
      const region = v.context.find((c) =>
        c.id.startsWith('region')
      )?.short_code;
      if (region !== 'US-NY') {
        // Skipping non-NYC address in search
        continue;
      }
      if (firstItem) {
        setFirstMatchedRes([v.center[0].toFixed(3), v.center[1].toFixed(3)]);
        firstItem = false;
      }
      resItems.push(
        <div
          key={v.id}
          className={`${
            searchState.data.primary.coords &&
            searchState.data.primary.coords[0] === v.center[0].toFixed(3) &&
            searchState.data.primary.coords[1] === v.center[1].toFixed(3)
              ? 'search-item-active'
              : 'search-item-inactive'
          } col search-item p-2`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
            const coords = [
              parseFloat(v.center[0].toFixed(3)),
              parseFloat(v.center[1].toFixed(3)),
            ];
            const boundaryFeature = getBoundaryFeatureFromCoords(coords);
            console.log('Setting search', searchType, boundaryFeature);
            updateCommunitySearch(boundaryFeature, {
              coords,
              source: 'search',
            });
            e.target.blur();
          }}
        >
          <div className={'row w-100 p-0 m-0'}>
            <div className={'col-10 m-0 p-0'}>
              <span style={{ fontWeight: 'bold' }}></span> {v.place_name}
            </div>
            <div
              className={`${
                searchState.data.primary.coords &&
                searchState.data.primary.coords[0] === v.center[0].toFixed(3) &&
                searchState.data.primary.coords[1] === v.center[1].toFixed(3)
                  ? 'visible'
                  : 'invisible'
              } d-flex col-2 p-0 flex-row justify-content-center align-items-center`}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      );
    }
    setSearchItems(resItems);
  }, [response, searchState.data.primary.coords, boundaryType, searchType]); // monitor at response and searchState.data.primary.coords updates

  // function that gets prebaked search and filters appropriately based on query
  const prebakedSearchItems = useMemo(
    () =>
      React.Children.toArray(children).filter((child) => {
        return (
          value.trim() &&
          (child.props.children.props.children[0].props.children[0].props.children
            ?.toLowerCase()
            .includes(value.trim().toLowerCase()) ||
            child.props.children.props.children[0].props.children[2]
              ?.toLowerCase()
              .includes(value.trim().toLowerCase()))
        );
      }),
    [children, value]
  );

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center mt-3 position-relative community-search-container ${
          isMobile ? 'h-100' : ''
        }`}
        onMouseEnter={() => {
          if (searchState.data.primary.query) {
            setHover(true);
          }
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {/* ---------- Clear/Search Icon ---------- */}
        {hover && (toggleValue || compareMode) ? (
          <div
            className="position-absolute remove-community-btn"
            onClick={(e) => {
              e.stopPropagation();
              console.log('Clearing', searchType, 'search');
              dispatch(resetSearchParams({ type: searchType }));
              dispatch(setCompareMode(false));
            }}
            style={{
              color: value ? 'black' : 'white',
            }}
          >
            <FontAwesomeIcon
              style={{ padding: '0.25em 0.5em' }}
              icon={faXmark}
              width={32}
            />
          </div>
        ) : searchType === 'compare' && !compareMode ? (
          <FontAwesomeIcon
            style={{
              position: 'absolute',
              right: '0.5rem',
              color: hover ? 'black' : 'white',
            }}
            icon={faPlus}
            width={32}
            onClick={() => dispatch(setCompareMode(true))}
          />
        ) : (
          <FontAwesomeIcon
            style={{
              position: 'absolute',
              right: '0.5rem',
              pointerEvents: 'none',
              color: 'white',
            }}
            icon={faSearch}
            width={32}
          />
        )}
        {/* ----------- Input box ----------- */}
        <input
          type={'search'}
          id={`${searchType}-search-input`}
          className={`community-search w-100 transition-color ${
            searchType === 'compare' && !compareMode ? 'strong-placeholder' : ''
          }`}
          placeholder={
            searchType === 'primary' || compareMode
              ? `Search for a District, Neighborhood, or Address`
              : 'Compare Communities'
          }
          style={{
            color: value ? 'black' : 'white',
            backgroundColor: value ? 'white' : 'black',
            border:
              (searchState.data.primary.isBadSearch &&
                searchType === 'primary') ||
              (searchState.data.compare.isBadSearch && searchType === 'compare')
                ? '2px solid yellow'
                : isMobile
                ? '2px solid black'
                : '2px solid white',
          }}
          onClick={(e) => {
            e.stopPropagation();
            setResize && setResize(true);
            setResizeIssues && setResizeIssues(false);
          }}
          onFocus={(e) => {
            e.stopPropagation();
            setFocus(true);
            if (searchType === 'compare') {
              console.log('Focused, setting compare mode to true');
              dispatch(setCompareMode(true));
            }
          }}
          onBlur={(e) => {
            e.stopPropagation();
            setFocus(false);
          }}
          onChange={(e) => {
            e.stopPropagation();
            setValue(e.target.value);
          }}
          value={value}
        />
      </div>
      {focus && searchItems.length + prebakedSearchItems.length > 0 && (
        <div>
          <ul className={`list-unstyled community-dropdown`}>
            {prebakedSearchItems}
            {searchItems}
          </ul>
        </div>
      )}
    </>
  );
}
