/**
 * Adapted from https://loading.io/css/
 * 
 * @returns A simple loader component
 */
const Loader = () => {
  return (
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
