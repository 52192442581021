import BoundaryToggle from './BoundaryToggle';
import CommunityNav from './community-profiles/CommunityNav';
import _CDDL from '../img/cddl_logo_white.svg';
import _LCAU from '../img/Logo_LCAU logo_white.svg';
import _MIT from '../img/MIT-logo-white.svg';
import _TA from '../img/ta_logo_BW_icon.svg';
import ShareButton from './ShareButton';

import { useSelector, useDispatch } from 'react-redux';
import { setChapter } from '../store/slices/navigationSlice';
import NavChapter from './NavChapter';
import { selectPrimaryQuery } from '../store/storeUtils';

function Nav() {
  const dispatch = useDispatch();
  const navState = useSelector((state) => state.nav);
  const primarySearchQuery = useSelector(selectPrimaryQuery)

  return (
    <div className={'col-3 h-100 d-flex flex-column'} style={{ zIndex: 3 }}>
      {/* SPATIAL EQUITY NYC CHAPTER NAV */}
      <NavChapter
        chapterId={1}
        selectedChapterId={navState.chapter}
        headerText="Spatial Equity NYC"
        smallText="What is"
        expandHeaderByDefault
      >
        <div className="d-flex flex-column justify-content-end h-100">
          <h6>
            Spatial Equity NYC documents inequities in the ways that public
            space — including streets, sidewalks, and greenspaces — is designed,
            distributed, and accessed.
            <span>
              {' '}
              <a
                className={'underline white-link'}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setChapter(2));
                }}
              >
                Browse citywide data
              </a>
            </span>{' '}
            or
            <span>
              {' '}
              <a
                className={'underline white-link'}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setChapter(3));
                }}
              >
                search community profiles
              </a>
            </span>{' '}
            to learn how decisions about the use of public space lead to unequal
            outcomes and what you can do about it.{' '}
          </h6>
        </div>
        <ShareButton />
      </NavChapter>

      {/* CITYWIDE CHAPTER NAV */}
      <NavChapter
        chapterId={2}
        selectedChapterId={navState.chapter}
        headerText="Citywide Data"
        smallText="Explore Spatial Equity by"
        expandHeaderByDefault
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <BoundaryToggle />
          <ShareButton />
        </div>
      </NavChapter>

      {/* COMMUNITY PROFILES CHAPTER NAV */}
      <NavChapter
        chapterId={3}
        selectedChapterId={navState.chapter}
        headerText="Community Profiles"
        smallText="Explore Spatial Equity by"
        expandHeaderByDefault
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <BoundaryToggle />
            <CommunityNav />
          </div>
          <ShareButton />
        </div>
      </NavChapter>

      {/* TAKE ACTION CHAPTER NAV */}
      <NavChapter
        chapterId={4}
        selectedChapterId={navState.chapter}
        collapsedText="Learn More & Take Action"
        headerText="Take Action"
        smallText="Learn More &"
      >
        <div className="d-flex flex-column justify-content-end h-100">
          <p>
            Spatial Equity NYC is a project of Transportation Alternatives and
            MIT.
          </p>
          <div className="attributions justify-content-center align-items-center d-flex">
            <a href={`https://www.transalt.org/`} target="_blank" rel="noreferrer" style = {{paddingRight: '35px'}}>
              <img src={_TA} height={48} alt="TA" />
            </a>
            <a target="_blank" rel="noreferrer" href={'https://www.mit.edu/'} style = {{margin: '0px'}}>
              <img src={_MIT} height={39} alt="MIT" />
            </a>
            <a target="_blank" rel="noreferrer" href={'https://lcau.mit.edu/'} style = {{paddingLeft: '35px'}}>
              <img src={_LCAU} height={40} alt="LCAU" />
            </a>
          </div>
        </div>
      </NavChapter>
    </div>
  );
}

export default Nav;
