// import React and React Hooks
import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectBoundaryMetadata,
  selectTopChallenges,
} from '../../store/storeUtils';
import { syncProfileMetrics } from '../../store/slices/navigationSlice';
import IssueCardsContainer from './IssueCardsContainer';
import MoreIssuesTags from './MoreIssuesTags';

/**
 * Renders the community profile for the given boundary ID.
 */
export default function CommunityProfile({ boundaryId }) {
  const selectedMetric = useSelector((state) => state.nav.metric);
  const profileMetrics = useSelector((state) => state.nav.profileMetrics);

  const topChallenges = useSelector((state) =>
    selectTopChallenges(state, boundaryId)
  );

  const boundaryMetadata = useSelector((state) =>
    selectBoundaryMetadata(state, boundaryId)
  );

  const displayedTopChallenges = topChallenges.filter((metricId) =>
    profileMetrics.includes(metricId)
  );
  const additionalMetrics = profileMetrics.filter(
    (metricId) => !topChallenges.includes(metricId)
  );

  const dispatch = useDispatch();

  const container = useRef(null);

  function scrollToIssue(id) {
    setTimeout(() => {
      if (!container.current) {
        return;
      }
      if (topChallenges.includes(id)) {
        // Scroll to the top of the page if the issues is a top issue
        container.current.scrollTo(0, 0);
      } else {
        // Scroll to the bottom of the page
        container.current.scrollTop = container.current.scrollHeight;
      }
    }, 100);
  }

  useEffect(() => {
    // Make sure that the additional metrics always contains the top challenges
    // whenever the boundary changes
    if (!topChallenges.length) {
      return;
    }

    dispatch(syncProfileMetrics(topChallenges));
  }, [boundaryId]);

  return (
    <div className={'community-profile-container'} ref={container}>
      <IssueCardsContainer
        metricIds={displayedTopChallenges}
        boundaryMetadata={boundaryMetadata}
        selectedMetric={selectedMetric}
      >
        Top Challenges
      </IssueCardsContainer>
      {additionalMetrics.length > 0 && (
        <IssueCardsContainer
          metricIds={additionalMetrics}
          boundaryMetadata={boundaryMetadata}
          selectedMetric={selectedMetric}
        >
          More Issues
        </IssueCardsContainer>
      )}
      <div className="issue-tags-container">
        <MoreIssuesTags topChallenges={topChallenges} onAdd={scrollToIssue} />
      </div>
    </div>
  );
}
