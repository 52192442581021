import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { setCommuteToggle } from '../../store/slices/togglesSlice';
import { commuteTogglesMeta } from '../../utils/constants';

const TransitToggles = ({ hidden }) => {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const commuteToggles = useSelector((state) => state.toggles.commuteToggles);
  const dispatch = useDispatch();

  const mobileStyle = useMemo(
    () =>
      isMobile
        ? {
            border: '1px solid black',
            padding: '0.5rem',
          }
        : {},
    [isMobile]
  );

  return (
    <div
      className={`transit-toggle`}
      style={{
        justifyContent: isMobile ? 'stretch' : '',
      }}
    >
      {Object.keys(commuteToggles).map(
        (commuteMode) =>
          !hidden.includes(commuteMode) && (
            <div key={commuteMode}>
              <Form style={mobileStyle}>
                <Form.Check
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  inline
                  type={'checkbox'}
                  id={`${commuteMode}-check`}
                  label={commuteTogglesMeta[commuteMode].action}
                  checked={commuteToggles[commuteMode]}
                  onChange={(e) =>
                    dispatch(
                      setCommuteToggle({ [commuteMode]: e.target.checked })
                    )
                  }
                />
              </Form>
            </div>
          )
      )}
    </div>
  );
};

export default TransitToggles;
