import React from 'react';
import {
  getBoundaryName,
  getFormattedNumber,
  ordinalSuffixOf,
} from '../utils/functions';

const RankingText = ({
  boundaryId,
  boundaryType,
  allMetricData,
  selectedMetricMetadata,
}) => {
  const selectedBoundaryData = allMetricData.find(
    (boundary) => boundary.boundaryId === boundaryId
  );

  if (!selectedBoundaryData || !selectedMetricMetadata) {
    return <></>;
  }

  const boundaryName = getBoundaryName(boundaryType, { id: boundaryId });
  const pluralBoundaryName = getBoundaryName(boundaryType, { plural: true });
  const displayedValue = getFormattedNumber(
    selectedBoundaryData.value,
    selectedMetricMetadata
  );
  return (
    <>
      {boundaryName} ranks{' '}
      <strong>
        {ordinalSuffixOf(selectedBoundaryData.rank)} out of{' '}
        {allMetricData.length}
      </strong>{' '}
      {pluralBoundaryName} for {selectedMetricMetadata.name.toLowerCase()} with{' '}
      {selectedMetricMetadata.mapTooltipSnippet.replace("#", displayedValue.toString())}.
      {selectedMetricMetadata.source.methodologyWarning !== '' && (
        <div className="text-secondary fst-italic fw-light">
          {`* `}
          {selectedMetricMetadata.source.methodologyWarning}.
        </div>
      )}
    </>
  );
};

export default RankingText;
