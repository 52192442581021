/**
 * =======================
 * 
 *     CUSTOM TYPES
 * 
 * =======================
 */

/**
 * @typedef HTMLString
 * 
 * A stringified representation of HTML. Used for rendering description/solution
 * paragraphs for each metric.
 * 
 * @type {string}
 */

/**
 * @typedef DeckGLColor
 * 
 * Color array as supported and used by DeckGL. Represents an array of 3 or 4
 * numbers for R, G, B, and (optionally) alpha.
 * 
 * @type {number[]}
 */


/**
 * =======================
 * 
 *     METADATA TYPES
 * 
 * =======================
 */

/**
 * @typedef Representative
 * @property {string} name - The representative's name
 * @property {string} email - URL linking to the representative's email
 * @property {string} [party] - The part of the representative
 */

/**
 * @typedef Source
 * @property {string} name - The name of the source
 * @property {string} url - The URL of the source, normally links to the page
 *    directly preceding the raw data
 * @property {string} year - The year the source was published/last updated
 * @property {string} [methodologyWarning] - A short methodology warning that
 *    appears in the source info tooltips
 */

/**
 * @typedef SolutionInfo
 *
 * Describes the solutions for a particular metric. For example, describes
 * protected bike lanes as a solution for mitigating traffic fatalities. 
 *
 * @property {string[]} imageIds - Array of image names (with extensions) as
 *    they appear in the public/ directory. These images give a visual
 *    representation of the metric's solutions
 * @property {string} actionText - Overall action text
 * @property {HTMLString[]} solutionsList - Array of HTML strings outlining the
 *    solutions with links. Each element can represent a paragraph 
 */

/**
 * @typedef BoundaryMetadata
 *
 * Detail metadata for a single boundary.
 *
 * @property {number} id - The ID of the boundary. Corresponds with one of the
 *    ID properties in BoundaryProperties
 * @property {string} neighborhoods - The neighborhoods encompassed by this
 *    boundary
 * @property {string} [borough] - The borough this boundary is in
 * @property {Representative} [representative] - The information for the
 *    representative of this boundary
 * @property {string} [url] - The URL for this boundary, such as its website
 */

/**
 * @typedef MetricMetadata
 * 
 * Metadata for an individual metric (e.g. description, JSON id, etc.) 
 * 
 * @property {number} id - The numeric ID of the metric
 * @property {number} metricCategoryId - The category ID of the metric
 *    (represents Health, Environment, or Mobility)
 * @property {string} jsonId - The ID of the property as it appears in the raw
 *    geojson file
 * @property {string} name - The short name of the metric (e.g. Asthma)
 * @property {string} title - The title of the metric, as it appears on the
 *    citywide histogram (e.g. Child Asthma Emergency Room Visits)
 * @property {object} boundTexts - The text snippets for upper and lower bounds
 *    for this metric
 * @property {string} boundTexts.max - Text snippet for the maximal value of
 *    this metric (e.g. Most)
 * @property {string} boundTexts.min - Text snippet for the minimal value of
 *    this metric (e.g. Least)
 * @property {string} boundTexts.boundText - Text snippet for the value that
 *    goes with the min and max (e.g. "Asthma" for Most Asthma and Least Asthma) 
 * @property {number[]} related - IDs of related metrics
 * @property {string} units - The longform units of this metric
 * @property {string} histogramTooltipSnippet - The text snippet that appears in
 *  the citywide histograms's tooltip (e.g. "ED visits")
 * @property {string} mapTooltipSnippet - A longer text snippet that follows the
 *    value and provides context and the units
 * @property {string} legendTicksUnitsSymbol - The unit text that appears in each
 *    tick on the legend (e.g. "mph" for bus speeds). NOTE: This is used to
 *    determine the number formatting. Metrics with "%" as the unit symbol will
 *    be automatically multiplied by 100 to be a more user-friendly percentage.
 * @property {string} highlightBoundariesSnippet - The text that displays on the
 *    button to highlight the worst-performing boundaries on the map
 * @property {boolean} higherValueIsBad - Whether or not in a collection of
 *    same-type metric values, higher values indicate worse performance. For
 *    example, Asthma is considered a metric where higher values are bad (higher
 *    Asthma rates are bad), so `higherValueIsBad` would be set to `true`. On
 *    the other hand, Tree Canopy is considered a metric where higher values are
 *    good (higher total tree canopy coverage is better since it is better for
 *    the environment), so `higherValueIsBad` would be set to `false`.
 * @property {Source} source - Object representing the source of this metric
 * @property {HTMLString} description - The description of the metric
 * @property {SolutionInfo} solutionInfo - The solutiosn for this metric
 */

/**
 * @typedef DemographicMetadata
 * 
 * Metadata for an individual demographic
 * 
 * @property {number} id - The numeric demographic ID
 * @property {string} name - The short name of the demographic (e.g. Poverty Level)
 * @property {string} title - The longer title of the demographic, often used as
 *    titles for visualizations (e.g. Households Living Below the Poverty Line)
 * @property {string} lookup - The ID of the property as it appears in the raw
 *    geojson file
 * @property {string} units - The units of the demographic (e.g. Percent)
 * @property {string} units_symbol - The units symbol (e.g. %)
 * @property {DeckGLColor[]} colorRamp - Array of colors (each represented as an
 *    array of 3 elements) used for the legend
 * @property {boolean} multivalue - Whether or not the demographics has multiple
 *    values associated with it, such as commute times and commute modes
 * @property {string[]} [text_list] - The labels for the different buckets (e.g.
 *    "Households Without a Car" and "Other")
 * @property {number | object[]} citywideValue - The hard-coded citywide value
 *    for the demographic since manual aggregation can lead to different
 *    results. Sometimes is an array for multivalue demographics 
 * @property {Source} source - The source for the demographic data
 * 
 */

/**
 * ===================
 * 
 *     DATA TYPES
 * 
 * ===================
 */

/**
 * @typedef BoundaryProperties
 * @property {number} [coun_dist] - ID property for council districts
 * @property {number} [BoroCD] - ID property for community boards
 * @property {number} [DISTRICT] - ID property for state senate and state assembly
 * @property {number} [NTA2020] - ID property for neighborhoods (NTAs)
 *  
 */


/**
 * @typedef BoundaryFeature
 * @property {BoundaryProperties} properties - The properties of the feature. Properties
 *    contain fields such as the ID of the boundary, the metric values for that
 *    particular boundary, the demographic values for that boundary, and more.
 * @property {string} type - The type as a string ('Feature')
 */

/**
 * @typedef FeatureCollection
 * @property {BoundaryFeature[]} features - An array of feature objects each containing properties
 * @property {string} type - The type as a string ('FeatureCollection')
 */

/**
 * @typedef BoundaryMetricData
 * 
 * An object representing a metric value for a specific boundary. Also includes
 * the rank of that boundary. Note the difference between this type and the
 * `BoundaryProperties` type. This represents a more simplified view of a
 * boundary for a specific metric.
 * 
 * @property {number} boundaryId - The ID of the boundary
 * @property {number} value - The raw value of the metric for this boundary
 * @property {number} rank - The rank of the boundary for this metric
 */

/**
 * @typedef CommuteToggles
 * 
 * An object representing the commute toggles for U.S. Census Data
 * 
 * @property {boolean} subway
 * @property {boolean} bus
 * @property {boolean} bike
 * @property {boolean} walk
 * @property {boolean} drive
 * @property {boolean} otherTransit
 */



module.exports = {};
