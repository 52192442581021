/**
 * General utilities for the global Redux store.
 *
 * Contains selector functions for common properties and logic for computing
 * derived data (e.g. visualization data from the selected metric).
 */

import { createSelector } from '@reduxjs/toolkit';
import _METRIC_METADATA from '../meta/metricMetadata.json';
import _METRIC_CATEGORY_METADATA from '../meta/metricCategoryMetadata.json';
import _BOUNDARY_METADATA from '../meta/boundaryMetadata.json';
import {
  getBins,
  getDemographicValues,
  getBoundaryId,
  getMetricData,
  isValidFeature,
} from '../utils/functions';
import _COUNCIL_DISTRICTS from '../data/council_districts.json';
import _COMMUNITY_BOARDS from '../data/community_boards.json';
import _SENATE_DISTRICTS from '../data/senate_districts.json';
import _ASSEMBLY_DISTRICTS from '../data/assembly_districts.json';
import _NEIGHBORHOODS from '../data/neighborhoods.json';
import _DEMOGRAPHICS_METADATA from '../meta/demographics.json';
import { scaleQuantile, scaleThreshold } from 'd3-scale';
import {
  boundaryTypes,
  chapters,
  commuteTogglesMeta,
} from '../utils/constants';
import {
  BoundaryMetadata,
  MetricMetadata,
  BoundaryProperties,
} from '../utils/types';

export const selectCurrentView = (state) => state.nav.views[state.nav.chapter];

/** @returns {MetricMetadata} */
export const selectMetricMetadata = (state) =>
  _METRIC_METADATA[state.nav.metric];

export const selectDemographicMetadata = (state) =>
  _DEMOGRAPHICS_METADATA[state.nav.demographic];

/**
 * Gets boundary metadata if boundaryId is present, otherwise returns all
 * boundary metadata for the current boundary type.
 *
 * @param {number} [boundaryId] - The boundary ID (optional)
 * @returns {BoundaryMetadata | BoundaryMetadata[]} The boundary metadata object, or an
 *    array of boundary metadata objects if not boundary ID is specified
 */
export const selectBoundaryMetadata = (state, boundaryId) => {
  if (boundaryId) {
    return _BOUNDARY_METADATA[state.nav.boundaryType].find(
      (meta) => meta.id === boundaryId
    );
  }
  return _BOUNDARY_METADATA[state.nav.boundaryType];
};

export const selectNumBoundaries = (state) => {
  const boundaryData = selectBoundaryData(state);
  return boundaryData.features.filter(isValidFeature).length;
};

/**
 * Selects the top challenges given the boundary ID.
 *
 * @param {number} boundaryId - The ID of the boundary to get the challenges of
 * @returns {number[]} The IDs of the three top challenge metrics
 */
export const selectTopChallenges = createSelector(
  [
    (state, boundaryId) => selectBoundaryData(state, boundaryId),
    selectNumBoundaries,
  ],
  /**
   * @param {BoundaryProperties} boundaryProperties
   * @param {number} numBoundaries
   */
  (boundaryProperties, numBoundaries) => {
    if (!boundaryProperties) {
      return [];
    }
    const ranks = [];
    for (const [key, val] of Object.entries(boundaryProperties)) {
      // Find all properties and their rank
      if (key.endsWith('_RANK')) {
        const metricJsonId = key.replace('_RANK', '');
        const metricMetadata = Object.values(_METRIC_METADATA).find(
          (meta) => meta.jsonId === metricJsonId
        );
        const metricValue = boundaryProperties[metricJsonId];
        const higherValueIsBad = metricMetadata.higherValueIsBad;
        ranks.push({
          id: metricMetadata.id,
          metricJsonId,
          // Normalized rank is standardized regardless of whether higher values
          // are bad or not. A normalized rank closer to 1 indicates the
          // boundary being "worse" in this metric.
          normalizedRank: metricMetadata.higherValueIsBad
            ? val
            : !metricValue // By default rank worst if the value is 0/null and lower values are bad
            ? 1
            : numBoundaries - val,
          higherValueIsBad,
        });
      }
    }

    // Sort the metrics by normalized rank across the boundaries, and choose the
    // top 3 values.
    const topChallenges = ranks
      .sort((a, b) => a.normalizedRank - b.normalizedRank)
      .slice(0, 3)
      .map((data) => data.id);
    return topChallenges;
  }
);

/**
 * Selects the boundary data properties given the boundary ID.
 *
 * If no boundary ID is provided, returns the entire geojson data.
 *
 * @param {number} [boundaryId] - Optional boundary ID. If provided, returns the
 *  properties of the feature for this boundary.
 * @returns {BoundaryProperties | FeatureCollection}
 */
export const selectBoundaryData = createSelector(
  [(state) => state.nav.boundaryType, (_, boundaryId) => boundaryId],
  (boundaryType, boundaryId) => {
    let boundaryData = null;
    switch (boundaryType) {
      case boundaryTypes.CITY_COUNCIL:
        boundaryData = _COUNCIL_DISTRICTS;
        break;
      case boundaryTypes.COMMUNITY_BOARD:
        boundaryData = _COMMUNITY_BOARDS;
        break;
      case boundaryTypes.STATE_SENATE:
        boundaryData = _SENATE_DISTRICTS;
        break;
      case boundaryTypes.STATE_ASSEMBLY:
        boundaryData = _ASSEMBLY_DISTRICTS;
        break;
      default:
        console.warn('Invalid boundary type, could not load feature data');
    }

    if (boundaryId) {
      return boundaryData.features.find(
        (feature) => getBoundaryId(feature.properties) === Number(boundaryId)
      )?.properties;
    }
    return boundaryData;
  }
);

/**
 * Selects the boundary data for the map (which includes neighborhoods)
 *
 * @returns {FeatureCollection} The boundary data
 */
export const selectMapBoundaryData = createSelector(
  [(state) => state.map.showNeighborhoodData, selectBoundaryData],
  (showNeighborhoodData, boundaryData) => {
    if (showNeighborhoodData) {
      return _NEIGHBORHOODS;
    }
    return boundaryData;
  }
);

export const selectPrimaryQuery = (state) => state.search.data.primary.query;
export const selectCompareQuery = (state) => state.search.data.compare.query;

/**
 * Selects the boundary metadata of the primary search.
 *
 * @returns {null | BoundaryMetadata}
 */
export const selectPrimarySearchMetadata = (state) => {
  const primaryQuery = selectPrimaryQuery(state);
  if (!primaryQuery) {
    return;
  }
  return selectBoundaryMetadata(state, primaryQuery);
};
/**
 * Selects the boundary metadata of the compare search.
 *
 * @returns {null | BoundaryMetadata}
 */
export const selectCompareSearchMetadata = (state) => {
  const compareQuery = selectCompareQuery(state);
  if (!compareQuery) {
    return;
  }
  return selectBoundaryMetadata(state, compareQuery);
};

export const selectColorRamp = createSelector(
  (state) => state.nav.metricCategory,
  (metricCategory) => _METRIC_CATEGORY_METADATA[metricCategory]?.colorRamp || []
);

/**
 * Computed selector that calculates the visualization data based on the
 * currently selected **metric**.
 */
export const selectMetricVizData = createSelector(
  [
    (state) => state.viz.binningMethod,
    selectMapBoundaryData,
    selectBoundaryData,
    selectMetricMetadata,
  ],
  (binningMethod, mapBoundaryData, boundaryData, metricMetadata) => {
    if (!metricMetadata) {
      return { legendBins: [], metricData: null, colorScale: [[]] };
    }

    // Select the color ramp given the metric's issue category
    const colorRamp =
      _METRIC_CATEGORY_METADATA[metricMetadata?.metricCategoryId]?.colorRamp;

    // Get an array of all the values for the selected metric, make sure it has
    // no NaN and no Null values
    const selectedMetricData = getMetricData(mapBoundaryData, metricMetadata);
    const nonNeighborhoodData = metricMetadata
      ? getMetricData(boundaryData, metricMetadata)
      : null;

    // Create a new sorted array for the quantile, but don't modify existing array
    const sortedSelectedMetricArray = [
      ...selectedMetricData
        .map((data) => data.value)
        .filter((val) => !isNaN(val)),
    ];
    sortedSelectedMetricArray.sort((a, b) => a - b);

    const uniqueValueArray = [...new Set(sortedSelectedMetricArray)];

    // Get the data bins
    const bins = getBins(sortedSelectedMetricArray, binningMethod);

    // Get the color scale given the bins
    let colorScale = null;
    if (binningMethod === 'equal') {
      colorScale = scaleThreshold().domain(bins).range(colorRamp);
    } else {
      colorScale = scaleQuantile().domain(uniqueValueArray).range(colorRamp);
    }

    // Legend bins are the first value in the sorted array, followed by the
    // actual bin cutoffs
    const legendBins = [uniqueValueArray[0], bins];

    return { legendBins, metricData: nonNeighborhoodData, colorScale };
  }
);

/**
 * Computed selector that calculates the visualization data based on the
 * currently selected **demographic**.
 */
export const selectDemographicVizData = createSelector(
  [
    (state) => state.nav.demographic,
    (state) => state.viz.binningMethod,
    (state) => state.toggles.commuteToggles,
    selectMapBoundaryData,
  ],
  (demographicId, binningMethod, commuteToggles, mapBoundaryData) => {
    if (!demographicId) {
      return {};
    }

    // Get the demographic values array from the boundary data
    /** @type {import('../utils/types').DemographicMetadata} */
    const demographicMetadata = _DEMOGRAPHICS_METADATA[demographicId];
    const demographicValues = getDemographicValues(
      mapBoundaryData,
      demographicMetadata,
      commuteToggles
    );

    // Calculate the citywide average from the hard-coded values in the
    // demographic metadata
    const citywideAverage = demographicMetadata.citywideValue;

    // Create a new sorted array for the bins, but don't modify existing array
    const sortedDemographicValues = [...demographicValues.values()];
    sortedDemographicValues.sort((a, b) => a - b);

    const uniqueDemographicValues = [...new Set(sortedDemographicValues)];

    // The array of bins for the legend
    const bins = getBins(sortedDemographicValues, binningMethod);

    // Select the color ramp given the metric's issue category
    const colorRamp = _DEMOGRAPHICS_METADATA[demographicId]?.colorRamp;

    // Get the color scale given the bins
    let colorScale = null;
    if (binningMethod === 'equal') {
      colorScale = scaleThreshold().domain(bins).range(colorRamp);
    } else {
      colorScale = scaleQuantile()
        .domain(uniqueDemographicValues)
        .range(colorRamp);
    }

    // Legend bins are the first value in the sorted array, followed by the
    // actual bin cutoffs
    const legendBins = [uniqueDemographicValues[0], ...bins];

    return { legendBins, demographicValues, colorScale, citywideAverage };
  }
);

/**
 * Selects and stringifies the currently selected transportation modes.
 */
export const selectCommuteModesString = createSelector(
  [(state) => state.toggles.commuteToggles, (state) => state.nav.demographic],
  (commuteToggles, demographicId) => {
    // Get the labels to use
    const labels = Object.keys(commuteToggles)
      .filter(
        (toggleId) =>
          commuteTogglesMeta[toggleId].lookups[demographicId] &&
          commuteToggles[toggleId]
      )
      .map((toggleId) => commuteTogglesMeta[toggleId].action);

    if (labels.length === 0) {
      return '...';
    }

    // If more than 1 label, add "or"
    if (labels.length > 1) {
      labels[labels.length - 1] = `or ${labels[labels.length - 1]}`;
    }
    // Return 2 labels without comma separation
    if (labels.length === 2) {
      return labels.join(' ');
    }
    return labels.join(', ');
  }
);

/**
 * Selects whether or not the middle column is visible.
 *
 * The middle column represents the navigation content, and often time replaces
 * the map.
 *
 * @returns {boolean}
 */
export const selectMiddleColumnVisible = (state) =>
  state.search.data.primary.query ||
  state.search.data.compare.query ||
  state.nav.metric ||
  state.map.demographicsVisible;

export const selectMapIsFullScreen = (state) => {
  // If panel is collapsed, default to true
  if (state.toggles.panelIsCollapsed) {
    return true;
  }

  // In the community profiles view, the map is full screen only if the middle
  // column is not visible (representing the user not selecting a boundary)
  if (state.nav.chapter === chapters.COMMUNITY) {
    return !selectMiddleColumnVisible(state);
  }

  return (
    (state.nav.chapter === chapters.CITYWIDE ||
      state.nav.chapter === chapters.COMMUNITY) &&
    state.toggles.panelIsCollapsed
  );
};
