import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import _METRIC_METADATA from '../../meta/metricMetadata.json';
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';
import { useSelector } from 'react-redux';

/**
 * IssuesDropdown.js renders the dropdown component from which the user can toggle the current active indicator
 * @param {int} currentValue - integer representing the current active indicator
 * @param {Object[]} items - list of objects representing indicators of the same category (Health, Environment, Mobility) to display within the dropdown
 */

export default function IssuesDropDown({
  items,
  currentValue = null,
  setValue = null,
}) {
  const showDemographicsTab = useSelector(
    (state) => state.toggles.showDemographicsTab
  );

  // display dropdown items or not
  const [showDropdownItems, setShowDropdownItems] = useState(false);

  // value in dropdown toggle
  const [toggleText, setToggleText] = useState(
    'Select an indicator to explore'
  );

  // if the selected specific issue is within this dropdown
  const [included, setIncluded] = useState(false);

  useEffect(() => {
    // check if selected specific issue is within this dropdown
    let changed = false;
    items.map((item) => {
      if (item.id === currentValue) {
        setToggleText(item.name);
        changed = true;
        setIncluded(true);
      }
    });
    if (!changed) {
      setToggleText('Select an indicator to explore');
      setIncluded(false);
    }
  }, [items, currentValue]);

  return (
    <>
      <div className={'dropdown-container'}>
        <div
          className={`${
            included && currentValue
              ? `dropdown-bar-${
                  _METRIC_CATEGORY_METADATA[
                    _METRIC_METADATA[currentValue].metricCategoryId
                  ].name
                }`
              : 'dropdown-bar-black'
          } dropdown-bar d-flex flex-row justify-content-between align-items-center mb-0 text-white`}
          onMouseDown={() => {
            setShowDropdownItems(!showDropdownItems);
          }}
        >
          <p className={'mb-0 small-font'}>{toggleText}</p>

          {!showDropdownItems && <FontAwesomeIcon icon={faCaretDown} />}
          {showDropdownItems && <FontAwesomeIcon icon={faCaretUp} />}
        </div>

        <div
          className={`${showDropdownItems ? 'd-block' : 'd-none'} ${
            showDemographicsTab ? 'short-dropdown' : 'long-dropdown'
          }
          dropdown-body position-absolute`}
        >
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={`dropdown-item 
                ${currentValue === item.id ? 'dropdown-item-active' : ''}`}
                onMouseDown={() => {
                  setShowDropdownItems(false);
                  setToggleText(item.name);
                  if (currentValue === item.id) {
                    setValue(null);
                    setToggleText('Select an indicator to explore');
                  } else {
                    setValue(item.id);
                  }
                }}
              >
                <p className={'small-font mb-0 ellipses'}>{item.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
