import { useState } from 'react';
import Table from 'react-bootstrap/Table';

import SolutionsCard from '../SolutionsCard';
import Histogram from '../visualizations/Histogram';
import MetricSelectionGrid from '../issue-selection/MetricSelectionGrid';
import CitywideHistogramFooter from '../citywide-data/CitywideHistogramFooter';
import MobileLegendTray from './MobileLegendTray';
import MobileDropdown from './MobileDropdown';

// File imports
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';
import _METRIC_METADATA from '../../meta/metricMetadata.json';

import { useSelector, useDispatch } from 'react-redux';
import { collapseDropdowns } from '../../store/slices/mobileSlice';
import {
  setMetricCategory,
  setMetric,
} from '../../store/slices/navigationSlice';
import { selectColorRamp, selectCurrentView } from '../../store/storeUtils';
import { viewTypes } from '../../utils/constants';
import { sanitize } from 'dompurify';
import RankingTable from '../visualizations/RankingTable';

export default function MobileCitywideData({ isTouchingMapMobile }) {
  const view = useSelector(selectCurrentView);
  const mapVisible = view === viewTypes.MAP;
  const metricCategory = useSelector((state) => state.nav.metricCategory);
  const selectedMetric = useSelector((state) => state.nav.metric);
  const colorRamp = useSelector(selectColorRamp);
  const showDemographicsTab = useSelector(
    (state) => state.toggles.showDemographicsTab
  );
  const dispatch = useDispatch();

  const [useBoroughColor, setUseBoroughColor] = useState(false);

  const health_issues = Object.values(_METRIC_METADATA).filter(
    (metadata) => metadata.metricCategoryId === 1
  );

  const environment_issues = Object.values(_METRIC_METADATA).filter(
    (metadata) => metadata.metricCategoryId === 2
  );

  const infrastructure_issues = Object.values(_METRIC_METADATA).filter(
    (metadata) => metadata.metricCategoryId === 3
  );

  const getSelectionIssues = (category, cat_id, issueType) => {
    if (metricCategory === cat_id) {
      if (!selectedMetric) {
        return (
          <MetricSelectionGrid
            isMobile={true}
            items={issueType}
            currentValue={selectedMetric}
            setValue={(val) => dispatch(setMetric(val))}
          />
        );
      } else {
        return (
          <div
            style={{
              maxHeight:
                metricCategory === cat_id && !selectedMetric ? '50%' : '0',
              transition: 'height 0.5s, opacity 0.5s',
              pointerEvents:
                metricCategory === cat_id && !selectedMetric ? '' : 'none',
              opacity: metricCategory === cat_id && !selectedMetric ? '1' : '0',
              overflow: 'auto',
              border:
                metricCategory === cat_id && !selectedMetric
                  ? '1px solid black'
                  : 'none',
            }}
            onClick={(e) => {
              // console.log('fired onClick');
              e.stopPropagation();
            }}
          >
            <Table
              className={`mobile-issue-dropdown ${
                metricCategory === cat_id
                  ? 'mobile-issue-dropdown-grow'
                  : 'mobile-issue-dropdown-shrink'
              }`}
            >
              <tbody>
                {Object.values(_METRIC_METADATA)
                  .filter((metadata) => metadata.metricCategoryId === category)
                  .map((metadata) => metadata.id)
                  .map((id_) => {
                    return (
                      <tr
                        key={id_}
                        className={`${
                          selectedMetric === id_
                            ? 'active-scheme'
                            : 'inactive-scheme'
                        } transition-color`}
                        onClick={(e) => {
                          if (selectedMetric !== id_) {
                            dispatch(setMetric(id_));
                          } else {
                            dispatch(setMetric(null));
                          }
                        }}
                      >
                        <td
                          className={`${
                            metricCategory === cat_id && !selectedMetric
                              ? 'small-text opacity-100'
                              : 'no-text no-padding opacity-0'
                          } mobile-issue-dropdown-item`}
                        >
                          {_METRIC_METADATA[id_].name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        );
      }
    }
  };

  return (
    <div className={'mobile-citywide'}>
      {!mapVisible && !selectedMetric && (
        <div className="h-100 d-flex flex-column">
          <div
            className={`mobile-citywide-chapter
            ${!selectedMetric ? 'big-padding regular-border' : 'no-border'}
            row-gap
            `}
            style={{
              height:
                metricCategory && metricCategory !== 1 && !selectedMetric
                  ? 'calc(1.375rem + 1.5vw + 3rem)'
                  : selectedMetric
                  ? '0vh'
                  : !metricCategory
                  ? 'calc(100% / 3)'
                  : 'calc((100vh - 4.025rem - 0.3vw) - 2 * (1.375rem + 1.5vw + 3rem)',
            }}
            onClick={() => {
              if (metricCategory !== 1) {
                dispatch(setMetricCategory(1));
              } else {
                dispatch(setMetricCategory(null));
              }
            }}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-between`}
            >
              <p
                className={`mb-0 ${
                  !selectedMetric ? 'big-text' : 'no-text'
                } mobile-transition-font`}
              >
                Health
              </p>
            </div>
            <p
              className={`mb-0 mobile-transition-font
                ${
                  (metricCategory === 1 || !metricCategory) && !selectedMetric
                    ? 'small-text'
                    : 'no-text'
                }`}
            >
              {_METRIC_CATEGORY_METADATA['1'].description}
            </p>

            {getSelectionIssues(1, 1, health_issues)}
          </div>

          <div
            className={`mobile-citywide-chapter
            ${!selectedMetric ? 'big-padding regular-border' : 'border-none'}
                       row-gap`}
            style={{
              height:
                metricCategory && metricCategory !== 2 && !selectedMetric
                  ? 'calc(1.375rem + 1.5vw + 3rem)'
                  : selectedMetric
                  ? '0vh'
                  : !metricCategory
                  ? 'calc(100% / 3)'
                  : 'calc((100vh - 4.025rem - 0.3vw) - 2 * (1.375rem + 1.5vw + 3rem)',
            }}
            onClick={() => {
              if (metricCategory !== 2) {
                dispatch(setMetricCategory(2));
              } else {
                dispatch(setMetricCategory(null));
              }
            }}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-between`}
            >
              <p
                className={`mb-0 ${
                  !selectedMetric ? 'big-text' : 'no-text'
                } mobile-transition-font`}
              >
                Environment
              </p>
            </div>
            <p
              className={`mb-0 mobile-transition-font
                ${
                  (metricCategory === 2 || !metricCategory) && !selectedMetric
                    ? 'small-text'
                    : 'no-text'
                }`}
            >
              {_METRIC_CATEGORY_METADATA['2'].description}
            </p>
            {getSelectionIssues(2, 2, environment_issues)}
          </div>

          <div
            className={`mobile-citywide-chapter
            ${!selectedMetric ? 'big-padding regular-border' : 'border-none'}
             row-gap`}
            style={{
              height:
                metricCategory && metricCategory !== 3 && !selectedMetric
                  ? 'calc(1.375rem + 1.5vw + 3rem)'
                  : selectedMetric
                  ? '0vh'
                  : !metricCategory
                  ? 'calc(100% / 3)'
                  : 'calc((100vh - 4.025rem - 0.3vw) - 2 * (1.375rem + 1.5vw + 3rem)',
            }}
            onClick={() => {
              if (metricCategory !== 3) {
                dispatch(setMetricCategory(3));
              } else {
                dispatch(setMetricCategory(null));
              }
            }}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-between`}
            >
              <p
                className={`mb-0 ${
                  !selectedMetric ? 'big-text' : 'no-text'
                } mobile-transition-font`}
              >
                Mobility
              </p>
            </div>
            <p
              className={`mb-0 mobile-transition-font
                ${
                  (metricCategory === 3 || !metricCategory) && !selectedMetric
                    ? 'small-text'
                    : 'no-text'
                }`}
            >
              {_METRIC_CATEGORY_METADATA['3'].description}
            </p>
            {getSelectionIssues(3, 3, infrastructure_issues)}
          </div>
        </div>
      )}

      {/* grid container for citywide data */}
      {(mapVisible || selectedMetric) && (
        <div
          className={'mobile-issues-profile-container'}
          style={{ zIndex: '1' }}
        >
          {/* first grid child - metric dropdown menu */}
          <MobileDropdown />

          {/* second grid child - body, including histogram */}
          <div
            className="citywide-non-map-content"
            style={{
              // Hide the citywide non-map content if showing map
              opacity: mapVisible ? 0 : 1,
              pointerEvents: mapVisible ? 'none' : 'auto',
              transition: 'height 0.5s',
              overflow: 'auto',
              position: 'relative',
              height: '100%',
              backgroundColor: 'white',
            }}
            onTouchMove={() => {
              dispatch(collapseDropdowns());
            }}
          >
            {selectedMetric && (
              <>
                {(!mapVisible || !showDemographicsTab) && (
                  <div>
                    <div
                      className={'pb-3'}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          _METRIC_METADATA[selectedMetric].description,
                          { ADD_ATTR: ['target'] }
                        ),
                      }}
                    ></div>
                    <div className={'link-underline'}>
                      <strong>Related:</strong>
                      {_METRIC_METADATA[selectedMetric].related.map(
                        (metricId, index) => (
                          <span key={metricId}>
                            {' '}
                            <a
                              style={{ textDecoration: 'underline' }}
                              onClick={() => {
                                dispatch(setMetric(metricId));
                              }}
                            >
                              {_METRIC_METADATA[metricId].name}
                            </a>
                            {index === 2 ? '.' : ','}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    flex: 1,
                    height: view === viewTypes.HISTOGRAM ? '90%' : '',
                  }}
                  className={`histogram-responsive-box ${
                    view === viewTypes.HISTOGRAM ? 'mobile-histogram' : ''
                  }`}
                >
                  {view === viewTypes.HISTOGRAM ? (
                    <Histogram
                      colorRamp={colorRamp}
                      useBoroughColor={useBoroughColor}
                    />
                  ) : (
                    <RankingTable
                      metricMetadata={_METRIC_METADATA[selectedMetric]}
                      target
                    />
                  )}
                </div>
                {/* if non map mode */}
                {!mapVisible && view === viewTypes.HISTOGRAM && (
                  <CitywideHistogramFooter
                    useBoroughColor={useBoroughColor}
                    setUseBoroughColor={setUseBoroughColor}
                    citywideTab={true}
                  />
                )}

                <SolutionsCard issue={_METRIC_METADATA[selectedMetric]} />
              </>
            )}
          </div>

          {/* third child - legend tray */}
          {/* if non map mode */}
          {mapVisible && (
            <MobileLegendTray isTouchingMapMobile={isTouchingMapMobile} />
          )}
        </div>
      )}
    </div>
  );
}
