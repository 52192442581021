// import React and React hooks
import React, { useState, useEffect } from 'react';

//import components
import SolutionsCard from '../SolutionsCard';
import Histogram from '../visualizations/Histogram';
import Header from '../Header';
import CitywideHistogramFooter from './CitywideHistogramFooter';

// import packages
import Typewriter from 'typewriter-effect';

import { useSelector } from 'react-redux';
import { selectColorRamp, selectMetricMetadata } from '../../store/storeUtils';
import IssueSelectionColumn from '../issue-selection/IssueSelectionColumn';
import RankingTable from '../visualizations/RankingTable';
import { chapters, viewTypes } from '../../utils/constants';

const backgroundImages = [
  'access-square.jpg',
  'bikepath-square.jpg',
  'city-square.jpg',
  'crosswalk-square.jpg',
  'traffic-square.jpg',
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

/**
 * CitywideContent.js renders the rightmost column of the dataview of the Citywide data section
 */
export default function CitywideContent({ setMoreIssues }) {
  const view = useSelector((state) => state.nav.views[chapters.CITYWIDE]);

  const navState = useSelector((state) => state.nav);
  const panelIsCollapsed = useSelector(
    (state) => state.toggles.panelIsCollapsed
  );
  const metricMetadata = useSelector(selectMetricMetadata);
  const colorRamp = useSelector(selectColorRamp);

  // current background image
  const [imageIndex, setImageIndex] = useState(0);
  const [useBoroughColor, setUseBoroughColor] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let index = getRandomInt(0, backgroundImages.length);
    let counter = 0;
    while (index === imageIndex && counter <= 5) {
      counter++;
      index = getRandomInt(0, backgroundImages.length);
    }
    setImageIndex(index);
  }, [navState.chapter, navState.metricCategory]);

  useEffect(() => {
    if (navState.metric) {
      let div = document.getElementsByClassName(
        'issues-tile-text-container'
      )[0];
      if (div) {
        div.scrollBy({
          top: -div.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  }, [navState.metric]);

  const getBackgroundImage = () => {
    const randomImage = backgroundImages[imageIndex];

    return (
      <div>
        <img
          className={`backsplash-image`}
          src={`/stills/${randomImage}`}
          alt={''}
        />
      </div>
    );
  };

  return (
    <>
      {/* ISSUE SELECTION COLUMN */}
      <div
        className={`${
          view !== viewTypes.MAP ? `d-flex flex-column` : ''
        } middle-column middle-transition
    h-100 ${
      view === viewTypes.MAP && panelIsCollapsed
        ? 'collapsed-middle-column'
        : 'col-4 no-top-border'
    }`}
        id="issue-selection"
      >
        {<IssueSelectionColumn />}
      </div>
      {/* SPLASH SCREEN (if no metric selected) */}
      {!navState.metric && view !== viewTypes.MAP && (
        <div className="d-flex flex-column col-8" id="citywide-splash-screen">
          <div className={'pb-3'}>{getBackgroundImage()}</div>
          <div
            className={'typewriter-container p-5'}
            style={{
              position: 'absolute',
              zIndex: '100',
              top: '0',
            }}
          >
            <div>Try choosing...</div>
            <Typewriter
              options={{
                strings: [
                  'Health to learn about asthma, pollution, and traffic violence',
                  'Environment to learn about heat, flooding, and trees',
                  'Mobility to learn about traffic, infrastructure, and transportation',
                ],
                autoStart: true,
                loop: true,
                pauseFor: 1500,
                delay: 40,
              }}
            />
          </div>
        </div>
      )}
      {/* CITYWIDE CONTENT (metric is selected) */}
      {navState.metric && view !== viewTypes.MAP && (
        <div
          className={'col-8 h-100 issues-tile-container p-0'}
          id="citywide-metric-content"
        >
          <div className={'issues-tile-body h-100'}>
            <div
              className={'issue-tile-viz position-relative'}
              style={{ outline: '1px solid black' }}
            >
              <Header
                metricId={navState.metric}
                type={'histogram header'}
                setMoreIssues={setMoreIssues}
              />
              <div style={{ flex: 1 }} className={'histogram-responsive-box'}>
                {view === viewTypes.HISTOGRAM ? (
                  <Histogram
                    colorRamp={colorRamp}
                    useBoroughColor={useBoroughColor}
                    setUseBoroughColor={setUseBoroughColor}
                    isHovering={isHovering}
                    setIsHovering={setIsHovering}
                  />
                ) : (
                  <RankingTable
                    metricMetadata={metricMetadata}
                    citywideTab={true}
                  />
                )}
              </div>
              {view === viewTypes.HISTOGRAM && (
                <CitywideHistogramFooter
                  useBoroughColor={useBoroughColor}
                  setUseBoroughColor={setUseBoroughColor}
                  setIsHovering={setIsHovering}
                />
              )}
            </div>

            {/* SOLUTIONS */}
            <div
              className={'d-flex flex-column col-6 w-50'}
              style={{ outline: '1px solid black', borderTop: '2px solid black' }}
            >
              <SolutionsCard issue={metricMetadata} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
