/**
 * Stores state for general toggles within the site.
 */

import { createSlice } from '@reduxjs/toolkit';
import { boolifyString } from '../../utils/functions';
import { CommuteToggles } from '../../utils/types';

const initialState = {
  /**
   * Whether or not to show the toggle between the dataview and mapview
   * Previously `showToggle`
   *
   * @type {boolean}
   */
  showViewToggle: false,
  /**
   * Whether or not the demographics tab is expanded
   * Previously `showDemographics`
   *
   * @type {boolean}
   */
  showDemographicsTab: false,
  /**
   * Whether or not to highlight underperforming areas
   * Previously `toggleUnderperformers`
   *
   * @type {boolean}
   */
  showUnderperformers: false,
  /**
   * Whether or not compare mode is on (i.e. user selection adds the comparison
   * community)
   * Previously `addCompare`
   *
   * @type {boolean}
   */
  compareMode: false,
  /**
   * True if user checks to include Transit, Bike, or Walk in alternative
   * commute to work within the mobility census data.
   *
   * @type {CommuteToggles}
   */
  commuteToggles: {
    subway: true,
    bus: true,
    bike: true,
    walk: true,
    drive: true,
    otherTransit: true,
  },
  /** Whether or not the middle panel is collapsed (to show more of the map) */
  panelIsCollapsed: false,
  showSolutions: false,
};

const togglesSlice = createSlice({
  name: 'toggles',
  initialState,
  reducers: {
    setCompareMode(state, action) {
      state.compareMode = action.payload;
    },
    toggleCompareMode(state) {
      state.compareMode = !state.compareMode;
    },
    setShowDemographicsTab(state, action) {
      state.showDemographicsTab = action.payload;
    },
    toggleDemographicsTab(state) {
      state.showDemographicsTab = !state.showDemographicsTab;
    },
    setShowViewToggle(state, action) {
      state.showViewToggle = action.payload;
    },
    setShowUnderperformers(state, action) {
      state.showUnderperformers = action.payload;
    },
    toggleUnderperformers(state) {
      state.showUnderperformers = !state.showUnderperformers;
    },
    setCommuteTogglesFromString(state, action) {
      const togglesString = action.payload;
      state.commuteToggles.subway = boolifyString(togglesString[0]);
      state.commuteToggles.bus = boolifyString(togglesString[1]);
      state.commuteToggles.bike = boolifyString(togglesString[2]);
      state.commuteToggles.walk = boolifyString(togglesString[3]);
      state.commuteToggles.drive = boolifyString(togglesString[4]);
      state.commuteToggles.otherTransit = boolifyString(togglesString[5]);
    },
    setCommuteToggle(state, action) {
      const { subway, bus, bike, walk, drive, otherTransit } = action.payload;
      if (bike !== undefined) {
        state.commuteToggles.bike = bike;
      }
      if (walk !== undefined) {
        state.commuteToggles.walk = walk;
      }
      if (bus !== undefined) {
        state.commuteToggles.bus = bus;
      }
      if (subway !== undefined) {
        state.commuteToggles.subway = subway;
      }
      if (drive !== undefined) {
        state.commuteToggles.drive = drive;
      }
      if (otherTransit !== undefined) {
        state.commuteToggles.otherTransit = otherTransit;
      }
    },
    setPanelIsCollapsed(state, action) {
      state.panelIsCollapsed = action.payload;
    },
    togglePanelIsCollapsed(state) {
      state.panelIsCollapsed = !state.panelIsCollapsed;
    },
    toggleShowSolutions(state) {
      state.showSolutions = !state.showSolutions;
    },
    setShowSolutions(state, action) {
      state.showSolutions = action.payload;
    },
  },
});

export const {
  setCompareMode,
  toggleCompareMode,
  setCommuteToggle,
  setShowDemographicsTab,
  toggleDemographicsTab,
  setShowViewToggle,
  setShowUnderperformers,
  toggleUnderperformers,
  setPanelIsCollapsed,
  togglePanelIsCollapsed,
  toggleShowSolutions,
  setShowSolutions,
  setCommuteTogglesFromString,
} = togglesSlice.actions;
export default togglesSlice.reducer;
