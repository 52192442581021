// import React and React hooks
import React from 'react';

// import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

// import data and / or text
import _METRIC_METADATA from '../../meta/metricMetadata.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushProfileMetric,
  removeProfileMetric,
} from '../../store/slices/navigationSlice';

/**
 * MoreIssuesTags.js renders the section of tags.
 * @constructor
 * @param {int[]} topChallenges - list of integers representing the least performing issues of the current selected community *
 */

export default function MoreIssuesTags({ topChallenges, onAdd }) {
  const profileMetrics = useSelector((state) => state.nav.profileMetrics);

  const dispatch = useDispatch();

  // Make sure the IDs are numbers
  const allMetricIds = Object.keys(_METRIC_METADATA).map(Number);

  return (
    <div className={'issue-tags'}>
      {allMetricIds
        .sort((id1, id2) => (topChallenges.includes(id1) ? -1 : 1))
        .map((id, index) => {
          return (
            <div
              key={index}
              className={`${
                profileMetrics.includes(id) ? 'active-tag' : 'inactive-tag'
              } issues-tag small-col-gap`}
              style={{border: topChallenges.includes(id) ? '4px double black': '2px solid black'}}
              onClick={() => {
                if (profileMetrics.includes(id)) {
                  dispatch(removeProfileMetric(id));
                } else {
                  dispatch(pushProfileMetric(id));
                  onAdd(id);
                }
              }}
            >
              <p className={'m-0 small-font'}>
                {id !== 6 && id !== 5
                  ? _METRIC_METADATA[id].name
                  : _METRIC_METADATA[id].id === 6
                  ? 'Permeable Surface Area'
                  : 'Surface Temperature'}
              </p>
              {profileMetrics.includes(id) ? (
                <FontAwesomeIcon icon={faMinus} />
              ) : (
                <FontAwesomeIcon icon={faPlus} />
              )}
            </div>
          );
        })}
    </div>
  );
}
