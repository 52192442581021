import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setView } from '../store/slices/navigationSlice';
import { selectCurrentView } from '../store/storeUtils';
import { chapters } from '../utils/constants';
import { useGetBoundaryName } from '../utils/hooks';

const ViewToggleButton = ({ viewType, icon, setHover, children }) => {
  const chapter = useSelector((state) => state.nav.chapter);
  const currentView = useSelector(selectCurrentView);
  const getBoundaryName = useGetBoundaryName();

  const dispatch = useDispatch();

  const isSelected = viewType === currentView;

  return (
    <div
      className={`${
        isSelected ? 'active-scheme-black-bg' : 'inactive-scheme-black-bg'
      } map-toggle`}
      style = {{
        height: '25px',
        width: '25px'
      }}
      onClick={() => dispatch(setView({ chapter, newView: viewType }))}
      onMouseEnter={() => {
        setHover(
          `${children} ${getBoundaryName({
            plural: chapter === chapters.CITYWIDE,
            length: 'medium',
          })}`
        );
      }}
      onMouseLeave={() => {
        setHover(null);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

export default ViewToggleButton;
