import React from 'react';
import { useDispatch } from 'react-redux';
import { setChapter } from '../store/slices/navigationSlice';

const NavChapter = ({
  chapterId,
  selectedChapterId,
  children,
  collapsedText,
  headerText,
  smallText,
  onSelect,
  expandHeaderByDefault,
}) => {
  const dispatch = useDispatch();

  const expandHeader =
    (!selectedChapterId && expandHeaderByDefault) ||
    selectedChapterId === chapterId;

  return (
    <div
      className={`position-relative nav-chapters d-flex flex-column
         ${
           !selectedChapterId
             ? expandHeaderByDefault
               ? ''
               : 'flex-grow-0'
             : selectedChapterId === chapterId
             ? 'expanded-nav'
             : 'collapsed-nav'
         } ${chapterId === 1 ? 'top-border' : ''}`}
      onClick={() => {
        dispatch(setChapter(chapterId));
        onSelect && onSelect();
      }}
    >
      <header id={`nav-chapter-${chapterId}-header-container`}>
        <h6
          className={`nav-title mb-0 ${!collapsedText ? 'collapse-text' : ''}`}
        >
          {expandHeader ? smallText : collapsedText}
        </h6>
        {/* The main header, shows up when collapsed */}
        <p
          className={`${
            expandHeader
              ? 'h1'
              : `mb-0  h6 ${collapsedText ? 'collapse-nav-title' : ''}`
          } transition-font`}
        >
          {headerText}
        </p>
      </header>

      <div
        className={`${
          selectedChapterId === chapterId
            ? 'nav-chapters-content-expanded'
            : 'nav-chapters-content'
        } `}
      >
        {selectedChapterId === chapterId && children}
      </div>
    </div>
  );
};

export default NavChapter;
