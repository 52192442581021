import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import SourceInfo from '../SourceInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectColorRamp,
  selectCurrentView,
  selectMetricMetadata,
  selectMetricVizData,
} from '../../store/storeUtils';
import { toggleUnderperformers } from '../../store/slices/togglesSlice';
import { useGetBoundaryName } from '../../utils/hooks';
import LegendColorBar from './LegendColorBar';
import { viewTypes } from '../../utils/constants';

/**
 * Metric Legend
 */
export default function Legend() {
  const view = useSelector(selectCurrentView);
  const mobileState = useSelector((state) => state.mobile);
  const metricMetadata = useSelector(selectMetricMetadata);
  const { legendBins: metricLegendBins } = useSelector(selectMetricVizData);
  const selectedMetricColorRamp = useSelector(selectColorRamp);

  const showUnderperformers = useSelector(
    (state) => state.toggles.showUnderperformers
  );

  const getBoundaryName = useGetBoundaryName();
  const boundaryTypeText = getBoundaryName({
    length: 'medium',
    plural: true,
  });

  const dispatch = useDispatch();

  return !metricMetadata || metricLegendBins[0] === undefined ? (
    <div className={'placeholder-legend'}>
      {metricMetadata && 'Error generating legend'}
    </div>
  ) : (
    <>
      {/* Legend text and color bar */}
      <div className="w-100">
        <div className={'small-font mb-1'}>
          {metricMetadata.units}{' '}
          {!mobileState.isMobile && (
            <SourceInfo metricMetadata={metricMetadata} />
          )}
        </div>
        <LegendColorBar
          dataBins={[metricLegendBins[0], ...metricLegendBins[1]]}
          colorRamp={selectedMetricColorRamp}
          unitsSymbol={metricMetadata.legendTicksUnitsSymbol}
        />
      </div>
      {/* Highlight underperformers button */}
      {view === viewTypes.MAP && !mobileState.isMobile && (
        <div
          className={`big-button small-font ${
            showUnderperformers ? 'big-button-active' : 'big-button-inactive'
          }`}
          onClick={() => dispatch(toggleUnderperformers())}
        >
          {showUnderperformers ? 'Remove highlights of' : 'Highlight'}{' '}
          {boundaryTypeText} with the{' '}
          {metricMetadata.highlightBoundariesSnippet}.
          <div>
            <FontAwesomeIcon icon={showUnderperformers ? faMinus : faPlus} />
          </div>
        </div>
      )}
    </>
  );
}
