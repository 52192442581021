import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { useDispatch } from 'react-redux';
import { setAboutSection, setChapter } from '../store/slices/navigationSlice';
import { chapters } from '../utils/constants';

export default function SourceInfo({
  metricMetadata,
  verticalHistogram = false,
  type,
}) {
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setShowInfo(true);
      }}
      onMouseLeave={() => {
        setShowInfo(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setChapter(chapters.ABOUT));
        dispatch(setAboutSection(9));
      }}
      className={'d-inline-block'}
    >
      <FontAwesomeIcon
        style={
          showInfo
            ? {
                display: 'inline-block',
                fontSize: '1rem',
                cursor: 'pointer',
                transform: 'scale(1.1)',
              }
            : { fontSize: '1rem', cursor: 'pointer' }
        }
        icon={faCircleInfo}
      />
      {type === 'demographic' && (
        <div
          className={`${
            showInfo ? '' : 'd-none'
          } position-absolute info-tooltip smaller-text`}
        >
          <p className={'m-0'}>Click to learn more about U.S Census Data.</p>
        </div>
      )}

      {type !== 'demographic' && (
        <div
          className={`${
            showInfo ? '' : 'd-none'
          } position-absolute info-tooltip smaller-text ${
            verticalHistogram ? '' : 'end-0'
          }`}
        >
          <p className={'m-0'}>
            {`Source: ${metricMetadata?.source.name}. ${metricMetadata?.source.year}.
            `}
          </p>
          <span className="text-secondary fst-italic fw-light">
            {metricMetadata?.source.methodologyWarning
              ? `*
           ${metricMetadata?.source.methodologyWarning}.
           Click to learn more.`
              : 'Click to learn more.'}
          </span>
        </div>
      )}
    </div>
  );
}
