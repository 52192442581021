import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';

import _METRIC_METADATA from '../../meta/metricMetadata.json';
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';

import { useDispatch, useSelector } from 'react-redux';
import {
  collapseDropdowns,
  openSubDropdown,
  toggleDropdown,
} from '../../store/slices/mobileSlice';
import {
  setMetricCategory,
  setMetric,
} from '../../store/slices/navigationSlice';

export default function MobileDropdown() {
  const dropdownVisible = useSelector((state) => state.mobile.dropdownVisible);
  const subDropdownVisible = useSelector(
    (state) => state.mobile.subDropdownVisible
  );
  const navState = useSelector((state) => state.nav);
  const dispatch = useDispatch();

  return (
    <div className="position-relative">
      <div className={'mobile-citywide-nav'}>
        <div
          className={'mobile-citywide-nav-dropdown w-100'}
          onClick={() => dispatch(toggleDropdown())}
        >
          {navState.metric ? (
            <div className={'mobile-citywide-nav-text'}>
              <div>
                {navState.chapter &&
                  _METRIC_CATEGORY_METADATA[navState.metricCategory].name}
              </div>
              <FontAwesomeIcon icon={faPlay} />
              <div className={'ellipses'}>
                {navState.metric && _METRIC_METADATA[navState.metric].name}
              </div>
            </div>
          ) : (
            <div className={'mobile-citywide-nav-text'}>Select</div>
          )}
          <FontAwesomeIcon icon={dropdownVisible ? faCaretUp : faCaretDown} />
        </div>
      </div>
      <div className={'position-absolute'}>
        <div
          className={`mobile-citywide-nav-dropdown-item
                    ${
                      dropdownVisible
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }  
                    ${
                      subDropdownVisible === 1
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
          style={{ width: '100vw' }}
          onClick={() => {
            dispatch(openSubDropdown(1));
          }}
        >
          <div>Health</div>
          <FontAwesomeIcon
            icon={subDropdownVisible === 1 ? faCaretUp : faCaretDown}
          />
        </div>

        {Object.values(_METRIC_METADATA)
          .filter((metadata) => metadata.metricCategoryId === 1)
          .map((metadata) => metadata.id)
          .map((id) => {
            return (
              <div
                key={id}
                className={`mobile-citywide-nav-dropdown-item
                    ${
                      dropdownVisible && subDropdownVisible === 1
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }  
                    ${
                      navState.metric === id
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
                style={{ width: '100vw' }}
                onClick={() => {
                  if (navState.metric !== id) {
                    dispatch(setMetric(id));
                    dispatch(setMetricCategory(1));
                  } else {
                    dispatch(setMetric(null));
                  }
                  dispatch(collapseDropdowns());
                }}
              >
                <div>
                  <p
                    className={'mb-0'}
                    style={{
                      fontSize:
                        dropdownVisible && subDropdownVisible === 1
                          ? '0.8em'
                          : '0',
                      opacity:
                        dropdownVisible && subDropdownVisible === 1 ? '1' : '0',
                      transition: 'font-size 0.2s, opacity 0.3s',
                    }}
                  >
                    {_METRIC_METADATA[id].name}
                  </p>
                </div>
              </div>
            );
          })}

        <div
          className={`mobile-citywide-nav-dropdown-item
                    ${
                      dropdownVisible
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }  
                    ${
                      subDropdownVisible === 2
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
          style={{ width: '100vw' }}
          onClick={() => {
            dispatch(openSubDropdown(2));
          }}
        >
          <div>Environment</div>
          <FontAwesomeIcon
            icon={subDropdownVisible === 2 ? faCaretUp : faCaretDown}
          />
        </div>

        {Object.values(_METRIC_METADATA)
          .filter((metadata) => metadata.metricCategoryId === 2)
          .map((metadata) => metadata.id)
          .map((id) => {
            return (
              <div
                key={id}
                className={`mobile-citywide-nav-dropdown-item
                    ${
                      dropdownVisible && subDropdownVisible === 2
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }  
                    ${
                      navState.metric === id
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
                style={{ width: '100vw' }}
                onClick={() => {
                  if (navState.metric !== id) {
                    dispatch(setMetric(id));
                    dispatch(setMetricCategory(2));
                  } else {
                    dispatch(setMetric(null));
                  }
                  dispatch(collapseDropdowns());
                }}
              >
                <div>
                  <p
                    className={'mb-0'}
                    style={{
                      fontSize:
                        dropdownVisible && subDropdownVisible === 2
                          ? '0.8em'
                          : '0',
                      opacity:
                        dropdownVisible && subDropdownVisible === 2 ? '1' : '0',
                      transition: 'font-size 0.2s, opacity 0.3s',
                    }}
                  >
                    {_METRIC_METADATA[id].name}
                  </p>
                </div>
              </div>
            );
          })}

        <div
          className={`mobile-citywide-nav-dropdown-item 
                    ${
                      dropdownVisible
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }                      
                    ${
                      subDropdownVisible === 3
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
          style={{ width: '100vw' }}
          onClick={() => {
            dispatch(openSubDropdown(3));
          }}
        >
          <div>Mobility</div>
          <FontAwesomeIcon
            icon={subDropdownVisible === 3 ? faCaretUp : faCaretDown}
          />
        </div>

        {Object.values(_METRIC_METADATA)
          .filter((metadata) => metadata.metricCategoryId === 3)
          .map((metadata) => metadata.id)
          .map((id) => {
            return (
              <div
                key={id}
                className={`mobile-citywide-nav-dropdown-item
                    ${
                      dropdownVisible && subDropdownVisible === 3
                        ? 'mobile-citywide-nav-dropdown-item-grow'
                        : 'mobile-citywide-nav-dropdown-item-shrink'
                    }  
                    ${
                      navState.metric === id
                        ? 'active-scheme'
                        : 'inactive-scheme'
                    }
                    `}
                style={{ width: '100vw' }}
                onClick={() => {
                  if (navState.metric !== id) {
                    dispatch(setMetric(id));
                    dispatch(setMetricCategory(3));
                  } else {
                    dispatch(setMetric(null));
                  }
                  dispatch(collapseDropdowns());
                }}
              >
                <div>
                  <p
                    className={'mb-0'}
                    style={{
                      fontSize:
                        dropdownVisible && subDropdownVisible === 3
                          ? '0.8em'
                          : '0',
                      opacity:
                        dropdownVisible && subDropdownVisible === 3 ? '1' : '0',
                      transition: 'font-size 0.s, opacity 0.3s',
                    }}
                  >
                    {_METRIC_METADATA[id].name}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
