import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearPinnedBoundaries } from '../../store/slices/vizSlice';
import { useState } from 'react';

export default function CitywideHistogramFooter({
  useBoroughColor,
  setUseBoroughColor,
  citywideTab,
}) {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const boundaryType = useSelector((state) => state.nav.boundaryType);
  const pinnedBoundaries = useSelector((state) => state.viz.pinnedBoundaries);
  const dispatch = useDispatch();
  const [isHovered, setHoverStatus] = useState(false)

  return (
    <div
      className="py-0"
      onClick={() => {
        setUseBoroughColor(!useBoroughColor);
      }}
      onMouseEnter={() => setHoverStatus(true)}
      onMouseLeave={() => setHoverStatus(false)}
    >
      <div
        className={`${isMobile && citywideTab
          ? 'issues-chapters-inactive'
          : 'issues-chapters issues-chapters-active collapse-issue top-border'
          } transition-height`}
        style={{
          backgroundColor: isMobile
            ? 'white'
            : useBoroughColor
              ? 'black'
              : 'white',
        }}
        id='city-wide-footer'
      >
        <div
          className="position-relative d-flex "
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '1rem',
            color: useBoroughColor ? 'white' : 'black',
          }}
        >
          <h6
            className={`mb-0 ${isMobile
              ? `big-button ${useBoroughColor
                ? 'big-button-active'
                : 'big-button-inactive'
              }`
              : 'hover-underline'
              }`}
            // onClick={() => {
            //   setUseBoroughColor(!useBoroughColor);
            // }}
            style={{
              cursor: 'pointer',
              padding: 0,
              backgroundColor: useBoroughColor || isHovered ? 'black' : 'white',
            }}
          >
            {useBoroughColor ? `Hide Borough` : `Show Borough`}{' '}
          </h6>
          <FontAwesomeIcon icon={useBoroughColor ? faMinus : faPlus} />

          {/* <h6
            className={`mb-0 ${isMobile ? 'big-button' : 'hover-underline'}`}
            style={{
              visibility:
                pinnedBoundaries[boundaryType]?.length > 0 ? '' : 'hidden',
              border: isMobile ? '2px solid black' : '',
              cursor: 'pointer',
              color: 'red'
            }}
            onClick={() => dispatch(clearPinnedBoundaries(boundaryType))}
          >
            Clear Pins <FontAwesomeIcon icon={faXmark} />
          </h6> */}
        </div>
      </div>
    </div>
  );
}
