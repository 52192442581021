import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentView,
  selectMetricMetadata,
} from '../../store/storeUtils';

import IssuesDropDown from './IssuesDropDown';
import IssuesGrid from './MetricSelectionGrid';
import Legend from '../visualizations/Legend';
import MapToggle from '../map/MapToggle';

import _METRIC_METADATA from '../../meta/metricMetadata.json';
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';
import {
  setMetricCategory,
  setMetric,
} from '../../store/slices/navigationSlice';
import { setShowDemographicsTab } from '../../store/slices/togglesSlice';
import { sanitize } from 'dompurify';
import { useRef, useEffect } from 'react';
import { setHeaderHeight } from '../../store/slices/vizSlice';
import { chapters, viewTypes } from '../../utils/constants';
import MapLegend from '../visualizations/MapLegend';

const IssueSelection = ({ categoryId }) => {
  const view = useSelector(selectCurrentView);
  const selectedCategoryId = useSelector((state) => state.nav.metricCategory);
  const selectedMetric = useSelector((state) => state.nav.metric);
  const selectedChapter = useSelector((state) => state.nav.chapter);
  const selectedMetricMetadata = useSelector(selectMetricMetadata);
  const header = useRef(null);
  const showDemographicsTab = useSelector(
    (state) => state.toggles.showDemographicsTab
  );
  const showChoropleth = useSelector((state) => state.map.showChoropleth);

  const allMetricsMetadata = Object.values(_METRIC_METADATA)
    .filter((metadata) => metadata.metricCategoryId === categoryId)
    // Sort alphabetically
    .sort((a, b) => a.name.localeCompare(b.name));
  const dispatch = useDispatch();

  const handleIssueTransition = (issue) => {
    dispatch(setMetric(null));
    if (selectedCategoryId !== issue) {
      dispatch(setMetricCategory(issue));
    } else {
      dispatch(setMetricCategory(null));
      dispatch(setShowDemographicsTab(false));
    }
  };

  useEffect(() => {
    const element = header.current;
    if (!element) {
      return;
    }
    const height = element.clientHeight;
    dispatch(setHeaderHeight(height));
  }, [header.current?.clientHeight]);

  return (
    <>
      {/* Issue selection COLLAPSED */}
      <div
        className={`${
          selectedCategoryId === categoryId ? 'issues-chapters-active' : ''
        } ${
          selectedCategoryId || showDemographicsTab ? 'collapse-issue' : ''
        } issues-chapters
        ${categoryId !== 3 && 'bottom-border'} 
        ${
          (categoryId === 1 || selectedCategoryId === categoryId - 1) &&
          'top-border'
        }
        `}
        onClick={() => {
          return selectedCategoryId !== categoryId
            ? handleIssueTransition(categoryId)
            : null;
        }}
      >
        <div
          id="issue-category-header"
          className="position-relative d-flex justify-content-between align-items-center"
        >
          <h6
            className={`${selectedCategoryId ? 'mb-0' : ''} `}
            style={{
              fontSize:
                categoryId === selectedCategoryId && selectedChapter === 2
                  ? '2.875rem'
                  : '',
              padding:
                categoryId === selectedCategoryId && selectedChapter === 2
                  ? '2.3rem 1rem 2.3rem 1rem'
                  : '',
              position: 'relative',
            }}
            ref={categoryId === selectedCategoryId ? header : null}
            onClick={() => {
              return selectedCategoryId === categoryId
                ? handleIssueTransition(1)
                : null;
            }}
          >
            {_METRIC_CATEGORY_METADATA[categoryId].name}
          </h6>

          <div
            style={{
              flexDirection: 'column',
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '0.688rem', //'11px',
              // width: '55px'
            }}
          >
            {selectedCategoryId === categoryId &&
              selectedChapter !== chapters.COMMUNITY && <MapToggle />}
          </div>
        </div>
        <p
          className={` ${
            selectedCategoryId ? 'invis' : 'vis'
          } mb-0 small-font-vh`}
          style={{ padding: !selectedCategoryId ? '1rem' : '0rem' }}
        >
          {_METRIC_CATEGORY_METADATA[selectedCategoryId]?.description}
        </p>
      </div>
      {/* Issue Selection EXPANDED */}
      <div
        className={`${
          selectedCategoryId === categoryId ? 'expand-issue' : ''
        } accordion-body`}
      >
        <div className={'position-relative d-flex flex-column row-gap'}>
          {!selectedMetric && (
            <IssuesGrid
              items={allMetricsMetadata}
              currentValue={selectedMetric}
              setValue={(val) => dispatch(setMetric(val))}
            />
          )}
          {selectedMetric && (
            <IssuesDropDown
              items={allMetricsMetadata}
              currentValue={selectedMetric}
              setValue={(val) => dispatch(setMetric(val))}
            />
          )}
          {/* Only show the description/related tags when map AND demographic tags are not open. */}
          {!(view === viewTypes.MAP && showDemographicsTab) && (
            <div>
              {!selectedMetric ? (
                <p className="small-font-vh">
                  {_METRIC_CATEGORY_METADATA[selectedCategoryId]?.description}
                </p>
              ) : (
                <div>
                  <p
                    className={`links-${_METRIC_CATEGORY_METADATA[selectedCategoryId]?.name}`}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(selectedMetricMetadata.description, {
                        ADD_ATTR: ['target'],
                      }),
                    }}
                  ></p>
                  <div className={'link-underline'}>
                    <strong>Related:</strong>
                    {selectedMetricMetadata.related.map((issue, index) => (
                      <span key={index}>
                        {' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          onClick={() => {
                            dispatch(setMetric(issue));
                          }}
                        >
                          {_METRIC_METADATA[issue].name}
                        </a>
                        {index === 2 ? '.' : ','}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* Only show legend on map view and when choropleth is visible. */}
          {view === viewTypes.MAP &&
            selectedMetric &&
            (showChoropleth ? (
              <Legend />
            ) : (
              <MapLegend metricId={selectedMetric} />
            ))}
        </div>
      </div>
    </>
  );
};

export default IssueSelection;
