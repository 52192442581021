import React from 'react';

export default function MetricSelectionGrid({ currentValue, items, setValue }) {
  return (
    <>
      <div>
        <div
          className={`d-flex flex-wrap issues-grid`}
          style={{
            textAlign: 'center',
            gap: '1rem',
            boxSizing: 'border-box',
            alignItems: 'center',
          }}
        >
          {items.map((item) => {
            return (
              <div
                key={item.id}
                className={`p grid-item ${
                  currentValue === item.id ? 'grid-item-active' : ''
                }`}
                style={{
                  border: '2px solid black',
                  flexGrow: '1',
                  minHeight: '6px',
                  justifyContent: 'space-between',
                  // padding: '0.25rem 0.5rem',
                }}
                onMouseDown={() => {
                  if (currentValue === item.id) {
                    setValue(null);
                  } else {
                    setValue(item.id);
                  }
                }}
              >
                <p className={'small-font-vh mb-0'}>{item.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
