import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';
import _METRIC_METADATA from '../../meta/metricMetadata.json';

import { useGetBoundaryName, useResizeObserver } from '../../utils/hooks';

import {
  getFormattedNumber,
  colorInterpolate,
  getMetricData,
  getBoundaryName,
  ordinalSuffixOf,
} from '../../utils/functions';

import { useSelector } from 'react-redux';
import { selectBoundaryData, selectCompareQuery } from '../../store/storeUtils';

const getDataToVis = (metricData, metricMetadata) => {
  let valueArray = [];
  let nameArray = [];
  let ascending;
  let lookupArray = [];

  if (metricMetadata.higherValueIsBad) {
    // Put larger values at the top of the histogram (sort in descending order)
    metricData.sort((a, b) => b.value - a.value);
  } else {
    // Put smaller values at the top of the histogram (sort in ascending order)
    metricData.sort((a, b) => a.value - b.value);
  }

  metricData.forEach((data) => {
    valueArray.push(Number(Number(data.value).toFixed(3)));
    nameArray.push(getBoundaryName({ id: data.boundaryId, length: 'short' }));
    lookupArray.push(data.boundaryId);
  });

  const isTemperature = metricMetadata.jsonId === 'F14_TmpDev' ? true : false;

  // get the corresponding index of average value
  let sum = valueArray.reduce((a, b) => a + b, 0);
  // let avg = Number(sum / valueArray.length);
  let avg = isTemperature ? 0 : Number(sum / valueArray.length);
  let avgIndex;

  for (let i = 0; i < valueArray.length - 1; i++) {
    if (valueArray[i] < avg && valueArray[i + 1] > avg) {
      avgIndex =
        i + (avg - valueArray[i]) / (valueArray[i + 1] - valueArray[i]);
      ascending = true;
      break;
    }

    if (valueArray[i] > avg && valueArray[i + 1] < avg) {
      avgIndex =
        i + 1 - (avg - valueArray[i + 1]) / (valueArray[i] - valueArray[i + 1]);
      ascending = false;
      break;
    }
  }

  return [
    valueArray,
    nameArray,
    avg,
    avgIndex,
    ascending,
    lookupArray,
    isTemperature,
  ];
};

const IssueHistogram = ({ metricId, boundaryMetadata }) => {
  const ref = useRef();
  const containerRef = useRef();

  const boundaryType = useSelector((state) => state.nav.boundaryType);
  const metricMetadata = _METRIC_METADATA[metricId];

  const boundaryId = boundaryMetadata?.id;
  const getBoundaryName = useGetBoundaryName();
  const boundaryData = useSelector(selectBoundaryData);
  const metricData = getMetricData(boundaryData, metricMetadata);

  const selectedBoundaryData = metricData.find(
    (boundary) => boundary.boundaryId === boundaryId
  );

  const isComparison = useSelector(selectCompareQuery);

  const getBoundingStatement = (side) => {
    const boundText = metricMetadata.boundTexts.boundText;
    // Bounds are already updated to match with the `higherValueIsBad` property
    if (side === 'left') {
      return metricMetadata.boundTexts.max + ' ' + boundText;
    }
    return metricMetadata.boundTexts.min + ' ' + boundText;
  };

  const textWidth = 50;
  const margin = {
    top: 45,
    left: 10,
    bottom: 25,
    right: 10,
  };
  const [containerWidth, containerHeight] = useResizeObserver(containerRef);

  let colorRamps =
    _METRIC_CATEGORY_METADATA[metricMetadata.metricCategoryId].colorRamp;
  let [data, nameArray, avg, avgIndex, ascending, lookupArray, isTemperature] =
    getDataToVis([...metricData], metricMetadata);

  let selectedIndex = boundaryId ? lookupArray.indexOf(boundaryId) : 0;

  const metricSymbol = metricMetadata.histogramTooltipSnippet;

  useEffect(() => {
    const height = containerHeight ? containerHeight : 0;
    const width = containerWidth ? containerWidth : 500;

    // histogram bars attr
    let barPadding = 0;
    let barWidth = (width - margin.right - margin.left) / data.length;
    let minValueMargin = 0.05 * (d3.max(data) - d3.min(data));
    let longestBarPadding = 0;

    let xScale = d3
      .scaleLinear()
      .domain([0, data.length])
      .range([margin.left, width - margin.right - margin.left]);

    let yrange =
      height - longestBarPadding - margin.bottom - margin.top > 0
        ? height - longestBarPadding - margin.bottom - margin.top
        : 0;
    let yscale = d3
      .scaleLinear()
      .domain([
        d3.min(data) >= 0 ? d3.min(data) - minValueMargin : d3.min(data),
        d3.max(data),
      ])
      .range([0, yrange]);

    let yUnit = yscale(1) - yscale(0);

    // build SVG
    let svg = d3
      .select(ref.current)
      .attr('height', '100%')
      .attr('width', '100%');

    // create Chart
    svg
      .select('#main-chart')
      .attr('class', 'rect')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .merge(
        svg
          .select('#main-chart')
          .attr('class', 'rect')
          .selectAll('rect')
          .data(data)
      )
      .attr('width', barWidth - barPadding >= 0 ? barWidth - barPadding : 0)
      .attr('height', (d) =>
        d3.min(data) >= 0
          ? yscale(d)
          : d > 0
          ? yscale(d) - yscale(0)
          : yscale(0) - yscale(d)
      )
      .attr('x', (d, i) => xScale(i + 0.5))
      .attr('y', (d) =>
        d3.min(data) >= 0
          ? height - yscale(d) - margin.bottom
          : d > 0
          ? margin.bottom + yscale(0)
          : margin.bottom + yscale(d)
      )
      .attr('fill', (d, i) =>
        metricMetadata.higherValueIsBad
          ? d3.rgb(
              ...colorInterpolate(
                // colorRamps[0],
                // colorRamps[colorRamps.length - 1],
                colorRamps[2],
                colorRamps[2],
                !ascending
                  ? 1 - i / (metricData.length - 1)
                  : i / (metricData.length - 1)
              )
            )
          : d3.rgb(
              ...colorInterpolate(
                // colorRamps[colorRamps.length - 1],
                // colorRamps[0],
                colorRamps[2],
                colorRamps[2],
                !ascending
                  ? 1 - i / (metricData.length - 1)
                  : i / (metricData.length - 1)
              )
            )
      )
      .attr('value', (d) => d);

    svg.selectAll('rect').each(function (d, i) {
      d3.select(this).attr('y', (d) =>
        d3.min(data) >= 0
          ? height - d3.select(this).attr('height') - margin.bottom
          : d > 0
          ? height - d3.select(this).attr('height') - margin.bottom - yscale(0)
          : height - d3.select(this).attr('height') - margin.bottom - yscale(d)
      );
    });

    // clear Chart
    svg
      .select('#main-chart')
      .attr('class', 'rect')
      .selectAll('*')
      .data(data)
      .exit()
      .remove();

    const selectedBoundaryLineX = xScale(selectedIndex + 1);
    const selectedBoundaryLineY = margin.top - 42;

    const averageLineX = xScale(avgIndex + 1);

    const maxX = xScale(data.length + 0.5);

    // Draw the selected boundary tooltip
    svg
      .select('#selectedBoundaryLine')
      .attr('class', 'dataLine')
      .attr('y1', selectedBoundaryLineY)
      .attr('x1', selectedBoundaryLineX)
      .attr('y2', height - margin.bottom)
      .attr('x2', selectedBoundaryLineX)
      .style('stroke', 'black')
      .style('stroke-width', 1)
      .attr('index', selectedIndex);

    const boundaryTooltip = svg.select('#selectedBoundaryTooltip');

    boundaryTooltip
      .selectAll('text')
      .attr('class', 'small-font')
      .attr('style', 'font-family:Inter')
      .attr('font-size', '14')
      .attr('fill', '#000000');

    // Boundary Name
    boundaryTooltip
      .select('#boundaryNameText')
      .attr('y', selectedBoundaryLineY + 10)
      .text(
        boundaryId ? getBoundaryName({ id: boundaryId, length: 'short' }) : ''
      );

    // Rank
    boundaryTooltip
      .select('#boundaryRankingText')
      .attr('y', selectedBoundaryLineY + 24)
      .style('font-weight', 700)
      .text(
        `${ordinalSuffixOf(selectedBoundaryData?.rank)} out of ${
          metricData.length
        }`
      );

    // Value
    boundaryTooltip
      .select('#boundaryValueText')
      .attr('y', selectedBoundaryLineY + 38)
      .text(
        `${getFormattedNumber(
          data[Math.round(svg.select('#selectedBoundaryLine').attr('index'))],
          metricMetadata
        )}${metricSymbol}`
      );

    svg
      .select('#minTextDown')
      .attr('x', xScale(0.5))
      .attr('y', height - margin.bottom + 15)
      .attr('class', 'smaller-text')
      .attr('style', 'font-family:Inter')
      .attr('font-size', '14')
      .attr('font-weight', '500')
      .attr('fill', '#000000')
      .attr('text-anchor', 'start')
      .text(getBoundingStatement('left'));

    svg
      .select('#maxTextDown')
      .attr('x', xScale(data.length + 0.5))
      .attr('y', height - margin.bottom + 15)
      .attr('class', 'small-font')
      .attr('style', 'font-family:Inter')
      .attr('font-size', '14')
      .attr('fill', '#000000')
      .attr('text-anchor', 'end')
      .text(getBoundingStatement('right'));

    // Citwide average line (only show when in the individual view, NOT in the
    // comparison view)
    const averageTooltip = svg.select('#averageTooltip');
    if (!isComparison) {
      // draw avg Lines
      svg
        .select('#avgLine')
        .attr('class', 'dataLine')
        .attr('y1', selectedBoundaryLineY)
        .attr('x1', averageLineX)
        .attr('y2', height - margin.bottom)
        .attr('x2', averageLineX)
        .style('stroke', 'black')
        .style('stroke-dasharray', '3, 3')
        .style('stroke-width', 1)
        .attr('index', avgIndex)
        .attr('visibility', 'visible');

      averageTooltip
        .selectAll('text')
        .attr('class', 'small-font')
        .attr('style', 'font-family:Inter')
        .attr('font-size', '14')
        .attr('fill', '#000000')
        .attr('text-anchor', 'end')
        .attr('visibility', 'visible');

      svg
        .select('#avgTextDown')
        .attr('y', Number(svg.select('#avgLine').attr('y1')) + 10)
        .text('Citywide Average');

      svg
        .select('#avgTextUp')
        .attr('y', Number(svg.select('#avgLine').attr('y1')) + 24)
        .text(
          `${
            isTemperature
              ? '98.6'
              : getFormattedNumber(
                  data[Math.round(svg.select('#avgLine').attr('index'))],
                  metricMetadata
                )
          }${metricSymbol}`
        );
    } else {
      // Hide the average information
      averageTooltip.selectAll('text').attr('visibility', 'hidden');
      svg.select('#avgLine').attr('visibility', 'hidden');
    }

    const selectedBoundaryTooltipWidth = svg
      .select('#selectedBoundaryTooltip')
      .node()
      .getBoundingClientRect().width;

    const averageTooltipWidth = svg
      .select('#averageTooltip')
      .node()
      .getBoundingClientRect().width;

    // Avoid overlapping between the selected boundary tooltip and the average
    // tooltip.
    // Use the selected boundary line and the average line's x position to
    // determine the direction of the tooltips (left or right)

    // Default case, both facing right
    // Covers when the boundary/average tooltip is close to the left of the
    // histogram
    let selectedBoundaryTooltipDirection = 'right';
    let averageTooltipDirection = 'right';
    const overlapPadding = 15;

    // Boundary tooltip is close to the right of the histogram
    // We have to flip the tooltip so it faces left
    if (
      selectedBoundaryLineX + selectedBoundaryTooltipWidth + overlapPadding >=
      maxX
    ) {
      selectedBoundaryTooltipDirection = 'left';
    }

    // Boundary tooltip is close to the average tooltip Here we must flip the
    // tooltips so that they face away from eachother to avoid overlap. Since
    // the average tooltip will often be in the center of the histogram,
    // prioritize flipping that one instead.
    // Note: Only do this if the average tooltip is on the histogram.
    if (
      !isComparison &&
      Math.abs(selectedBoundaryLineX - averageLineX) <
        selectedBoundaryTooltipWidth + averageTooltipWidth + overlapPadding
    ) {
      // Boundary tooltip is to the right of the average, flip average tooltip
      // left
      if (selectedBoundaryLineX > averageLineX) {
        averageTooltipDirection = 'left';
      } else if (selectedBoundaryLineX >= selectedBoundaryTooltipWidth) {
        selectedBoundaryTooltipDirection = 'left';
      }
    }

    const tooltipPadding = 4;

    boundaryTooltip
      .selectAll('text')
      .attr(
        'text-anchor',
        selectedBoundaryTooltipDirection === 'left' ? 'end' : 'start'
      )
      .attr(
        'x',
        selectedBoundaryLineX +
          (selectedBoundaryTooltipDirection === 'left'
            ? -tooltipPadding
            : tooltipPadding)
      );

    averageTooltip
      ?.selectAll('text')
      .attr('text-anchor', averageTooltipDirection === 'left' ? 'end' : 'start')
      .attr(
        'x',
        averageLineX +
          (averageTooltipDirection === 'left'
            ? -tooltipPadding
            : tooltipPadding)
      );
  }, [
    colorRamps,
    boundaryType,
    metricId,
    boundaryMetadata,
    isComparison,
    containerWidth,
    containerHeight,
  ]);

  return (
    <div>
      <div
        ref={containerRef}
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <svg ref={ref}>
          {/* Main Chart */}
          <g id="main-chart" />

          {/* Avg Line */}
          <line id="avgLine" />
          <g id="averageTooltip">
            <text id="avgTextUp" />
            <text id="avgTextDown" />
          </g>

          {/* Selected Line */}
          <line id="selectedBoundaryLine" />
          <g id="selectedBoundaryTooltip">
            <text id="boundaryNameText" />
            <text id="boundaryRankingText" />
            <text id="boundaryValueText" />
          </g>

          {/* Min/Max Line */}
          <text id="maxTextDown" />
          <text id="minTextDown" />
        </svg>
      </div>
    </div>
  );
};

export default IssueHistogram;
