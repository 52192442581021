// import React and React hooks
import React, { useEffect } from 'react';

// import components
import Demographics from '../Demographics';

// import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

// import data and text
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';

import { useSelector, useDispatch } from 'react-redux';
import { setDemographicsVisible as setMapDemographicsVisible } from '../../store/slices/mapSlice';
import {
  setShowDemographicsTab,
  toggleDemographicsTab,
} from '../../store/slices/togglesSlice';
import IssueSelection from './IssueSelection';

/**
 * IssueSelectionColumn.js renders the middle column in the Citywide data section
 * and the middle column in the Community Profiles section when the map view is toggled.
 * Refer to App.js for info on props
 */

export default function IssueSelectionColumn() {
  const metricCategory = useSelector((state) => state.nav.metricCategory);
  const showDemographicsTab = useSelector(
    (state) => state.toggles.showDemographicsTab
  );

  const dispatch = useDispatch();

  // if none of the categories are opened, close demographics tab
  useEffect(() => {
    if (!metricCategory) {
      dispatch(setShowDemographicsTab(false));
    }
  });

  return (
    <div className={'d-flex flex-column position-relative h-100'}>
      {/* Issue selection boxes */}
      {[...Object.keys(_METRIC_CATEGORY_METADATA)].map((categoryId) => (
        <IssueSelection key={categoryId} categoryId={Number(categoryId)} />
      ))}

      {/* DEMOGRAPHICS TAB COLLAPSED */}
      <div
        className={`collapse-issue transition-height
                ${metricCategory ? '' : 'no-height'}
                ${
                  showDemographicsTab
                    ? 'bottom-border issues-chapters-active'
                    : ''
                } ${
          metricCategory === 3 ? 'top-border' : ''
        } issues-chapters no-bottom-border`}
        onClick={() => {
          if (showDemographicsTab) {
            dispatch(setMapDemographicsVisible(false));
          }
          if (metricCategory) {
            dispatch(toggleDemographicsTab());
          }
        }}
        id="bottom-chapter"
      >
        <div
          className={`d-flex flex-row justify-content-between align-items-center
                transition-height ${metricCategory ? '' : 'no-height'}
                `}
        >
          <h6 className="mb-0">
            {showDemographicsTab
              ? 'Hide U.S. Census Data'
              : 'Show U.S. Census Data'}
          </h6>
          {showDemographicsTab ? (
            <FontAwesomeIcon icon={faMinus} style={{ marginRight: '1.5rem' }} />
          ) : (
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '1.5rem' }} />
          )}
        </div>
      </div>

      {/* DEMOGRAPHICS TAB EXPANDED */}
      <div
        className={`${
          showDemographicsTab ? 'expand-issue' : ''
        } accordion-body`}
      >
        <div className={'d-flex position-relative'}>
          <Demographics />
        </div>
      </div>
    </div>
  );
}
