import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import MapTooltip from './MapTooltip';
import {
  selectCommuteModesString,
  selectDemographicMetadata,
  selectMetricMetadata,
  selectMetricVizData,
} from '../../store/storeUtils';

const StaticTooltip = ({ tooltipData, isCompareTooltip, onClose }) => {
  const navState = useSelector((state) => state.nav);
  const selectedMetricMetadata = useSelector(selectMetricMetadata);
  const selectedDemographicMetadata = useSelector(selectDemographicMetadata);
  const { metricData: selectedMetricData } = useSelector(selectMetricVizData);
  const commuteToggles = useSelector((state) => state.toggles.commuteToggles);
  const demographicsTabOpen = useSelector(
    (state) => state.toggles.showDemographicsTab
  );
  const commuteModesString = useSelector(selectCommuteModesString);

  if (!tooltipData?.coords) {
    return <></>;
  }

  return (
    <>
      <div
        id={`map-tooltip-${isCompareTooltip ? 'compare' : 'primary'}`}
        className="map-tooltip map-pinned noselect"
      >
        <MapTooltip
          boundaryType={navState.boundaryType}
          selectedMetricMetadata={selectedMetricMetadata}
          selectedDemographicMetadata={selectedDemographicMetadata}
          selectedMetricData={selectedMetricData}
          demographicId={demographicsTabOpen ? navState.demographic : null}
          commuteToggles={commuteToggles}
          commuteModesString={commuteModesString}
          pickingInfoObject={tooltipData}
          isStatic
        />
      </div>
      <button
        className="map-pinned-close-button"
        style={{
          left: tooltipData ? `${tooltipData.width - 30}px` : 0,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          console.log(
            `Close ${isCompareTooltip ? 'compare' : 'primary'} tooltip!`
          );
          onClose();
        }}
      >
        <FontAwesomeIcon
          icon={faXmark}
          className={'close-icon'}
          style={{
            display: 'inline-block',
            padding: '0.25rem 0.5rem 0 0.5rem',
            fontSize: '1.5rem',
            color: 'white',
          }}
        />
      </button>
    </>
  );
};

export default StaticTooltip;
