// import React and React hooks
import React, { useEffect, useState } from 'react';

// import fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// import components
import Slider from './Carousel';
import MetricSelectionGrid from './issue-selection/MetricSelectionGrid';

import { useSelector, useDispatch } from 'react-redux';
import {
  setDemographicsVisible as setMapDemographicsVisible,
  toggleDemographicsVisible as toggleMapDemographicsVisible,
} from '../store/slices/mapSlice';
import { setDemographic } from '../store/slices/navigationSlice';
import {
  selectCompareQuery,
  selectCurrentView,
  selectDemographicMetadata,
  selectPrimaryQuery,
} from '../store/storeUtils';
import DemographicLegend from './visualizations/DemographicLegend';

import _DEMOGRAPHICS_METADATA from '../meta/demographics.json';
import { chapters, viewTypes } from '../utils/constants';

/**
 * Demographics.js
 *
 * @constructor
 * @param {Object} info - contains information calculated in App.js
 * @param {Object} issue_categories - categories of demographics to display for IssueGrid.js
 */

export default function Demographics() {
  const mapState = useSelector((state) => state.map);
  const view = useSelector(selectCurrentView);
  const mobileState = useSelector((state) => state.mobile);
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const compareSearchQuery = useSelector(selectCompareQuery);

  const chapter = useSelector((state) => state.nav.chapter);
  const selectedDemographicMetadata = useSelector(selectDemographicMetadata);
  const showDemographicsTab = useSelector(
    (state) => state.toggles.showDemographicsTab
  );
  const dispatch = useDispatch();

  // state for keeping track of whether to display dropdown items or not
  const [showDropdownItems, setShowDropdownItems] = useState(false);

  return (
    <>
      {/* if nothing is chosen from the demographics dropdown, display the issues grid which lets users choose */}
      {!selectedDemographicMetadata && (
        <MetricSelectionGrid
          items={[...Object.entries(_DEMOGRAPHICS_METADATA)].map(
            ([id, meta]) => ({ id, name: meta.name })
          )}
          currentValue={selectedDemographicMetadata?.id}
          setValue={(val) => {
            dispatch(setMapDemographicsVisible(true));
            dispatch(setDemographic(val));
          }}
        />
      )}

      {/* if a demographics is chosedn from the dropdown */}
      {selectedDemographicMetadata && (
        <div
          className={`demographics-container row-gap ${
            showDemographicsTab ? 'expand-demographic' : 'collapse-demographic'
          }`}
          style={{ paddingBottom: mobileState.isMobile ? '0' : '' }}
        >
          {/* show dropdown */}
          {(!mobileState.isMobile ||
            (mobileState.isMobile && mobileState.legendVisible)) && (
            <div className={'dropdown-container'}>
              <div
                className={
                  'dropdown-bar dropdown-bar-black d-flex flex-row justify-content-between align-items-center'
                }
                onMouseDown={() => {
                  setShowDropdownItems(!showDropdownItems);
                }}
              >
                <p className={'mb-0 small-font'}>
                  {selectedDemographicMetadata?.name ||
                    'Select a demographic to explore'}
                </p>

                {!showDropdownItems && <FontAwesomeIcon icon={faCaretDown} />}
                {showDropdownItems && <FontAwesomeIcon icon={faCaretUp} />}
              </div>

              <div
                className={`${
                  showDropdownItems ? 'd-block' : 'd-none'
                } dropdown-body position-absolute w-100`}
              >
                {Object.values(_DEMOGRAPHICS_METADATA).map((metadata) => {
                  return (
                    <div
                      key={metadata.id}
                      className={`dropdown-item ${
                        selectedDemographicMetadata?.id === metadata.id
                          ? 'dropdown-item-active'
                          : ''
                      }`}
                      onMouseDown={() => {
                        setShowDropdownItems(false);
                        if (selectedDemographicMetadata?.id !== metadata.id) {
                          dispatch(setDemographic(metadata.id));
                        } else {
                          dispatch(setDemographic(null));
                          dispatch(setMapDemographicsVisible(false));
                        }
                      }}
                    >
                      <p className={'small-font m-0'}>{metadata.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {selectedDemographicMetadata && (
            <>
              {chapter === chapters.CITYWIDE ? (
                <DemographicLegend />
              ) : primarySearchQuery && compareSearchQuery ? (
                <div id="demographic-slider">
                  <Slider>
                    <div>
                      <DemographicLegend boundaryId={primarySearchQuery} />
                    </div>
                    <div>
                      <div
                        className={'d-flex flex-row justify-content-between'}
                      ></div>

                      <DemographicLegend boundaryId={compareSearchQuery} />
                    </div>
                  </Slider>
                </div>
              ) : (
                <DemographicLegend boundaryId={primarySearchQuery} />
              )}

              {/* Show on map button  */}
              {view === viewTypes.MAP &&
                (!mobileState.isMobile ||
                  (mobileState.isMobile && mobileState.legendVisible)) && (
                  <div
                    className={`big-button ${
                      mapState.demographicsVisible
                        ? 'big-button-active'
                        : 'big-button-inactive'
                    }`}
                    onClick={() => {
                      dispatch(toggleMapDemographicsVisible());
                    }}
                  >
                    <div>
                      <p className={'mb-0 small-font'}>
                        {mapState.demographicsVisible
                          ? 'Remove from map'
                          : 'Show on map'}
                      </p>
                    </div>
                    <div>
                      {mapState.demographicsVisible ? (
                        <FontAwesomeIcon icon={faMinus} />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} />
                      )}
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
}
