// import style sheets
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// import React and React Hooks
import React, { useEffect, useRef, useState } from 'react';

// import components
import Container from 'react-bootstrap/Container';
import Nav from './components/Nav';
import Content from './components/Content';
import Map from './components/map/Map';
import MobileNav from './components/mobile/MobileNav';
import MobileCitywideData from './components/mobile/MobileCitywideData';
import MobileCommunityProfile from './components/mobile/MobileCommunityProfile';
import MobileWhatIsSE from './components/mobile/MobileWhatIsSE';
import About from './components/About';
import MobileFixedHeader from './components/mobile/MobileFixedHeader';

// import fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretLeft,
  faCaretRight,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Protect from './utils/react-app-protect';

// import react player
import ReactPlayer from 'react-player/youtube';

// Map
import { useSelector, useDispatch } from 'react-redux';
import * as navReducers from './store/slices/navigationSlice';
import * as toggleReducers from './store/slices/togglesSlice';
import {
  selectMapIsFullScreen,
  selectMetricMetadata,
  selectMiddleColumnVisible,
} from './store/storeUtils';
import {
  useGA4,
  useURLLoader,
  useURLUpdater,
  useWindowSize,
} from './utils/hooks';
import { chapters, viewTypes } from './utils/constants';
import Loader from './components/Loader';
import SolutionsCard from './components/SolutionsCard';
import MobileLanding from './components/mobile/MobileLanding';

// site protection
let siteProtection = process.env.REACT_APP_SITE_PROTECTION === 'true';
let sha512 = process.env.REACT_APP_SITE_PWD;

function App() {
  // Redux
  const mapState = useSelector((state) => state.map);
  const searchState = useSelector((state) => state.search);
  const navState = useSelector((state) => state.nav);
  const toggles = useSelector((state) => state.toggles);
  const vizState = useSelector((state) => state.viz);
  const metricMetadata = useSelector(selectMetricMetadata);
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const dispatch = useDispatch();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [collapseMapToggle, setCollapseMapToggle] = useState(false);

  // mobile hooks
  const isTouchingMapMobile = useRef(false);

  // Update the body style
  useEffect(() => {
    let body = document.getElementsByTagName('body')[0];
    let html = document.documentElement;
    body.style.height = window.innerHeight + 'px';
    html.style.height = window.innerHeight + 'px';
  });

  // Add document height event listeners
  useEffect(() => {
    const documentHeight = () => {
      let body = document.getElementsByTagName('body')[0];
      let html = document.documentElement;
      body.style.height = window.innerHeight + 'px';
      html.style.height = window.innerHeight + 'px';
    };
    window.addEventListener('resize', documentHeight);
    return () => {
      window.removeEventListener('resize', documentHeight);
    };
  }, []);

  // GA4 hooks
  useGA4({
    searchState,
    navState,
    mapState,
    toggles,
    vizState,
  });

  // Update the appropriate state based on the URL query params
  useURLLoader();

  // Update the URL based on the changes in state
  useURLUpdater(
    mapState,
    navState,
    searchState,
    vizState,
    toggles,
    isFirstLoad,
    setIsFirstLoad
  );

  useEffect(() => {
    if (metricMetadata) {
      dispatch(navReducers.setMetricCategory(metricMetadata.metricCategoryId));
    }
  }, [dispatch, metricMetadata]);

  if (typeof siteProtection === 'undefined') {
    console.log('Turning off site protection');
    siteProtection = false;
  }
  if (typeof sha512 === 'undefined') {
    // Encryption for password "test"
    sha512 =
      'EE26B0DD4AF7E749AA1A8EE3C10AE9923F618980772E473F8819A5D4940E0DB27AC185F8A0E1D5F84F88BC887FD67B143732C304CC5FA9AD8E6F57F50028A8FF';
  }

  // Updates whenever the window size changes
  useWindowSize();

  const showMap = navState.views[navState.chapter] === viewTypes.MAP;
  const [showMapSolutions, setShowMapSolutions] = useState(false);
  const middleColumnVisible = useSelector(selectMiddleColumnVisible);
  const showCollapsePanelButton =
    showMap &&
    !(navState.chapter === chapters.COMMUNITY && !middleColumnVisible);
  const mapIsFullScreen = useSelector(selectMapIsFullScreen);

  return (
    <Protect isEnabled={siteProtection} sha512={sha512}>
      {!isMobile ? (
        /**
         * ========= DESKTOP =========
         */
        <Container fluid className={'h-100 p-0 m-0 d-flex flex-row'}>
          <Nav />
          <Content />
          <div className={`${showMap ? 'show-map' : 'hide-map'} map-container`}>
            <div className={'map-subcontainer'}>
              <div
                className={`individual-maps`}
                style={{
                  width: mapIsFullScreen ? '75vw' : showMap ? '50vw' : 0,
                  transition: 'width 0.5s',
                }}
                id={
                  mapState.demographicsVisible ? 'left-map' : 'left-map-alone'
                }
              >
                {mapState.loading && (
                  <div className="map-loader-container">
                    <Loader />
                    Loading map...
                  </div>
                )}
                {showCollapsePanelButton && (
                  <div
                    className={`collapse-map-button`}
                    style={{
                      width: '1.5vw',
                      borderLeft: 'none',
                      outline: '1px solid black',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(toggleReducers.togglePanelIsCollapsed());
                    }}
                    onMouseEnter={() => {
                      setCollapseMapToggle(true);
                    }}
                    onMouseLeave={() => {
                      setCollapseMapToggle(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        toggles.panelIsCollapsed ? faCaretRight : faCaretLeft
                      }
                    />
                    <div
                      className={`collapse-map-tooltip ${
                        collapseMapToggle ? '' : 'd-none'
                      }`}
                    >
                      {toggles.panelIsCollapsed
                        ? 'Show Panel'
                        : 'Collapse Panel'}
                    </div>
                  </div>
                )}
                {metricMetadata && showCollapsePanelButton && (
                  <button
                    className={`map-solutions-button`}
                    style={{
                      width: '50px',
                      borderRight: 'none',
                      padding: '1rem',
                      outline: '1px solid black',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMapSolutions(true);
                    }}
                  >
                    <h4 style={{ fontSize: 'inherit' }}>Solutions</h4>
                    <FontAwesomeIcon
                      icon={showMapSolutions ? faMinus : faPlus}
                      color="inherit"
                      fontSize={'x-large'}
                    />
                  </button>
                )}
                {metricMetadata && (
                  <div
                    className="map-solutions"
                    style={{ left: showMapSolutions ? 0 : '101%' }}
                  >
                    <SolutionsCard
                      issue={metricMetadata}
                      onClose={() => setShowMapSolutions(false)}
                    ></SolutionsCard>
                  </div>
                )}
                <Map />
              </div>
            </div>
          </div>
        </Container>
      ) : (
        /**
         * ========= MOBILE =========
         */
        <Container
          className={
            'p-0 d-flex flex-column overflow-hidden position-relative container flex-grow-0'
          }
        >
          <div className={`d-flex flex-column`} style={{ zIndex: '10' }}>
            {navState.chapter && <MobileFixedHeader />}
            <MobileNav />
          </div>

          {!navState.chapter && (
            <MobileLanding currentChapter={navState.chapter} />
          )}
          {navState.chapter === 1 ? (
            <MobileWhatIsSE />
          ) : navState.chapter === 2 ? (
            <MobileCitywideData
              mainMap={true}
              isTouchingMapMobile={isTouchingMapMobile}
            />
          ) : navState.chapter === 3 ? (
            <MobileCommunityProfile isTouchingMapMobile={isTouchingMapMobile} />
          ) : navState.chapter === 4 ? (
            <About />
          ) : null}

          <div
            className={'mobile-map'}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: 0,
              zIndex: showMap ? '0' : '-1',
            }}
          >
            <Map
              // mobile only
              isTouchingMapMobile={isTouchingMapMobile}
            />
          </div>
        </Container>
      )}
    </Protect>
  );
}

export default App;
