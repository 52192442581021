import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../store/slices/mobileSlice';
import MapToggle from '../map/MapToggle';

export default function MobileFixedHeader() {
  const menuVisible = useSelector((state) => state.mobile.menuVisible);
  const chapter = useSelector((state) => state.nav.chapter);
  const showViewToggle = useSelector((state) => state.toggles.showViewToggle);
  const dispatch = useDispatch();

  return (
    <div
      className={'mobile-nav-header'}
      style={{ height: [4, 1].includes(chapter) ? '4rem' : '' }}
    >
      <div>
        <h4 className={'m-0'}>
          {chapter === 1
            ? 'Spatial Equity NYC'
            : chapter === 2
            ? 'Citywide Data'
            : chapter === 3
            ? 'Community Profiles'
            : 'Take Action'}
        </h4>
      </div>

      <MapToggle showToggle={showViewToggle} />

      <div
        className={`${menuVisible ? 'toggle-menu-active' : ''} toggle-menu`}
        onClick={() => dispatch(toggleMenu())}
      >
        <span
          className={`${
            menuVisible ? 'toggle-menu-span-active' : ''
          } toggle-menu-span`}
        ></span>
      </div>
    </div>
  );
}
