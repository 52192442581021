import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getMetricData, objectArrayToCSVString } from '../utils/functions';
import _METRIC_METADATA from '../meta/metricMetadata.json';
import { useSelector } from 'react-redux';
import { selectBoundaryData } from '../store/storeUtils';
import { useGetBoundaryName } from '../utils/hooks';

/**
 *
 * @param {object} param0
 * @param {object[]} param0.metricId - The ID of the metric this download button
 *    is for.
 * @param {string} [param0.color] - The color of the button. Default is 'black'
 * @returns
 */
const DownloadButton = ({ metricId, color }) => {
  const metricMetadata = _METRIC_METADATA[metricId];
  const boundaryData = useSelector(selectBoundaryData);
  const getBoundaryName = useGetBoundaryName();

  /** The data to download. */
  const metricData = getMetricData(boundaryData, metricMetadata);

  function downloadCSV() {
    console.log('Downloading data', metricMetadata.name);

    // Ensure the data is sorted and has clearer names
    const formattedMetricData = [...metricData]
      .sort((a, b) => a.rank - b.rank)
      .map((data) => ({
        // Keys are the header values
        Rank: data.rank,
        [getBoundaryName({ length: 'long' })]: getBoundaryName({
          id: data.boundaryId,
          length: 'short',
        }),
        [metricMetadata.units]: data.value, // Value's header is the metric units
      }));

    const csvContent = objectArrayToCSVString(formattedMetricData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Citywide ${metricMetadata.name} by ${getBoundaryName()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <button
      className="download-ranks"
      onClick={downloadCSV}
      title={`Download ${metricMetadata.name} data as CSV`}
      style={{ color: color || 'black', paddingLeft: '1.5rem' }}
    >
      <FontAwesomeIcon icon={faDownload} />
    </button>
  );
};

export default DownloadButton;
