import React, { Fragment, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setView } from '../../store/slices/navigationSlice';
import {
  selectCompareQuery,
  selectMetricMetadata,
  selectMiddleColumnVisible,
  selectPrimaryQuery,
} from '../../store/storeUtils';
import { chapters, viewTypes } from '../../utils/constants';
import IssueSelectionColumn from '../issue-selection/IssueSelectionColumn';
import SolutionsCard from '../SolutionsCard';
import CommunityHeader from './CommunityHeader';
import CommunityProfile from './CommunityProfile';

const CommunityContent = () => {
  const view = useSelector((state) => state.nav.views[chapters.COMMUNITY]);
  const panelIsCollapsed = useSelector(
    (state) => state.toggles.panelIsCollapsed
  );
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const compareSearchQuery = useSelector(selectCompareQuery);
  const showSolutions = useSelector((state) => state.toggles.showSolutions);
  const metricMetadata = useSelector(selectMetricMetadata);

  const dispatch = useDispatch();

  const middleColumnVisible = useSelector(
    (state) =>
      selectMiddleColumnVisible(state) && !state.toggles.panelIsCollapsed
  );

  const showSolutionsPane = showSolutions && !compareSearchQuery;

  useEffect(() => {
    if (view === viewTypes.HISTOGRAM && !primarySearchQuery) {
      console.log("Forcing switch from histogram view to map view in community profile!")
      dispatch(
        setView({ chapter: chapters.COMMUNITY, newView: viewTypes.MAP })
      );
    }
  }, [view, primarySearchQuery]);

  const className = useMemo(() => {
    const classesArray = ['d-flex', 'flex-column', 'middle-transition'];
    if (!middleColumnVisible) {
      classesArray.push('collapsed-middle-column');
    } else if (view === viewTypes.MAP || showSolutionsPane) {
      classesArray.push('col-4');
    } else {
      classesArray.push('col-12');
    }
    return classesArray.join(' ');
  }, [panelIsCollapsed, middleColumnVisible, view, showSolutionsPane]);

  return (
    <>
      <div id="community-content" className={className}>
        {middleColumnVisible && (
          <>
            <CommunityHeader />
            {view === viewTypes.MAP ? (
              <IssueSelectionColumn />
            ) : (
              <div className="d-flex">
                <CommunityProfile boundaryId={primarySearchQuery} />
                {compareSearchQuery && (
                  <div style={{ borderLeft: '2px solid black' }}>
                    <CommunityProfile boundaryId={compareSearchQuery} />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className={showSolutionsPane ? 'col-8' : 'col-0'}>
        {metricMetadata && showSolutionsPane && (
          <SolutionsCard issue={metricMetadata} />
        )}
      </div>
    </>
  );
};

export default CommunityContent;
