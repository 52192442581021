import React from 'react';
import { setChapter } from '../../store/slices/navigationSlice';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';

const MobileLanding = ({ currentChapter }) => {
  const dispatch = useDispatch();
  return (
    <div
      id={'mobile-landing'}
      style={{
        zIndex: 3,
        flex: 1,
        minHeight: 0,
        //flexGrow: currentChapter ? '0' : '2',
        //height: currentChapter ? 0 : '100%',
      }}
    >
      <div
        className={'mobile-landing-video'}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setChapter(1));
        }}
      >
        <div className={'mobile-landing-overlay'}>
          <div className={'d-flex flex-column justify-content-between'}>
            <div className={'d-flex flex-row justify-content-center'}>
              <h6
                style={{
                  fontSize: currentChapter ? '0' : '1.25rem',
                  opacity: currentChapter ? '0' : '1',
                  transition: 'font-size 0.5s, opacity 0.5s',
                }}
              >
                NYC SPATIAL EQUITY TOOL
              </h6>
            </div>
            <p
              style={{
                fontSize: currentChapter ? '0' : '0.8em',
                opacity: currentChapter ? '0' : '1',
                transition: 'font-size 0.5s, opacity 0.5s',
              }}
            >
              Spatial Equity NYC documents inequities in the ways that public
              space — including streets, sidewalks, and greenspaces — is
              designed, distributed, and accessed. Browse citywide data or
              search community profiles to learn how decisions about the use of
              public space lead to unequal outcomes and what you can do about
              it.
            </p>
          </div>
        </div>
        <ReactPlayer
          style={{
            border: '2px solid black',
            borderLeft: 'none',
          }}
          width={'100%'}
          height={currentChapter ? '0' : '100%'}
          url="https://www.youtube.com/embed/tSGOYpNTc8k"
          config={{
            youtube: {
              playerVars: { showinfo: 0, frameborder: 0 },
            },
          }}
        />
      </div>
      <div
        className={'mobile-landing-menu'}
        style={{ height: currentChapter ? '0' : '40%' }}
      >
        <div
          className={'mobile-landing-menu-item'}
          style={{
            height: currentChapter ? '0' : '25%',
            padding: currentChapter ? 0 : '0.5rem 1rem',
            borderBottom: currentChapter ? 'none' : '1px solid black',
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setChapter(1));
          }}
        >
          <p
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '0.8em',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            What is
          </p>
          <h6
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '1.25rem',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Spatial Equity
          </h6>
        </div>
        <div
          className={'mobile-landing-menu-item'}
          style={{
            height: currentChapter ? '0' : '25%',
            padding: currentChapter ? 0 : '0.5rem 1rem',
            borderBottom: currentChapter ? 0 : '1px solid black',
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setChapter(2));
          }}
        >
          <p
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '0.8em',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Explore Spatial Equity by
          </p>
          <h6
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '1.25rem',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Citywide Data
          </h6>
        </div>
        <div
          className={'mobile-landing-menu-item'}
          style={{
            height: currentChapter ? '0' : '25%',
            padding: currentChapter ? 0 : '0.5rem 1rem',
            borderBottom: currentChapter ? 0 : '1px solid black',
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setChapter(3));
          }}
        >
          <p
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '0.8em',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Explore Spatial Equity by
          </p>
          <h6
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '1.25rem',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Community Profiles
          </h6>
        </div>
        <div
          className={'mobile-landing-menu-item'}
          style={{
            height: currentChapter ? '0' : '25%',
            padding: currentChapter ? 0 : '0.5rem 1rem',
            borderBottom: currentChapter ? 0 : '1px solid black',
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setChapter(4));
          }}
        >
          <p
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '0.8em',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Learn More &
          </p>
          <h6
            className={'mb-0'}
            style={{
              fontSize: currentChapter ? '0' : '1.25rem',
              opacity: currentChapter ? '0' : '1',
              transition: 'font-size 0.5s, opacity 0.5s',
            }}
          >
            Take Action
          </h6>
        </div>
      </div>
    </div>
  );
};

export default MobileLanding;
