/** The bin size for metric legends. */
export const BIN_SIZE = 5;

export const chapters = {
  LANDING: 1,
  CITYWIDE: 2,
  COMMUNITY: 3,
  ABOUT: 4,
};

export const viewTypes = {
  MAP: 'map',
  HISTOGRAM: 'histogram',
  TABLE: 'table',
};

export const boundaryTypes = {
  CITY_COUNCIL: 'council',
  COMMUNITY_BOARD: 'community',
  STATE_SENATE: 'senate',
  STATE_ASSEMBLY: 'assembly',
  NEIGHBORHOOD: 'neighborhood',
};

/** The different names for each boundary type. */
export const boundaryTypeNames = {
  selection: {
    council: 'City Council',
    community: 'Community Board',
    senate: 'State Senate',
    assembly: 'State Assembly',
  },
  long: {
    council: 'City Council District',
    community: 'Community Board',
    senate: 'State Senate District',
    assembly: 'State Assembly District',
  },
  medium: {
    council: 'Council District',
    community: 'Community Board',
    senate: 'Senate District',
    assembly: 'Assembly District',
  },
  short: {
    council: 'District',
    community: 'Board',
    senate: 'District',
    assembly: 'District',
  },
};

export const geojsonTypes = {
  FEATURE_COLLECTION: 'FeatureCollection',
  FEATURE: 'Feature',
};

/** Enum for demographic IDs */
export const demographicIds = {
  RACE_AND_ETHNICITY: 1,
  POVERTY_LEVEL: 2,
  VEHICLE_OWNERSHIP: 3,
  DRIVE_ALONE: 4,
  COMMUTE_MODE: 5,
  COMMUTE_TIME: 6,
};

/**
 * Lookups for the commute modes for different demographics
 */
export const commuteTogglesMeta = {
  walk: {
    action: 'Walk',
    lookups: {
      [demographicIds.COMMUTE_MODE]: 'PctWalk',
      [demographicIds.COMMUTE_TIME]: 'AvgWalkTime',
    },
  },
  bike: {
    action: 'Bike',
    lookups: {
      [demographicIds.COMMUTE_MODE]: 'PctBike',
    },
  },
  subway: {
    action: 'Take the Subway',
    lookups: {
      [demographicIds.COMMUTE_MODE]: 'PctSubway',
      [demographicIds.COMMUTE_TIME]: 'AvgSubwayTime',
    },
  },
  bus: {
    action: 'Take the Bus',
    lookups: {
      [demographicIds.COMMUTE_MODE]: 'PctBus',
      [demographicIds.COMMUTE_TIME]: 'AvgBusTime',
    },
  },
  drive: {
    action: 'Drive Alone',
    lookups: {
      [demographicIds.COMMUTE_TIME]: 'AvgDriveTime',
    },
  },
  otherTransit: {
    action: 'Take other transit',
    lookups: {
      [demographicIds.COMMUTE_MODE]: 'PctOtherTransit',
      [demographicIds.COMMUTE_TIME]: 'AvgOtherTransitTime',
    },
  },
};

export const raceAndEthnicityMeta = {
  latino: {
    lookup: 'P_Hispanic',
    colors: {
      deckFormat: [244, 133, 0, 255],
      htmlFormat: 'rgb(244, 133, 0)',
    },
  },
  white: {
    lookup: 'P_White',
    colors: {
      deckFormat: [29, 168, 39, 255],
      htmlFormat: 'rgb(29, 168, 39)',
    },
  },
  black: {
    lookup: 'P_Black',
    colors: {
      deckFormat: [80, 128, 234, 255],
      htmlFormat: 'rgb(80, 128, 234)',
    },
  },
  asian: {
    lookup: 'P_Asian',
    colors: {
      deckFormat: [252, 75, 56, 255],
      htmlFormat: 'rgb(252, 75, 56)',
    },
  },
  other: {
    colors: {
      deckFormat: [128, 63, 138, 255],
      htmlFormat: 'rgb(128, 63, 138)',
    },
  },
};
