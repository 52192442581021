import { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import CommunityProfile from '../community-profiles/CommunityProfile';
import CommunitySearchBar from '../community-profiles/CommunitySearchBar';
import MobileLegendTray from './MobileLegendTray';
import MobileDropdown from './MobileDropdown';

import { useSelector, useDispatch } from 'react-redux';
import { collapseDropdowns } from '../../store/slices/mobileSlice';
import {
  selectBoundaryData,
  selectBoundaryMetadata,
  selectCompareQuery,
  selectCurrentView,
  selectPrimaryQuery,
  selectPrimarySearchMetadata,
} from '../../store/storeUtils';
import { getBoundaryId, getBoundaryName } from '../../utils/functions';
import { useCommunitySearchUpdater } from '../../utils/hooks';
import { chapters, viewTypes } from '../../utils/constants';
import { setView } from '../../store/slices/navigationSlice';

export default function MobileCommunityProfile({ isTouchingMapMobile }) {
  const view = useSelector(selectCurrentView);
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const compareSearchQuery = useSelector(selectCompareQuery);
  const primarySearchMetadata = useSelector(selectPrimarySearchMetadata);
  const boundaryData = useSelector(selectBoundaryData);
  const allBoundaryMetadata = useSelector(selectBoundaryMetadata);
  const dispatch = useDispatch();
  const boundaryType = useSelector((state) => state.nav.boundaryType);

  const [resize, setResize] = useState(true);

  const updateCommunitySearch = useCommunitySearchUpdater();

  useEffect(() => {
    if (view === viewTypes.HISTOGRAM && !primarySearchQuery) {
      console.log(
        '[MOBILE] Forcing switch from histogram view to map view in community profile!'
      );
      dispatch(
        setView({ chapter: chapters.COMMUNITY, newView: viewTypes.MAP })
      );
    }
  }, [view, primarySearchQuery]);

  useEffect(() => {
    if (!primarySearchQuery) {
      setResize(true);
    }
  }, [primarySearchQuery]);

  const getSearchItems = (isPrimarySearch) => {
    const searchItems = [];
    const targetQuery = isPrimarySearch
      ? primarySearchMetadata?.id
      : compareSearchQuery;
    const oppositeQuery = isPrimarySearch
      ? compareSearchQuery
      : primarySearchMetadata?.id;

    allBoundaryMetadata.forEach((boundaryMeta) => {
      if (boundaryMeta.id === oppositeQuery) {
        return;
      }

      const isTargetQuery = targetQuery?.toString().startsWith(boundaryMeta.id);
      const boundaryName = getBoundaryName(boundaryType, {
        id: boundaryMeta.id,
        length: 'short',
      });
      searchItems.push(
        <div
          key={boundaryMeta.id}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${
            isTargetQuery ? 'search-item-active' : 'search-item-inactive'
          } col search-item p-2`}
          onMouseDown={(e) => {
            console.debug(
              `Selected ${
                isPrimarySearch ? 'primary' : 'compare'
              } search from the search bar`,
              boundaryMeta.id
            );

            e.stopPropagation(e);
            const boundaryFeature = boundaryData.features.find(
              (feature) => getBoundaryId(feature.properties) === boundaryMeta.id
            );
            updateCommunitySearch(boundaryFeature, { source: 'search' });
            e.target.blur();
          }}
        >
          <div className={'row w-100 p-0 m-0'}>
            <div className={'col-10 m-0 p-0'}>
              <span style={{ fontWeight: 'bold' }}>{boundaryName}</span>{' '}
              {boundaryMeta.neighborhoods}
            </div>
            <div
              className={`${
                isTargetQuery ? 'visible' : 'invisible'
              } d-flex col-2 p-0 flex-row justify-content-center align-items-center`}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      );
    });

    return searchItems;
  };
  useEffect(() => {
    if (resize) {
      dispatch(collapseDropdowns());
    }
  }, [resize]);

  return (
    <div className={'mobile-community'}>
      {/* grid access - assign 3 children */}
      <div
        className={'mobile-community-search-screen align-items-start'}
        style={{
          zIndex: '1',
        }}
      >
        {/* child 1 - search bar and metric bar */}

        <div
          style={{
            pointerEvents: 'auto',
            width: '100%',
            position: 'relative',
          }}
        >
          {/* district title here- put up in top bar later*/}
          <div
            style={{
              position: 'relative',
              width: '100%',
            }}
            onClick={(e) => {
              dispatch(collapseDropdowns());
            }}
          >
            <CommunitySearchBar
              toggleValue={
                primarySearchMetadata &&
                getBoundaryName(boundaryType, {
                  id: primarySearchMetadata?.id,
                  length: 'short',
                })
              }
              searchType="primary"
              setResize={setResize}
            >
              {getSearchItems(true)}
            </CommunitySearchBar>
          </div>

          {/* metric dropdown menu */}
          {primarySearchQuery && view === viewTypes.MAP && <MobileDropdown />}
        </div>

        {/* main body of the page and grid */}
        <main className="h-100 overflow-hidden">
          {/* main body of page when no map */}
          {view === viewTypes.HISTOGRAM && primarySearchQuery && (
            <>
              <div
                className="h-100"
                style={{
                  pointerEvents: 'auto',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <CommunityProfile boundaryId={primarySearchQuery} />
              </div>
            </>
          )}
        </main>
        {/* bottom legend */}

        {view !== viewTypes.HISTOGRAM && (
          <MobileLegendTray isTouchingMapMobile={isTouchingMapMobile} />
        )}
      </div>
    </div>
  );
}
