// import React and React hooks
import React from 'react';

// import components
import ReactPlayer from 'react-player/youtube';
import CitywideContent from './citywide-data/CitywideContent';
import CommunityContent from './community-profiles/CommunityContent';
import About from './About';

import { useSelector } from 'react-redux';
import { selectPrimaryQuery } from '../store/storeUtils';
import { chapters } from '../utils/constants';

/**
 * Content.js renders the right-2 columns of the spatial equity web app
 */

export default function Content() {
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const chapter = useSelector((state) => state.nav.chapter);
  const selectedMetric = useSelector((state) => state.nav.metric);

  return (
    <div className={`d-flex flex-row col-9`}>
      {/* DISPLAY THE PRIMARY CONTENT */}
      <div
        className={`d-flex h-100 flex-grow-1 ${
          !chapter || chapter === chapters.LANDING ? 'no-left-border' : ''
        } ${
          chapter === chapters.COMMUNITY &&
          primarySearchQuery &&
          !selectedMetric
            ? 'transparent-bg'
            : 'white-bg'
        }`}
        id="main-content"
      >
        {/* Chapter 1: Spatial Equity NYC Home Page */}
        {(chapter === null || chapter === chapters.LANDING) && (
          <ReactPlayer
            style={{
              border: '2px solid black',
              borderLeft: 'none',
            }}
            width={'100%'}
            height={'100%'}
            url="https://www.youtube.com/embed/tSGOYpNTc8k"
            config={{
              youtube: {
                playerVars: { showinfo: 0, controls: 1, modestbranding: 1 },
              },
            }}
          />
        )}
        {/* Chapter 2: Citywide Data */}
        {chapter === chapters.CITYWIDE && <CitywideContent />}
        {/* Chapter 3: Community Profiles */}
        {chapter === chapters.COMMUNITY && <CommunityContent />}
        {/* Chapter 4: About */}
        {chapter === chapters.ABOUT && <About />}
      </div>
    </div>
  );
}
