// import React and React Hooks
import React, { useMemo, useState } from 'react';

// import components
import IssueHistogram from '../visualizations/IssueHistogram';
import RankingTable from '../visualizations/RankingTable';

import { useDispatch, useSelector } from 'react-redux';
import { setMetric } from '../../store/slices/navigationSlice';

import _METRIC_METADATA from '../../meta/metricMetadata.json';
import _METRIC_CATEGORY_METADATA from '../../meta/metricCategoryMetadata.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faCaretRight,
  faCaretDown,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { setShowSolutions } from '../../store/slices/togglesSlice';

import DownloadButton from '../DownloadButton';
import { selectCompareQuery } from '../../store/storeUtils';

/**
 * IssuesCard.js renders the individual issue cards in the Community Profiles section
 */

export default function IssuesCard({
  metricId,
  boundaryMetadata,
  target = false,
}) {
  const selectedMetricId = useSelector((state) => state.nav.metric);
  const showSolutions = useSelector((state) => state.toggles.showSolutions);
  const metricMetadata = useMemo(() => _METRIC_METADATA[metricId], [metricId]);
  const comparisonView = useSelector(selectCompareQuery);
  const isMobile = useSelector((state) => state.mobile.isMobile);

  const dispatch = useDispatch();

  const solutionIsShowing = useMemo(
    () => showSolutions && selectedMetricId === metricId
  );

  const showData = !showSolutions || comparisonView;

  const [rankingsDisplay, setRankingsDisplay] = useState(false);

  const issueColor =
    _METRIC_CATEGORY_METADATA[metricMetadata.metricCategoryId].color;
  const [solutionHovered, onSolutionHover] = useState(false);

  const toggleRankingsDisplay = () => setRankingsDisplay(!rankingsDisplay);
  return (
    <>
      <div
        className="issues-card-container-wrapper"
        style={{ paddingLeft: '1rem' }}
      >
        <div
          className={'issues-card-container'}
          style={{ justifyContent: 'space-between', flexDirection: !isMobile ? 'initial' : 'column' }}
          onClick={() => {
            if (selectedMetricId === metricId) {
            } else {
              dispatch(setMetric(metricId));
            }
          }}
        >
          <div
            className={`d-flex flex-column ${isMobile ? '' : comparisonView ? 'col-4' : showData ? 'col-3' : 'col-11'
              }`}
            style={{ justifyContent: 'space-between', paddingBottom: '1.5rem' }}
          >
            <h2>
              <b>{metricMetadata.name}</b>
            </h2>
            {!(showSolutions && metricMetadata) && (
              <span className={'m-0 smaller-text position-relative'}>
                {metricMetadata.units}{' '}
                {/* <SourceInfo metricMetadata={metricMetadata} /> */}
              </span>
            )}
          </div>
          {showData && (
            <div
              className={`issues-card-body ${isMobile ? '' : showData ? 'col-8' : 'col-5'} `}
            >
              <IssueHistogram
                metricId={metricId}
                boundaryMetadata={boundaryMetadata}
                colorRampsyType={'health'}
                target={target}
              />
            </div>
          )}
          {!comparisonView && !isMobile && (
            <div className="d-flex justify-content-end col-1">
              <button
                className="solutions-button"
                onClick={() => {
                  if (!showSolutions) {
                    dispatch(setShowSolutions(true));
                    return;
                  }
                  if (metricId === selectedMetricId) {
                    dispatch(setShowSolutions(false));
                  }
                }}
                onMouseOver={() => {
                  onSolutionHover(true);
                }}
                onMouseLeave={() => {
                  onSolutionHover(false);
                }}
                style={{
                  backgroundColor:
                    (metricId !== selectedMetricId || !solutionIsShowing) &&
                    !solutionHovered
                      ? 'white'
                      : issueColor,
                  color:
                    (metricId !== selectedMetricId || !solutionIsShowing) &&
                    !solutionHovered
                      ? 'black'
                      : 'white',
                  borderColor:
                    (metricId !== selectedMetricId || !solutionIsShowing) &&
                    !solutionHovered
                      ? 'black'
                      : 'transparent',
                  bottom: !showSolutions ? '-29px' : '-10px',
                }}
              >
                <span
                  style={{
                    fontSize: showSolutions ? '0.75rem' : '1rem',
                    padding: `1rem ${showSolutions ? '0.5rem' : '1rem'}`,
                  }}
                >
                  <h4 style={{ fontSize: 'inherit' }}>Solutions</h4>
                  <FontAwesomeIcon
                    icon={solutionIsShowing ? faMinus : faPlus}
                    color="inherit"
                    fontSize={'x-large'}
                  />
                </span>
              </button>
            </div>
          )}
        </div>

        <div className="expand-rankings">
          {showData && (
            <div className="rankings-buttons">
              <FontAwesomeIcon
                onClick={toggleRankingsDisplay}
                icon={rankingsDisplay ? faCaretDown : faCaretRight}
                color="inherit"
                fontSize={'x-large'}
              />
              <FontAwesomeIcon
                icon={faTable}
                color="inherit"
                fontSize={'x-large'}
              />
              <DownloadButton metricId={metricId} />
            </div>
          )}
        </div>
        <div
          className="d-flex"
          style={{
            flexDirection: 'column',
            width: !isMobile ? '85%' : '',
            height: isMobile ? '' : rankingsDisplay ? '210px' : 0,
            border: rankingsDisplay ? '2px solid black' : 'none',
          }}
        >
          {rankingsDisplay && (
            <>
              <RankingTable
                metricMetadata={metricMetadata}
                communityProfile={true}
                target={target}
                citywideTab={false}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
