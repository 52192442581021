import React, { useState } from 'react';
import {
  faGlobe,
  faChartSimple,
  faList,
} from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import ViewToggleButton from '../ViewToggleButton';
import { viewTypes, chapters } from '../../utils/constants';
import { selectCurrentView, selectPrimaryQuery } from '../../store/storeUtils';

export default function MapToggle() {
  const [hover, setHover] = useState(null);

  const view = useSelector(selectCurrentView);
  const chapter = useSelector((state) => state.nav.chapter);
  const selectedMetric = useSelector((state) => state.nav.metric);
  const primarySearchQuery = useSelector(selectPrimaryQuery);
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const mobileMenuVisible = useSelector((state) => state.mobile.menuVisible);

  // disable map toggle on certain mobile conditions
  const hideMapToggle =
    isMobile && (mobileMenuVisible || (chapter !== 2 && chapter !== 3))
      ? true
      : false;

  return (
    <>
      {hover && !isMobile && (
        <div
          className={'d-inline-block toggle-tooltip'}
          style={{
            position: 'absolute',
            top: '4rem',
            right: '0.5rem',
            backgroundColor: 'black',
            padding: '0.5rem',
            zIndex: '3',
          }}
        >
          {hover}
        </div>
      )}
      <div
        className={`map-toggle-container ${hideMapToggle ? 'disabled' : ''}`}
      >
        {/* enable table/list */}
        {chapter === chapters.CITYWIDE &&
          view !== viewTypes.MAP &&
          selectedMetric && (
            <ViewToggleButton
              viewType={viewTypes.TABLE}
              icon={faList}
              setHover={setHover}
            >
              List
            </ViewToggleButton>
          )}
        {/* enable chart */}
        {!(chapter === chapters.COMMUNITY && !primarySearchQuery) && (
          <ViewToggleButton
            viewType={viewTypes.HISTOGRAM}
            icon={faChartSimple}
            setHover={setHover}
          >
            Chart
          </ViewToggleButton>
        )}
        {/* enable map */}
        <ViewToggleButton
          viewType={viewTypes.MAP}
          icon={faGlobe}
          setHover={setHover}
        >
          Map
        </ViewToggleButton>
      </div>
    </>
  );
}
