import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import _BOROUGH_COLORS from '../../meta/boroughColors.json';
import { useGetBoundaryName, useResizeObserver } from '../../utils/hooks';
import SourceInfo from '../SourceInfo';

import {
  colorInterpolate,
  getBoundaryId,
  getFormattedNumber,
} from '../../utils/functions';
import {
  selectBoundaryData,
  selectCurrentView,
  selectMetricMetadata,
  selectMetricVizData,
} from '../../store/storeUtils';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchFromSearchBar } from '../../store/slices/communitySearchSlice';
import { setChapter } from '../../store/slices/navigationSlice';
import {
  addPinnedBoundary,
  removePinnedBoundary,
} from '../../store/slices/vizSlice';
import _BOUNDARY_METADATA from '../../meta/boundaryMetadata.json';
import { boundaryTypes, viewTypes } from '../../utils/constants';

const Histogram = ({ colorRamp, useBoroughColor }) => {
  const ref = useRef();
  const containerRef = useRef();

  const isMobile = useSelector((state) => state.mobile.isMobile);
  const navState = useSelector((state) => state.nav);
  const visible = useSelector(
    (state) => selectCurrentView(state) === viewTypes.HISTOGRAM
  );
  const metricMetadata = useSelector(selectMetricMetadata);
  const pinnedBoundaries = useSelector((state) => state.viz.pinnedBoundaries);
  const allBoundaryData = useSelector(selectBoundaryData);
  const dispatch = useDispatch();
  const { metricData } = useSelector(selectMetricVizData);
  const getBoundaryName = useGetBoundaryName();

  const getDataToVis = (metricData, metricMetadata) => {
    let valueArray = [];
    let nameArray = [];
    let ascending;
    let lookupArray = [];

    if (metricMetadata.higherValueIsBad) {
      // Put larger values at the top of the histogram (sort in descending order)
      metricData.sort((a, b) => b.value - a.value);
    } else {
      // Put smaller values at the top of the histogram (sort in ascending order)
      metricData.sort((a, b) => a.value - b.value);
    }

    metricData.forEach((data) => {
      valueArray.push(Number(Number(data.value).toFixed(3)));
      nameArray.push(getBoundaryName({ id: data.boundaryId, length: 'short' }));
      lookupArray.push(data.boundaryId);
    });

    const isTemperature = metricMetadata.jsonId == 'F14_TmpDev' ? true : false;

    // get the corresponding index of average value
    let sum = valueArray.reduce((a, b) => a + b, 0);
    let avg = isTemperature ? 0 : Number(sum / valueArray.length);
    let avgIndex;
    let avgRectID;

    for (let i = 0; i < valueArray.length - 1; i++) {
      if (valueArray[i] < avg && valueArray[i + 1] > avg) {
        avgIndex =
          i + (avg - valueArray[i]) / (valueArray[i + 1] - valueArray[i]);
        avgRectID = Math.round(avgIndex);
        ascending = true;
        break;
      }

      if (valueArray[i] > avg && valueArray[i + 1] < avg) {
        avgIndex =
          i +
          1 -
          (avg - valueArray[i + 1]) / (valueArray[i] - valueArray[i + 1]);
        avgRectID = Math.round(avgIndex);
        ascending = false;
        break;
      }
    }

    return [
      valueArray,
      nameArray,
      avg,
      avgIndex,
      avgRectID,
      ascending,
      lookupArray,
      isTemperature,
    ];
  };

  const getBoundingStatement = (minMax) => {
    const boundText = metricMetadata.boundTexts.boundText;
    const bounds = [
      metricMetadata.boundTexts.max + ' ' + boundText,
      metricMetadata.boundTexts.min + ' ' + boundText,
    ];

    const lookup = Number(metricMetadata.higherValueIsBad);

    if (minMax == 'max') {
      return `${bounds[Number(!lookup)]}`;
    } else {
      return `${bounds[lookup]}`;
    }
  };

  const textWidth = 50;
  const [currentHoveredCommunityID, setCurrentHoveredCommunityID] =
    useState('');

  const [containerWidth, containerHeight] = useResizeObserver(containerRef);

  const margin = {
    top: 13,
    left: 0,
    bottom: 30,
    right: 15,
  };

  let [
    data,
    nameArray,
    avg,
    avgIndex,
    avgRectID,
    ascending,
    lookupArray,
    isTemperature,
  ] = getDataToVis([...metricData], metricMetadata);

  let colorArray = [];

  const boundaryMetadata = _BOUNDARY_METADATA[navState.boundaryType];
  for (let i = 0; i < data.length; i++) {
    let boroughName =
      navState.boundaryType !== boundaryTypes.COMMUNITY_BOARD
        ? boundaryMetadata
            .find((meta) => meta.id === lookupArray[i])
            ?.borough.replace(',', '')
            .split(' ')[0]
        : nameArray[i].split(' ')[0];

    if (!boroughName) {
      console.debug(
        'Boundary',
        lookupArray[i],
        nameArray[i],
        'does not exist in metadata, could not get borough! Defaulting to Manhattan'
      );
      boroughName = 'Manhattan';
    }
    if (useBoroughColor) {
      colorArray.push(
        d3.rgb(
          ...colorInterpolate(
            _BOROUGH_COLORS[boroughName].deckFormat,
            _BOROUGH_COLORS[boroughName].deckFormat,
            0
          )
        )
      );
    } else {
      colorArray.push(
        d3.rgb(
          ...colorInterpolate(
            colorRamp[2],
            colorRamp[2],
            ascending
              ? 1 - i / (metricData.length - 1)
              : i / (metricData.length - 1)
          )
        )
      );
    }
  }

  useEffect(() => {
    let svg = d3.select(ref.current);
    const height = containerHeight ? containerHeight : 0;
    const width = containerWidth ? containerWidth : 500;

    //  Init mouse line
    svg.select('#mouseLine').style('stroke-width', 0);

    svg
      .select('#mouseTextUp')
      .attr('text-anchor', 'end')
      .attr('x', width - margin.right)
      .attr(
        'style',
        'font-family:Inter; text-shadow:rgb(255 255 255) -1px -1px 0px, rgb(255 255 255) -1px 1px 0px, rgb(255 255 255) 1px -1px 0px, rgb(255 255 255) 1px 1px 0px'
      )
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text('');

    svg
      .select('#mouseTextDown')
      .attr('text-anchor', 'end')
      .attr('x', width - margin.right)
      .attr(
        'style',
        'font-family:Inter; text-shadow:rgb(255 255 255) -1px -1px 0px, rgb(255 255 255) -1px 1px 0px, rgb(255 255 255) 1px -1px 0px, rgb(255 255 255) 1px 1px 0px'
      )
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text('');
  }, [
    colorRamp,
    navState.boundaryType,
    navState.metric,
    containerWidth,
    containerHeight,
  ]);

  useEffect(() => {
    const height = containerHeight ? containerHeight - 10 : 0;
    const width = containerWidth ? containerWidth : 500;

    // histogram bars attr
    let barPadding = 0;
    let barHeight = (height - margin.top - margin.bottom) / data.length;
    let minValueMargin = 0.05 * (d3.max(data) - d3.min(data));
    let longestBarPadding = 0;

    const metricSymbol =
      metricMetadata.legendTicksUnitsSymbol !== ''
        ? metricMetadata.legendTicksUnitsSymbol
        : '';

    let xscale = d3
      .scaleLinear()
      // .domain([0, d3.max(data)])
      .domain([
        d3.min(data) >= 0 ? d3.min(data) - minValueMargin : d3.min(data),
        d3.max(data),
      ])
      .range([0, width - longestBarPadding - margin.right - margin.left]);

    let yscale = d3
      .scaleLinear()
      .domain([0, data.length])
      .range([margin.top, height - margin.bottom]);

    let yUnit = yscale(1) - yscale(0);

    // build SVG
    let svg = d3
      .select(ref.current)
      .attr('height', '100%')
      .attr('width', '100%');

    // create Chart
    svg
      .select('g')
      .attr('class', 'rect')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .merge(svg.select('g').attr('class', 'rect').selectAll('rect').data(data))
      .attr('height', barHeight - barPadding >= 0 ? barHeight - barPadding : 0)
      .attr('width', (d) =>
        d3.min(data) >= 0
          ? xscale(d)
          : d > 0
          ? xscale(d) - xscale(0)
          : xscale(0) - xscale(d)
      )
      .attr('y', (d, i) => yscale(i + 0.5))
      .attr('x', (d) =>
        d3.min(data) >= 0
          ? margin.left
          : d > 0
          ? margin.left + xscale(0)
          : margin.left + xscale(d)
      )
      .attr('initColor', (d, i) => colorArray[i])
      .style('fill', (d, i) => colorArray[i])
      .attr('value', (d) => d)
      .attr('lookupID', (d, i) => lookupArray[i]);

    // clear Chart
    svg
      .select('g')
      .attr('class', 'rect')
      .selectAll('rect')
      .data(data)
      .exit()
      .remove();

    // draw Lines
    svg
      .select('#minLine')
      .attr('x1', margin.left)
      .attr('y1', yscale(0.5))
      .attr('x2', width - margin.right)
      .attr('y2', yscale(0.5))
      .style('stroke', 'black')
      .style('stroke-width', 1);

    svg
      .select('#maxLine')
      .attr('x1', margin.left)
      .attr('y1', yscale(data.length + 0.5))
      .attr('x2', width - margin.right)
      .attr('y2', yscale(data.length + 0.5))
      .style('stroke', 'black')
      .style('stroke-width', 1);

    svg
      .select('#minText')
      .attr('x', width - margin.right - textWidth)
      .attr('y', yscale(0.5) - 5)
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text(
        !ascending
          ? `${getBoundingStatement('max')}`
          : `${getBoundingStatement('min')}`
      );

    svg
      .select('#maxText')
      .attr('x', width - margin.right - textWidth)
      .attr('y', yscale(data.length + 0.5) + 15)
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text(
        ascending
          ? `${getBoundingStatement('max')}`
          : `${getBoundingStatement('min')}`
      );

    // Adjust text position
    svg
      .select('#maxText')
      .attr(
        'x',
        width -
          margin.right -
          svg.select('#maxText').node().getBoundingClientRect().width
      );

    svg
      .select('#minText')
      .attr(
        'x',
        width -
          margin.right -
          svg.select('#minText').node().getBoundingClientRect().width
      );

    svg
      .select('#avgLine')
      //   .attr('x1', margin.left)
      .attr('x1', margin.left + xscale(0))
      .attr('y1', yscale(avgIndex + 1))
      .attr('x2', width - margin.right)
      .attr('y2', yscale(avgIndex + 1))
      .style('stroke', 'black')
      .style('stroke-dasharray', '3, 3')
      .style('stroke-width', 1);

    svg
      .select('#avgTextUp')
      .attr('x', width - margin.right - textWidth)
      .attr('y', yscale(avgIndex + 1) - 5)
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text('Citywide Average');

    svg
      .select('#avgTextDown')
      .attr('x', width - margin.right - textWidth)
      .attr('y', yscale(avgIndex + 1) + 15)
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text(
        `${isTemperature ? '98.6' : getFormattedNumber(avg, metricMetadata)}${
          metricMetadata.histogramTooltipSnippet
        }`
      );

    // Adjust text position
    svg
      .select('#avgTextUp')
      .attr(
        'x',
        width -
          margin.right -
          svg.select('#avgTextUp').node().getBoundingClientRect().width
      );

    svg
      .select('#avgTextDown')
      .attr(
        'x',
        width -
          margin.right -
          svg.select('#avgTextDown').node().getBoundingClientRect().width
      );

    svg
      .select('#histBg')
      .attr('height', height >= 0 ? height : 0)
      .attr('width', width - margin.left - margin.right)
      .attr('y', 0)
      .attr('x', margin.left)
      .attr('fill', d3.rgb(0, 0, 0, 0))
      .on('mousemove touchmove', function (event, d) {
        event.stopPropagation();
        let pt = d3.pointers(event)[0];
        // let pt = d3.pointer(event);

        let ycood = pt[1];
        if (ycood < yscale(0.5)) return;
        if (ycood > yscale(data.length + 0.5)) return;

        let rectID = Math.floor(yscale.invert(ycood) - 0.5);
        if (!lookupArray[rectID]) return;

        setCurrentHoveredCommunityID(lookupArray[rectID]);

        svg
          .select('#mouseLine')
          // .transition()
          // .duration(10)
          // .ease('linear')
          .attr('y1', ycood)
          .attr('y2', ycood)
          //   .attr('x1', margin.left)
          .attr('x1', margin.left + xscale(0))
          .attr('x2', width - margin.right)
          .attr('lookupID', lookupArray[rectID])
          .style('stroke', 'black')
          .style('stroke-width', 1);

        svg
          .select('#mouseTextUp')
          .attr('y', ycood - 5)
          .attr('lookupID', lookupArray[rectID])
          .text(nameArray[rectID]);

        svg
          .select('#mouseTextDown')
          .attr('y', ycood + 15)
          .attr('lookupID', lookupArray[rectID])
          .text(
            `${getFormattedNumber(data[rectID], metricMetadata)}${
              metricMetadata.histogramTooltipSnippet
            }`
          );
      })
      .on('click', (event, d) => {
        let pt = d3.pointer(event);

        let ycood = pt[1];
        if (ycood < yscale(0.5)) return;
        if (ycood > yscale(data.length + 0.5)) return;

        let rectID = Math.floor(yscale.invert(ycood) - 0.5);
        if (!lookupArray[rectID]) return;

        dispatch(
          addPinnedBoundary({
            boundaryType: navState.boundaryType,
            boundaryId: lookupArray[rectID],
          })
        );
      });

    // Draw all the line and make them invisible
    svg
      .selectAll('.pinnedLine')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'pinnedLine')
      .merge(svg.selectAll('.pinnedLine').data(data))
      .attr('y1', (d, i) => yscale(i + 1))
      .attr('y2', (d, i) => yscale(i + 1))
      .attr('x1', margin.left)
      //   .attr('x1', margin.left + xscale(0))
      .attr('x2', width - margin.right)
      .attr('visibility', 'hidden')
      .attr('lookupID', (d, i) => lookupArray[i])
      .style('stroke', 'black')
      .style('stroke-width', 1);

    svg.selectAll('.pinnedLine').data(data).exit().remove();

    // Draw all the pinnedTextUp and make them invisible
    svg
      .selectAll('.pinnedTextUp')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'pinnedTextUp')
      .merge(svg.selectAll('.pinnedTextUp').data(data))
      .attr('y', (d, i) => yscale(i + 1) - 5)
      .attr('x', width - margin.right)
      .attr('text-anchor', 'end')
      .attr('visibility', 'hidden')
      .attr('lookupID', (d, i) => lookupArray[i])
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text((d, i) => nameArray[i]);

    svg.selectAll('.pinnedTextUp').data(data).exit().remove();

    // Draw all the pinnedTextDown and make them invisible
    svg
      .selectAll('.pinnedTextDown')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'pinnedTextDown')
      .merge(svg.selectAll('.pinnedTextDown').data(data))
      .attr('y', (d, i) => yscale(i + 1) + 15)
      .attr('x', width - margin.right)
      .attr('text-anchor', 'end')
      .attr('visibility', 'hidden')
      .attr('lookupID', (d, i) => lookupArray[i])
      .attr('style', 'font-family:Inter')
      .attr('font-size', '12')
      .attr('fill', '#000000')
      .text(
        (d) =>
          `${getFormattedNumber(d, metricMetadata)}${
            metricMetadata.histogramTooltipSnippet
          }`
      );

    svg.selectAll('.pinnedTextDown').data(data).exit().remove();

    // Add goto function on pinnedTextUp
    svg.selectAll('.pinnedTextUp').each(function () {
      d3.select(this).on('click', () => {
        dispatch(setChapter(3));

        const thisId = Number(d3.select(this).attr('lookupID'));

        const thisBoundaryData = allBoundaryData.features.find(
          (feature) => getBoundaryId(feature.properties) === thisId
        ).properties;

        console.log(
          'Setting primary search from the HISTOGRAM',
          thisBoundaryData
        );

        const coords = [thisBoundaryData.X_cent, thisBoundaryData.Y_cent];
        dispatch(
          setSearchFromSearchBar({
            isPrimary: true,
            coords,
            searchResults: [thisId],
          })
        );
      });
    });

    // Draw all cancel button text
    svg
      .selectAll('.cancelButtonText')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'cancelButtonText')
      .merge(svg.selectAll('.cancelButtonText').data(data))
      .attr('y', (d, i) => yscale(i + 1) + 5)
      //   .attr('x', margin.left - 5)
      .attr('x', width)
      .attr('text-anchor', 'end')
      .attr('visibility', 'hidden')
      .style('font-weight', 'bold')
      .attr('fill', '#000000')
      .attr('font-size', '16')
      .text('✕')
      .attr('lookupID', (d, i) => lookupArray[i]);

    svg.selectAll('.cancelButtonText').each(function (d, i) {
      d3.select(this).on('click', (e, d) => {
        dispatch(
          removePinnedBoundary({
            boundaryType: navState.boundaryType,
            boundaryId: Number(d3.select(this).attr('lookupID')),
          })
        );
      });
    });

    svg.selectAll('.cancelButtonText').data(data).exit().remove();

    // move the interaction layer to front
    svg.select('#histBg').raise();
    // svg.select('#resetBg').raise();
    // svg.select('#resetButton').raise();
    svg.selectAll('.pinnedTextUp').raise();
    svg.selectAll('.pinnedLine').lower();
    svg.select('#mouseLine').lower();
  }, [
    colorRamp,
    navState.boundaryType,
    navState.metric,
    containerWidth,
    containerHeight,
    pinnedBoundaries,
    useBoroughColor,
  ]);

  useEffect(() => {
    let svg = d3.select(ref.current);

    for (let element of ['#mouseLine', '#mouseTextUp', '#mouseTextDown']) {
      svg.selectAll(element).each(function (d, i) {
        if (
          pinnedBoundaries[navState.boundaryType]?.includes(
            Number(d3.select(this).attr('lookupID'))
          ) ||
          !d3.select(this).attr('lookupID')
        ) {
          d3.select(this).attr('visibility', 'hidden');
        } else {
          d3.select(this).attr('visibility', 'visible');
        }
      });
    }

    // mouse text white bg
    svg
      .select('#mouseTextUp')
      .attr(
        'width',
        svg.select('#mouseTextUp').node().getBoundingClientRect().width
      )
      .attr(
        'height',
        svg.select('#mouseTextUp').node().getBoundingClientRect().height
      );

    svg
      .select('#mouseTextDown')
      .attr(
        'width',
        svg.select('#mouseTextDown').node().getBoundingClientRect().width
      )
      .attr(
        'height',
        svg.select('#mouseTextDown').node().getBoundingClientRect().height
      );

    for (let element of [
      '.pinnedLine',
      '.pinnedTextUp',
      '.pinnedTextDown',
      //   '.goToButton',
    ]) {
      svg.selectAll(element).each(function (d, i) {
        if (
          pinnedBoundaries[navState.boundaryType]?.includes(
            Number(d3.select(this).attr('lookupID'))
          ) ||
          !d3.select(this).attr('lookupID')
        ) {
          d3.select(this).attr('visibility', 'visible');
        } else {
          d3.select(this).attr('visibility', 'hidden');
        }
      });
    }

    for (let element of ['.cancelButton', '.cancelButtonText']) {
      svg.selectAll(element).each(function (d, i) {
        const selection = d3.select(this);
        if (
          pinnedBoundaries[navState.boundaryType]?.includes(
            Number(selection.attr('lookupID'))
          )
        ) {
          selection.attr('visibility', 'visible');
        } else {
          selection.attr('visibility', 'hidden');
        }
      });
    }

    // when avgline is close to mouseline or is overlapped with another pinned line, hide the avgline
    let hideAvgLine = false;
    if (Math.abs(avgIndex - lookupArray.indexOf(currentHoveredCommunityID)) < 3)
      hideAvgLine = true;
    const pinned = pinnedBoundaries[navState.boundaryType];
    if (
      pinned?.includes(lookupArray[avgRectID]) ||
      pinned?.includes(lookupArray[avgRectID - 1]) ||
      pinned?.includes(lookupArray[avgRectID + 1])
    ) {
      hideAvgLine = true;
    }

    if (hideAvgLine) {
      svg.select('#avgLine').attr('visibility', 'hidden');
      svg.select('#avgTextUp').attr('visibility', 'hidden');
      svg.select('#avgTextDown').attr('visibility', 'hidden');
    } else {
      svg.select('#avgLine').attr('visibility', 'visible');
      svg.select('#avgTextUp').attr('visibility', 'visible');
      svg.select('#avgTextDown').attr('visibility', 'visible');
    }

    // when maxline is close to mouseline or is overlapped with another pinned line, hide the maxline
    let hideMaxLine = false;
    if (lookupArray.indexOf(currentHoveredCommunityID) == data.length - 1)
      hideMaxLine = true;

    if (pinned?.includes(lookupArray[data.length - 1])) {
      hideMaxLine = true;
    }

    if (hideMaxLine) {
      svg.select('#maxLine').attr('visibility', 'hidden');
      svg.select('#maxText').attr('visibility', 'hidden');
    } else {
      svg.select('#maxLine').attr('visibility', 'visible');
      svg.select('#maxText').attr('visibility', 'visible');
    }

    // when minline is close to mouseline or is overlapped with another pinned line, hide the minline
    let hideMinLine = false;
    if (lookupArray.indexOf(currentHoveredCommunityID) == 0) hideMinLine = true;
    if (pinned?.includes(lookupArray[0])) {
      hideMinLine = true;
    }

    if (hideMinLine) {
      svg.select('#minLine').attr('visibility', 'hidden');
      svg.select('#minText').attr('visibility', 'hidden');
    } else {
      svg.select('#minLine').attr('visibility', 'visible');
      svg.select('#minText').attr('visibility', 'visible');
    }

    svg
      .select('g')
      .selectAll('rect')
      .each(function (d, i) {
        if (
          d3.select(this).attr('lookupID') == currentHoveredCommunityID ||
          pinnedBoundaries[navState.boundaryType]?.includes(
            Number(d3.select(this).attr('lookupID'))
          )
        ) {
          // border + move back lines
          d3.select(this)
            .attr('stroke', '#000000')
            .style('stroke-width', '2px');
          d3.select(this).raise();
          //   svg.select('#mouseLine').attr('x1', d3.select(this).attr('x'));
          //   svg.select('#avgLine').attr('x1', d3.select(this).attr('x'));
        } else {
          d3.select(this).style('stroke-width', '0px');
          d3.select(this).style(
            'fill',
            d3.rgb(d3.select(this).attr('initColor'))
          );
        }
      });
  }, [
    colorRamp,
    navState.boundaryType,
    navState.metric,
    containerWidth,
    containerHeight,
    currentHoveredCommunityID,
    pinnedBoundaries,
    useBoroughColor,
  ]);

  return (
    <>
      <div
        className={'m-0 small-font d-inline-block'}
        style={{ padding: isMobile ? '0 1rem' : '1rem 1.5rem 0 1.5rem' }}
      >
        {metricMetadata.units}{' '}
        <SourceInfo metricMetadata={metricMetadata} verticalHistogram={true} />
      </div>
      <div
        className={'d-flex histogram-responsive-box'}
        style={{
          width: '100%',
          flexGrow: '1',
          padding: isMobile ? '0 1rem' : '0 1.5rem 0 1.5rem',
        }}
      >
        <div
          className={'d-flex flex-row position-relative'}
          style={{
            width: '100%',
            top: '10px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        ></div>

        <div
          ref={containerRef}
          style={{
            width: '100%',
            flexGrow: 1,
          }}
          className={'position-relative'}
        >
          <svg
            display={!visible ? 'none' : ''}
            ref={ref}
            style={{ touchAction: 'none' }}
          >
            {/* Main Chart */}
            <g />

            {/* Avg Line */}
            <line id="avgLine" />
            <text id="avgTextUp" />
            <text id="avgTextDown" />

            {/* Interactive Line */}
            <line id="mouseLine" />
            <text id="mouseTextUp" />
            <text id="mouseTextDown" />
            <rect id="histBg" />

            {/* Min/Max Line */}
            <line id="maxLine" />
            <line id="minLine" />
            <text id="maxText" />
            <text id="minText" />
          </svg>
        </div>
      </div>

      <div>
        <div
          className={`${
            useBoroughColor ? '' : 'invisible'
          } d-flex flex-row position-relative mb-2`}
          style={{
            justifyContent: 'start',
            flexGrow: '1',
            marginLeft: isMobile ? '' : '3px',
            marginRight: isMobile ? '' : '30px',
            flexWrap: 'wrap',
            padding: isMobile ? '' : '0 1.5rem 0 1.5rem',
          }}
        >
          <div className={'d-flex flex-row'}>
            <div
              className={'mb-0 small-font'}
              style={{
                color: _BOROUGH_COLORS['Bronx'].htmlFormat,
                marginRight: '2px',
              }}
            >
              ■
            </div>
            <p
              className={'mb-0 small-font'}
              style={{
                marginRight: '8px',
              }}
            >
              Bronx
            </p>
          </div>
          <div className={'d-flex flex-row'}>
            <div
              className={'mb-0 small-font'}
              style={{
                color: _BOROUGH_COLORS['Brooklyn'].htmlFormat,
                marginRight: '2px',
              }}
            >
              ■
            </div>
            <p
              className={'mb-0 small-font'}
              style={{
                marginRight: '8px',
              }}
            >
              Brooklyn
            </p>
          </div>
          <div className={'d-flex flex-row'}>
            <div
              className={'mb-0 small-font'}
              style={{
                color: _BOROUGH_COLORS['Manhattan'].htmlFormat,
                marginRight: '2px',
              }}
            >
              ■
            </div>
            <p
              className={'mb-0 small-font'}
              style={{
                marginRight: '8px',
              }}
            >
              Manhattan
            </p>
          </div>
          <div className={'d-flex flex-row'}>
            <div
              className={'mb-0 small-font'}
              style={{
                color: _BOROUGH_COLORS['Queens'].htmlFormat,
                marginRight: '2px',
              }}
            >
              ■
            </div>
            <p
              className={'mb-0 small-font'}
              style={{
                marginRight: '8px',
              }}
            >
              Queens
            </p>
          </div>
          <div className={'d-flex flex-row'}>
            <div
              className={'mb-0 small-font'}
              style={{
                color: _BOROUGH_COLORS['Staten'].htmlFormat,
                marginRight: '2px',
              }}
            >
              ■
            </div>
            <p className={'mb-0 small-font'}>Staten Island</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Histogram;
