import SourceInfo from './SourceInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import {
  removeAdditionalMetric,
  setMetric,
} from '../store/slices/navigationSlice';

import _METRIC_METADATA from '../meta/metricMetadata.json';
import { useMemo, useRef, useEffect } from 'react';
import { setHeaderHeight } from '../store/slices/vizSlice';
import { useGetBoundaryName } from '../utils/hooks';
import DownloadButton from './DownloadButton';
// import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

const mobilePadding = {
  paddingLeft: '0.5rem',
};

var colorType = {
  1: '#e85be8',
  2: '#fc4b38',
  3: '#5080ea',
};

/**
 * Headers for specific parts of the right column of the website.
 * Types include:
 * * `'solutions'`
 * * `'histogram header'`
 * * `'card'`
 * * `'more issues'`
 * * `'collapse'`
 */
export default function Header({
  type = 'solutions',
  metricId,
  target,
  forMoreIssues,
  expand,

  // mobile
  citywideTab = false,
}) {
  const isMobile = useSelector((state) => state.mobile.isMobile);
  const selectedMetricId = useSelector((state) => state.nav.metric);
  const headerHeight = useSelector((state) => state.viz.headerHeight);
  const dispatch = useDispatch();

  const getBoundaryName = useGetBoundaryName();

  const header = useRef(null);

  useEffect(() => {
    const element = header.current;
    if (!element) {
      return;
    }
    const height = element.clientHeight;
    dispatch(setHeaderHeight(height));
  }, [header.current?.clientHeight]);

  const metricMetadata = useMemo(() => _METRIC_METADATA[metricId], [metricId]);

  const getMetricDescription = () => {
    return metricMetadata.name || null;
  };

  // if (type === 'solutions') {
  //   return (
  //     <div className={'d-flex flex-column position-relative'}>
  //       <div
  //         className={`${'issues-chapters-active'} collapse-issue issues-chapters top-border transition-height`}
  //       >
  //         <div
  //           className="position-relative d-grid "
  //           style={{
  //             gridTemplateColumns: '1fr auto',
  //             gridGap: '0.33rem',
  //             alignItems: 'center',
  //             cursor: 'default',
  //             height: `${headerHeight}px`,
  //           }}
  //         >
  //           <h6 className="mb-0">Solutions</h6>
  //         </div>
  //       </div>
  //     </div>
  //   );
  if (type === 'histogram header') {
    return (
      <div className={'d-flex flex-column position-relative'}>
        <div
          className={`issues-chapters-active collapse-issue issues-chapters top-border transition-height`}
        >
          <div
            // ref={header}
            className="position-relative d-flex "
            style={{
              // gridTemplateColumns: '1fr auto',
              // gridGap: '0.33rem',
              // alignItems: 'center',
              cursor: 'default',
              backgroundColor: colorType[metricMetadata.metricCategoryId],
              borderColor: colorType[metricMetadata.metricCategoryId],
              height: `${headerHeight}px`,
              display: 'flex',
              flexDirection: 'column',
              // padding: '0.1rem'
              // flex-direction: 'column'
              // position: 'relative'
            }}
          >
            <h6
              className={'mb-0'}
              style={{
                flex: '1',
                textAlign: 'left',
                paddingLeft: '0.8rem',
                paddingBottom: '0rem',
                fontSize: '1rem',
                fontWeight: '600',
              }}
            >
              {getBoundaryName({ length: 'medium', plural: true })} Ranked by
            </h6>
            <h6
              className={'mb-0'}
              style={{
                flex: '2',
                textAlign: 'left',
                paddingLeft: '0.8rem',
                fontSize: '1.75rem',
                fontWeight: '700',
              }}
            >
              {metricMetadata.title}
            </h6>
            <div style={{ position: 'absolute', right: 0, bottom: '1rem' }}>
              <DownloadButton metricId={metricId} color="white" />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === 'collapse') {
    return (
      <div className={'d-flex flex-column position-relative'}>
        <div
          className={`${isMobile && citywideTab
              ? 'issues-chapters-inactive'
              : 'issues-chapters-active'
            } issues-chapters transition-height expand-toggle`}
        >
          <div
            className="position-relative d-grid "
            style={{
              gridGap: '0.33rem',
              alignItems: 'center',
              textAlign: 'center',
              border: 'none',
              padding: '0',
            }}
          >
            <h6 style={{ paddingLeft: '0' }}>
              <FontAwesomeIcon icon={!expand ? faCaretDown : faCaretUp} />
            </h6>
          </div>
        </div>
      </div>
    );
  }
}
