import { useMemo, useCallback } from 'react';
import {
  CHOROPLETH_OPACITY,
  TOOLTIP_WIDTH,
  zoomCutoffs,
  zoomLevels,
  ZOOM_MAX,
  ZOOM_MIN,
} from './constants';
import { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { distance } from '@turf/turf';
import { point } from '@turf/helpers';
import {
  isValidFeature,
  getBoundaryId,
  interpolate,
  getValueFromFeature,
  getNoCommuteTogglesSelected,
} from '../../utils/functions';

import _METRIC_METADATA from '../../meta/metricMetadata.json';

import MapTooltip from './MapTooltip';
import { setLoading } from '../../store/slices/mapSlice';
import { useDispatch } from 'react-redux';

/**
 * Memoized zoom level based on the zoom value.
 *
 * @param {number} zoomValue - Mapbox zoom value
 * @returns {number} The zoom level (see zoomLevels in map/constants.js)
 */
export function useZoomLevel(zoomValue) {
  return useMemo(() => {
    if (zoomValue > zoomCutoffs.NORMAL_TO_HIGH) {
      return zoomLevels.HIGH;
    } else if (zoomValue > zoomCutoffs.LOW_TO_NORMAL) {
      return zoomLevels.NORMAL;
    }
    return zoomLevels.LOW;
  }, [zoomValue]);
}

/**
 * Hook that updates the width a tooltip based on a trigger.
 *
 * @param {string} elementId - The native HTML element ID of the tooltip
 * @param {function} widthSetter - State setter function for the width
 * @param {any} trigger - Additional trigger for the useEffect
 */
export function useTooltipWidthUpdater(elementId, widthSetter, trigger) {
  useEffect(() => {
    const tooltipElement = document.getElementById(elementId);
    const elementWidth = tooltipElement?.offsetWidth;
    widthSetter((prevState) => {
      if (elementWidth === prevState?.width) {
        return prevState;
      }
      return {
        ...prevState,
        width: elementWidth,
      };
    });
  }, [trigger, elementId, widthSetter]);
}

/** Gets the fill color for the metric choropleth. */
export function getChoroplethFillColor(
  feature,
  metricJsonId,
  zoomLevel,
  colorScale,
  isNeighborhood
) {
  let opacity = CHOROPLETH_OPACITY * 255;
  if (
    zoomLevel >= zoomLevels.HIGH ||
    (isNeighborhood && zoomLevel < zoomLevels.NORMAL) ||
    (!isNeighborhood && zoomLevel >= zoomLevels.NORMAL)
  ) {
    opacity = 0;
  }

  const fillValue = getValueFromFeature(feature, metricJsonId);
  if (!isValidFeature(feature) || isNaN(fillValue)) {
    return [0, 0, 0, 0];
  }
  return [...colorScale(fillValue), opacity];
}

/** Gets the fill color for the demographic choropleth. */
export function getDemographicFillColor(
  feature,
  zoomLevel,
  demographicMetadata,
  demographicValues,
  commuteToggles,
  colorScale
) {
  let opacity = CHOROPLETH_OPACITY * 255;
  if (zoomLevel >= zoomLevels.HIGH) {
    opacity = 0;
  }

  if (!demographicMetadata) {
    return [0, 0, 0, 0];
  }

  if (!isValidFeature(feature)) {
    return [0, 0, 0, 0];
  }
  // Note: Feature IDs are 1-indexed
  const fillValue = demographicMetadata.multivalue
    ? demographicValues.get(getBoundaryId(feature.properties))
    : parseFloat(feature.properties[demographicMetadata.lookup]);

  if (
    isNaN(fillValue) ||
    (demographicMetadata.multivalue &&
      getNoCommuteTogglesSelected(commuteToggles, demographicMetadata.id))
  ) {
    return [255, 255, 255, opacity];
  }
  return [...colorScale(fillValue), opacity];
}

/** Returns a memoized function that gets the hatch pattern if underperformer. */
export function useGetHatchPattern(underperformerIds) {
  return useCallback(
    (feature) => {
      if (!isValidFeature(feature) || !underperformerIds) {
        return 'hatch-solid';
      }
      if (underperformerIds.includes(getBoundaryId(feature.properties))) {
        return 'hatch-pattern';
      }
      return 'hatch-solid';
    },
    [underperformerIds]
  );
}

/** Returns a memoized function that returns the hover tooltip HTML. */
export function useCustomHoverTooltip(
  navState,
  searchState,
  toggles,
  selectedMetricData,
  selectedMetricMetadata,
  selectedDemographicMetadata,
  commuteModesString
) {
  return useCallback(
    (info) => {
      const tooltipElement = (
        <MapTooltip
          boundaryType={navState.boundaryType}
          selectedMetricMetadata={selectedMetricMetadata}
          selectedMetricData={selectedMetricData}
          demographicId={
            toggles.showDemographicsTab ? navState.demographic : null
          }
          selectedDemographicMetadata={selectedDemographicMetadata}
          commuteToggles={toggles.commuteToggles}
          commuteModesString={commuteModesString}
          isSubway={info.layer?.id === 'subway-lines'}
          pickingInfoObject={info.object}
          primarySearchQuery={searchState.data.primary.query}
          compareSearchQuery={searchState.data.compare.query}
        />
      );

      const tooltipHtml = renderToStaticMarkup(tooltipElement);

      // Flip the tooltip if it is close to the right side of the viewport
      let transformation = `translate(${info.x}px, ${info.y}px)`;
      if (info.viewport) {
        const { width, height } = info.viewport;
        const newX =
          info.x > width - TOOLTIP_WIDTH + 50 ? info.x - TOOLTIP_WIDTH : info.x;
        transformation = `translate(${newX}px, ${info.y}px)`;
      }

      // Return the static tooltip HTML in an object
      if (tooltipHtml) {
        return {
          className: 'map-tooltip',
          html: tooltipHtml,
          style: {
            background: 'none',
            margin: '0',
            color: 'black',
            padding: '0',
            transform: transformation,
          },
        };
      }
    },
    [
      navState,
      searchState,
      toggles,
      selectedMetricData,
      selectedMetricMetadata,
      selectedDemographicMetadata,
      commuteModesString,
    ]
  );
}

/** Returns a memoized function that sets the hovered boundary given map info. */
export function useOnHoverHandler(setHoveredBoundaryId) {
  return useCallback(
    (info) => {
      if (!info.layer) {
        // No layer hovered
        setHoveredBoundaryId(null);
        return;
      }
      // Boundary is hovered
      if (info.layer.id === 'administrative-selected' && info.object) {
        // Make sure the feature has data
        if (!isValidFeature(info.object)) {
          setHoveredBoundaryId(null);
          return;
        }
        setHoveredBoundaryId(getBoundaryId(info.object.properties));
      }
    },
    [setHoveredBoundaryId]
  );
}

export function useGetViewportDataFromPoints(demographicsVisible) {
  /**
   * Gets the latitude, longitude, and zoom data centered around the two
   * provided points. Sets the zoom so that both points are visible.
   *
   * @param {number[]} ptA
   * @param {number[]} ptB
   */
  const getViewportDataFromPoints = useCallback(
    (ptA, ptB) => {
      const maxDistance = !demographicsVisible ? 25 : 15;
      const ptDistance = Math.min(
        distance(point(ptA), point(ptB)),
        maxDistance
      );
      let newZoom = interpolate(
        ptDistance,
        0.3,
        maxDistance,
        ZOOM_MAX,
        ZOOM_MIN
      );
      if (demographicsVisible) {
        newZoom = Math.max(newZoom - 0.5, ZOOM_MIN);
      }

      return {
        longitude: (ptA[0] + ptB[0]) / 2,
        latitude: (ptA[1] + ptB[1]) / 2,
        zoom: !demographicsVisible ? newZoom : newZoom - 0.5,
      };
    },
    [demographicsVisible]
  );
  return getViewportDataFromPoints;
}

function updateMapboxLayers(mapObj, selectedMetricId, dispatch) {
  if (!mapObj.isStyleLoaded()) {
    const waitTime = 500;
    console.log(
      `Mapbox style is not loaded! Trying to update layers again in ${waitTime}ms...`
    );
    return setTimeout(
      () => updateMapboxLayers(mapObj, selectedMetricId, dispatch),
      waitTime
    );
  }

  console.log('Successfully updated mapbox layers!');
  dispatch(setLoading(false));
  [...Object.values(_METRIC_METADATA)].forEach((metadata) => {
    if (!metadata.mapboxLayerId) {
      // Metric has no mapbox layers
      return;
    }
    // Update all mapbox layers for the metric
    metadata.mapboxLayerId.forEach((layerId) => {
      if (metadata.id === selectedMetricId) {
        mapObj.setLayoutProperty(layerId, 'visibility', 'visible');
      } else {
        mapObj.setLayoutProperty(layerId, 'visibility', 'none');
      }
    });
  });
}

export function useMapboxLayerUpdater(
  selectedMetricId,
  mainMapRef,
  leftMapRef,
  rightMapRef
) {
  const dispatch = useDispatch();

  useEffect(() => {
    [mainMapRef, leftMapRef, rightMapRef].forEach((mapRef) => {
      if (!mapRef.current) {
        return;
      }
      const mapObj = mapRef.current.getMap();

      updateMapboxLayers(mapObj, selectedMetricId, dispatch);
    });
  }, [
    selectedMetricId,
    mainMapRef.current,
    leftMapRef.current,
    rightMapRef.current,
  ]);
}
