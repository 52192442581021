import { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';
import { setIsMobile } from '../store/slices/mobileSlice';
import {
  boolifyString,
  getBoundaryFeature,
  getBoundaryId,
  getBoundaryName,
  stringifyBool,
} from './functions';
import * as mapReducers from '../store/slices/mapSlice';
import * as searchReducers from '../store/slices/communitySearchSlice';
import * as navReducers from '../store/slices/navigationSlice';
import * as toggleReducers from '../store/slices/togglesSlice';
import * as vizReducers from '../store/slices/vizSlice';
import { chapters, viewTypes } from './constants';

export function useGA4({ searchState, mapState, toggles, navState, vizState }) {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: 'G-589ZW1S0M4',
      },
    ]);
    ReactGA.event({
      category: 'PageView',
      action: 'Init View',
      label: 'View',
    });
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Route params',
      label: window.location.search,
    });
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: 'Regions of Interests',
      action: 'Select Coord',
      label: searchState.data.primary.coords,
    });
  }, [searchState.data.primary.coords]);

  useEffect(() => {
    ReactGA.event({
      category: 'Regions of Interests',
      action: 'Compare Coord',
      label: searchState.data.compare.coords,
    });
  }, [searchState.data.compare.coords]);

  useEffect(() => {
    if (toggles.showViewToggle)
      ReactGA.event({
        category: 'Function',
        action: 'Show Toggle',
        label: toggles.showViewToggle,
      });
  }, [toggles.showViewToggle]);

  useEffect(() => {
    if (toggles.showDemographicsTab)
      ReactGA.event({
        category: 'Function',
        action: 'Show Demographics',
        label: toggles.showDemographicsTab,
      });
  }, [toggles.showDemographicsTab]);

  useEffect(() => {
    if (mapState.demographicsVisible)
      ReactGA.event({
        category: 'Function',
        action: 'Show Demographics on map',
        label: mapState.demographicsVisible,
      });
  }, [mapState.demographicsVisible]);

  useEffect(() => {
    if (toggles.compareMode)
      ReactGA.event({
        category: 'Function',
        action: 'Use compare',
        label: toggles.compareMode,
      });
  }, [toggles.compareMode]);

  useEffect(() => {
    if (toggles.showUnderperformers)
      ReactGA.event({
        category: 'Function',
        action: 'Toggle Underperformers',
        label: toggles.showUnderperformers,
      });
  }, [toggles.showUnderperformers]);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Select Chapter',
      label: navState.chapter,
    });
  }, [navState.chapter]);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Select Issue',
      label: navState.metricCategory,
    });
  }, [navState.metricCategory]);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Select Specific Issue',
      label: navState.metric,
    });
  }, [navState.metric]);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Switch boundary',
      label: navState.boundaryType,
    });
  }, [navState.boundaryType]);

  useEffect(() => {
    ReactGA.event({
      category: 'PageView',
      action: 'Select About',
      label: navState.aboutSection,
    });
  }, [navState.aboutSection]);

  useEffect(() => {
    ReactGA.event({
      category: 'Regions of Interests',
      action: 'Pinned boundaries',
      label: vizState.pinnedBoundaries,
    });
  }, [vizState.pinnedBoundaries]);
}

export const useResizeObserver = (ref, callback = null) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO = new ResizeObserver((entries) => handleResize(entries));
    RO.observe(ref.current);

    return () => {
      RO.disconnect();
      RO = null;
    };
  }, [ref, handleResize]);

  return [width, height];
};

export function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}

/**
 * The URL state updater.
 *
 * Puts specific global state into the URL so that when the link is copied from
 * user to user, the view remains consistent.
 *
 * -----------------
 * URL DOCUMENTATION
 * -----------------
 * **Navigation**
 * * `c=<chapterId>`- Chapter (`navState.chapter`)
 * * `b=<boundaryType>` - Boundary (`navState.boundaryType`)
 * * `md=<t/f>` - Map Demographics (`mapState.demographicsVisible`)
 * * `v=<viewType>` - The view we want to show
 *      (`navState.views[navState.chapter]`). Note: only saves the view type
 *      from the current chapter.
 *
 * **Citywide Data**
 * * `mc=<metricCategoryId>` - Metric Category (`navState.metricCategory`)
 * * `m=<metricId>` - Metric (`navState.metric`)
 * * `pm=<profileMetrics>` - Additional Metrics (`navState.profileMetrics`)
 * * `dt=<t/f>` - Demographics Tab (`toggles.showDemographicsTab`)
 * * `d=<demographicId>` - Demographic (`navState.demographic`)
 * * `ct=<(t|f)(t|f)(t|f)(t|f)(t|f)>` - Commute Toggles (concatenates the
 *      commute toggles from `toggles.commuteToggles`:
 *      <subway|bus|bike|walk|drive>). For example, 'ct=tfftf' indicates subway
 *      and walk are selected while bus, bike, and drive are not.
 * * `pb=<pinnedBoundaries>` - Pinned Boundaries (The pinned boundaries from
 *      `vizState.pinnedBoundaries`. Note: only saves the pinned boundaries from
 *      the currently selected boundary type.)
 *
 * **Community Search**
 * * `pq=<primaryBoundaryId>` - Primary Query (`searchState.data.primary.query`)
 * * `pc=<primarySearchCoords>` - Primary Coordinates (`searchState.data.primary.coords`)
 * * `cq=<compareBoundaryId>` - Compare Query (`searchState.data.compare.query`)
 * * `cc=<compareSearchCoords>` - Compare Coordinates (`searchState.data.compare.coords`)
 * * `cm=<t/f>` - Compare Mode (`toggles.compareMode`)
 */
export function useURLUpdater(
  mapState,
  navState,
  searchState,
  vizState,
  toggles,
  isFirstLoad,
  setIsFirstLoad
) {
  useEffect(() => {
    if (isFirstLoad && navState.chapter === null) {
      console.debug('! Preventing URL update on first load !');
      return;
    }

    if (isFirstLoad && navState.chapter !== null) {
      console.debug(
        'User interacted with site by selecting a chapter (or chapter was ' +
          'already loaded from URL)... begin updating the URL'
      );
      setIsFirstLoad(false);
    }

    const params = [];
    // Navigation
    if (navState.chapter !== null)
      params.push(`c=${navState.chapter.toString()}`);
    if (navState.boundaryType !== null)
      params.push(`b=${navState.boundaryType.toString()}`);
    if (mapState.demographicsVisible !== null)
      params.push(`md=${stringifyBool(mapState.demographicsVisible)}`);
    if (
      navState.chapter === chapters.CITYWIDE ||
      navState.chapter === chapters.COMMUNITY
    )
      params.push(`v=${navState.views[navState.chapter]}`);

    // Metric/Demographic Information
    if (navState.metricCategory)
      params.push(`mc=${navState.metricCategory.toString()}`);
    if (navState.metric !== null)
      params.push(`m=${navState.metric.toString()}`);
    if (navState.profileMetrics.length > 0)
      params.push(`pm=[${navState.profileMetrics.toString()}]`);
    if (toggles.showDemographicsTab !== null)
      params.push(`dt=${stringifyBool(toggles.showDemographicsTab)}`);
    if (navState.demographic !== null)
      params.push(`d=${navState.demographic.toString()}`);
    if (searchState.data.primary.query !== null)
      params.push(`pq=${searchState.data.primary.query}`);
    if (searchState.data.primary.coords.length) {
      const pCoords = searchState.data.primary.coords;
      params.push(`pc=[${pCoords[0].toFixed(3)},${pCoords[1].toFixed(3)}]`);
    }
    if (searchState.data.compare.query !== null)
      params.push(`cq=${searchState.data.compare.query}`);
    if (searchState.data.compare.coords.length > 0) {
      const cCoords = searchState.data.compare.coords;
      params.push(`cc=[${cCoords[0].toFixed(3)},${cCoords[1].toFixed(3)}]`);
    }
    if (toggles.compareMode !== null)
      params.push(`cm=${stringifyBool(toggles.compareMode)}`);
    if (toggles.commuteToggles !== null) {
      const t = toggles.commuteToggles;
      params.push(`ct=${Object.values(t).map(stringifyBool).join('')}`);
    }
    if (vizState.pinnedBoundaries[navState.boundaryType]?.length) {
      params.push(`pb=${vizState.pinnedBoundaries[navState.boundaryType]}`);
    }

    // Create the new path and add it to the URL
    const newPath = `${window.location.origin}${
      window.location.pathname
    }?${params.join('&')}`;
    if (window.history.replaceState) {
      try {
        window.history.replaceState({ path: newPath }, '', newPath);
      } catch (e) {
        console.error('error trying to change window history');
        setTimeout(() => {
          window.history.replaceState(null, '', newPath);
        }, 0.5);
      }
    }
  }, [
    mapState,
    navState,
    searchState,
    vizState,
    toggles,
    isFirstLoad,
    setIsFirstLoad,
  ]);
}

/**
 * On first load, populates global store with data from the URL.
 */
export function useURLLoader() {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log('FIRST LOAD, reading query params', queryParams);
    let loadedBoundaryType;
    let loadedChapter;
    let loadedPrimaryQuery;
    let loadedPrimaryCoords;
    let loadedCompareQuery;
    let loadedCompareCoords;
    for (let pair of queryParams.entries()) {
      const [key, val] = pair;
      switch (key) {
        case 'c':
          loadedChapter = parseInt(val);
          dispatch(navReducers.setChapter(loadedChapter));
          break;
        case 'b':
          dispatch(navReducers.setBoundaryType(val));
          loadedBoundaryType = val;
          break;
        case 'md':
          dispatch(mapReducers.setDemographicsVisible(boolifyString(val)));
          break;
        case 'v':
          dispatch(
            navReducers.setView({ chapter: loadedChapter, newView: val })
          );
          break;
        case 'mc':
          dispatch(navReducers.setMetricCategory(parseInt(val)));
          break;
        case 'm':
          dispatch(navReducers.setMetric(parseInt(val)));
          break;
        case 'pq':
          loadedPrimaryQuery = Number(val);
          break;
        case 'pc':
          loadedPrimaryCoords = JSON.parse(val).map((item) =>
            parseFloat(item.toString())
          );
          break;
        case 'cq':
          loadedCompareQuery = Number(val);
          break;
        case 'cc':
          loadedCompareCoords = JSON.parse(val).map((item) =>
            parseFloat(item.toString())
          );
          break;
        case 'd':
          dispatch(navReducers.setDemographic(val));
          break;
        case 'dt':
          dispatch(toggleReducers.setShowDemographicsTab(boolifyString(val)));
          break;
        case 'pm':
          dispatch(
            navReducers.setProfileMetrics(
              JSON.parse(val).map((item) => parseInt(item))
            )
          );
          break;
        case 'cm':
          dispatch(toggleReducers.setCompareMode(boolifyString(val)));
          break;
        case 'ct':
          dispatch(toggleReducers.setCommuteTogglesFromString(val));
          break;
        case 'pb':
          dispatch(
            vizReducers.setPinnedBoundaries({
              boundaryType: loadedBoundaryType,
              boundaryIds: val.split(',').map(Number),
            })
          );
          break;
        default:
          break;
      }
    }

    if (loadedPrimaryQuery !== undefined) {
      dispatch(
        searchReducers.setSearchData({
          type: 'primary',
          boundaryId: loadedPrimaryQuery,
          boundaryCoords: loadedPrimaryCoords,
        })
      );
    }
    if (loadedCompareQuery !== undefined) {
      dispatch(
        searchReducers.setSearchData({
          type: 'compare',
          boundaryId: loadedCompareQuery,
          boundaryCoords: loadedCompareCoords,
        })
      );
    }
  }, [dispatch]);
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      dispatch(setIsMobile(window.innerWidth < 576));
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]); // Empty array ensures that effect is only run on mount
  return windowSize;
}

/**
 * Hook that returns a function that gets the boundary name using the currently
 * selected boundary type.
 *
 * Example usage:
 * ```js
 * const getBoundaryName = useGetBoundaryName()
 * // `getBoundaryName` automatically updates when the selected boundary type
 * // changes, so it always returns the correct value
 * ```
 * @returns
 */
export function useGetBoundaryName() {
  const boundaryType = useSelector((state) => state.nav.boundaryType);
  return useCallback(
    (config) => getBoundaryName(boundaryType, config),
    [boundaryType]
  );
}

export function useCommunitySearchUpdater() {
  const dispatch = useDispatch();

  const compareMode = useSelector((state) => state.toggles.compareMode);
  const currentSearchData = useSelector((state) => state.search.data);

  return useCallback(
    function () {
      function communitySearchUpdater(boundaryFeature, config) {
        let searchType = config?.searchType;
        if (!searchType) {
          searchType = compareMode ? 'compare' : 'primary';
        }
        if (!currentSearchData.primary.query) {
          // Ensure that compare searches can only occur when a primary search
          // has already been executed
          searchType = 'primary';
        }
        const searchSource = config?.source;

        if (!boundaryFeature) {
          console.debug('No results found for', searchType);
          dispatch(searchReducers.setErrorCode(0));
          dispatch(
            searchReducers.setBadSearch({ type: searchType, newFlag: true })
          );
          return;
        }

        // The new coordinates are determined either from the centroid or the
        // config (when clicked on the map)
        let newCoords;
        if (config?.coords?.length) {
          newCoords = config.coords;
        } else {
          newCoords = [
            boundaryFeature.properties.X_cent,
            boundaryFeature.properties.Y_cent,
          ];
        }
        const newBoundaryId = getBoundaryId(boundaryFeature.properties);

        console.debug('ID', newBoundaryId);
        console.debug('Config:', config);
        console.debug('Current search data:', currentSearchData);
        console.debug('New Coords', newCoords);
        console.debug('Search type:', searchType);

        if (searchType === 'compare') {
          if (newBoundaryId === currentSearchData.primary.query) {
            console.debug('Selected comparison boundary equals the primary');
            dispatch(searchReducers.resetSearchParams({ type: 'compare' }));

            if (searchSource === 'search') {
              // If the user *searched* the same boundary for the comparison, do not
              // allow and set an error code
              dispatch(searchReducers.setErrorCode(1));
              dispatch(
                searchReducers.setBadSearch({ type: 'compare', newFlag: true })
              );
            }
            return;
          }

          if (newBoundaryId === currentSearchData.compare.query) {
            // The *clicked* comparison boundary equals existing comparison boundary
            // In this case we just get rid of the comparison selection
            console.debug(
              'User clicked on already selected compare community, unselecting...'
            );
            dispatch(searchReducers.resetSearchParams({ type: 'compare' }));
            return;
          }

          console.debug(
            'DEFAULT CASE: Setting selected boundary as the comparison'
          );
          dispatch(
            searchReducers.setSearchData({
              type: searchType,
              boundaryId: newBoundaryId,
              boundaryCoords: newCoords,
            })
          );
          return;
        }

        // Primary search, no compare mode

        if (
          newBoundaryId === currentSearchData.primary.query &&
          searchSource === 'click'
        ) {
          // User selected the same boundary, reset primary data and view
          console.debug('Unselecting primary search boundary');
          dispatch(searchReducers.resetSearchParams({ type: 'primary' }));
          return;
        }

        console.debug(
          `DEFAULT CASE: Setting selected boundary data for ${newBoundaryId} as the primary`
        );
        if (searchSource === 'search') {
          console.debug(
            'Setting primary search from search bar; change view to histogram'
          );
          dispatch(
            navReducers.setView({
              chapter: chapters.COMMUNITY,
              newView: viewTypes.HISTOGRAM,
            })
          );
        }
        dispatch(
          searchReducers.setSearchData({
            type: searchType,
            boundaryId: newBoundaryId,
            boundaryCoords: newCoords,
          })
        );
      }

      console.group('Community Search Updater');
      const result = communitySearchUpdater(...arguments);
      console.groupEnd();
      return result;
    },
    [dispatch, compareMode, currentSearchData]
  );
}
