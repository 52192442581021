// import React and React hooks
import React, { useMemo } from 'react';

// import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// import components
import CommunitySearchBar from './CommunitySearchBar';

// import npm packages
import Typewriter from 'typewriter-effect';

import { useSelector, useDispatch } from 'react-redux';
import {
  setPrimarySearchQuery,
  setCompareSearchQuery,
  setSearchSource,
  setPrimarySearchCoords,
  setCompareSearchCoords,
  setSearchQuery,
} from '../../store/slices/communitySearchSlice';
import { toggleCompareMode } from '../../store/slices/togglesSlice';
import {
  selectBoundaryData,
  selectBoundaryMetadata,
  selectCompareSearchMetadata,
  selectPrimarySearchMetadata,
} from '../../store/storeUtils';
import { getBoundaryId, getBoundaryName } from '../../utils/functions';
import CommunityMetadata from './CommunityMetadata';
import { useCommunitySearchUpdater } from '../../utils/hooks';

/**
 * CommunityNav.js renders the Community Profiles section of the navigation
 * which includes the community and compare search box as well as the typewriter effect
 * @constructor
 *
 */

export default function CommunityNav() {
  const searchState = useSelector((state) => state.search);
  const boundaryType = useSelector((state) => state.nav.boundaryType);
  const chapter = useSelector((state) => state.nav.chapter);
  const dispatch = useDispatch();

  const primarySearchMetadata = useSelector(selectPrimarySearchMetadata);
  const compareSearchMetadata = useSelector(selectCompareSearchMetadata);
  const allBoundaryMetadata = useSelector(selectBoundaryMetadata);

  /** The boundary geojson data */
  const boundaryJSONData = useSelector(selectBoundaryData);

  const updateCommunitySearch = useCommunitySearchUpdater();

  // pre-baked search using imported geojson data
  const getSearchItems = (isPrimarySearch) => {
    const searchItems = [];
    const targetQuery = isPrimarySearch
      ? primarySearchMetadata?.id
      : compareSearchMetadata?.id;
    const oppositeQuery = isPrimarySearch
      ? compareSearchMetadata?.id
      : primarySearchMetadata?.id;

    allBoundaryMetadata.forEach((boundaryMeta) => {
      if (boundaryMeta.id === oppositeQuery) {
        return;
      }

      const isTargetQuery = targetQuery?.toString().startsWith(boundaryMeta.id);
      const boundaryName = getBoundaryName(boundaryType, {
        id: boundaryMeta.id,
        length: 'short',
      });
      searchItems.push(
        <div
          key={boundaryMeta.id}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${
            isTargetQuery ? 'search-item-active' : 'search-item-inactive'
          } col search-item p-2`}
          onMouseDown={(e) => {
            console.debug(
              `Selected ${
                isPrimarySearch ? 'primary' : 'compare'
              } search from the search bar`,
              boundaryMeta.id
            );

            e.stopPropagation(e);
            const boundaryFeature = boundaryJSONData.features.find(
              (feature) => getBoundaryId(feature.properties) === boundaryMeta.id
            );
            updateCommunitySearch(boundaryFeature, { source: 'search' });
            e.target.blur();
          }}
        >
          <div className={'row w-100 p-0 m-0'}>
            <div className={'col-10 m-0 p-0'}>
              <span style={{ fontWeight: 'bold' }}>{boundaryName}</span>{' '}
              {boundaryMeta.neighborhoods}
            </div>
            <div
              className={`${
                isTargetQuery ? 'visible' : 'invisible'
              } d-flex col-2 p-0 flex-row justify-content-center align-items-center`}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      );
    });

    return searchItems;
  };

  const searchItems = useMemo(
    () => getSearchItems(true),
    [boundaryType, primarySearchMetadata, compareSearchMetadata, updateCommunitySearch]
  );
  const compareSearchMetadataItems = useMemo(
    () => getSearchItems(false),
    [boundaryType, primarySearchMetadata, compareSearchMetadata, updateCommunitySearch]
  );

  return (
    <div
      className={
        'community-nav-container d-flex flex-column justify-content-between h-100'
      }
    >
      <div
        className={`position-relative`}
        style={{ pointerEvents: chapter === 3 ? '' : 'none' }}
      >
        <CommunitySearchBar
          toggleValue={
            primarySearchMetadata &&
            getBoundaryName(boundaryType, {
              id: primarySearchMetadata?.id,
              length: 'short',
            })
          }
          searchType="primary"
        >
          {searchItems}
        </CommunitySearchBar>
        <div className={'community-nav-text'}>
          <CommunityMetadata boundaryMetadata={primarySearchMetadata} />
        </div>
        {primarySearchMetadata && (
          <CommunitySearchBar
            toggleValue={
              compareSearchMetadata &&
              getBoundaryName(boundaryType, {
                id: compareSearchMetadata?.id,
                length: 'short',
              })
            }
            searchType="compare"
          >
            {compareSearchMetadataItems}
          </CommunitySearchBar>
        )}
        {(searchState.data.primary.isBadSearch ||
          searchState.data.compare.isBadSearch) && (
          <div className={'m-0 small-font'}>
            {searchState.errorCode === 1
              ? `${getBoundaryName(boundaryType, {
                  id: primarySearchMetadata?.id,
                })} is already selected!`
              : `Nothing found. Try searching for something else.`}
          </div>
        )}
        <CommunityMetadata boundaryMetadata={compareSearchMetadata} />
        {!primarySearchMetadata && (
          <div
            className={'d-flex flex-column align-items-start w-100 mt-3 mb-3'}
          >
            <p className={'m-0'} style={{ fontSize: '1.75rem' }}>
              Try searching for &thinsp;
            </p>

            <div className={'typewriter-container'}>
              <Typewriter
                options={{
                  strings:
                    boundaryType === 'community'
                      ? [
                          'your address',
                          'Hamilton Heights',
                          '111 John Street',
                          'Bronx 9',
                          'Bedford Stuyvesant',
                          '350 5th Avenue',
                        ]
                      : [
                          'your address',
                          'Washington Heights',
                          '350 5th Avenue',
                          'District 5',
                          '111 John Street',
                          'Bensonhurst',
                        ],
                  autoStart: true,
                  loop: true,
                  pauseFor: 2000,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
